/*Base-Styling for HTML-Elements*/
@import "config.css";
@import "bootstrap-override.css";

@import "font-face.css";
@import "text/text.css";
@import "headline/headline.css";
@import "link/link.css";
@import "button/button.css";
@import "form/form.css";
@import "content-block.css";
@import "wysiwyg.css";
@import "skip-links.css";
@import "icons/icons.css";
@import "ecommerce.scss";

body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default-xs);
    line-height: var(--line-height-default-xs);
    word-break: break-word;

    @media (min-width: 992px) {
        font-size: var(--font-size-default);
        line-height: var(--line-height-default);
    }

    @media (min-width: 1921px) {
        font-size: var(--font-size-default-xxxl);
        line-height: var(--line-height-default-xxxl);
    }
}

table {
    word-break: initial;
}

.bg-warm-grey {
    background-color: var(--color-warm-grey) !important;
}

.cursor-default {
    cursor: default !important;
}