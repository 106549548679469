.mai__m15-wysiwyg .mai__m13-headline__heading {
    margin-bottom: calc(30rem / 16);

    @media (min-width: 992px) {
        margin-bottom: calc(40rem / 16);
    }
}

.mai__m15-wysiwyg .mai__editorial.mai__m13-headline__heading {
    @media (min-width: 992px) {
        margin-bottom: calc(60rem / 16);
    }
}

.mai__m15-wysiwyg__text--columns p {
    @media (min-width: 992px) {
        column-count: 2;
        column-gap: calc(19rem/16);
    }
}
