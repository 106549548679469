:root {
    --color-primary: #313330;
    --color-secondary: #E5E1DE;
    --color-text-default: var(--color-default);
    --color-text-muted: var(--color-dark-grey);
    --color-primary-transparent: rgba(0, 58, 96, 0.9);

    --color-default: #323232;
    --color-white: #FFF;
    --color-black: #000;

    --color-anthracite: #313330;
    --color-anthracite-50: rgba(49,51,48,.5);
    --color-anthracite-40: rgba(49,51,48,.4);
    --color-warm-grey: #E5E1DE;
    --color-light-beige: #EBE5DC;
    --color-light-grey: #F8F9FA;
    --color-dark-grey: #A4A2A1;
    --color-dark: var(--color-default);

    --color-light-mint: #D8E8E2;
    --color-dark-mint: #95B6AD;
    --color-mintpetrol: #8CBEBE;
    --color-petrol: #038C7E;

    --color-green: #323232;
    --color-blue: #003A60;
    --color-yellow: #FFFF00;
    --color-red: #CC0033;

    --color-sand-10: #F9F8F7;
    --color-sand-20: #F0EEEC;
    --color-sand: #B5ADA6;

    --color-success: #65BA8C;
    --color-info: var(--color-yellow);
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #002f4e;
    --color-secondary-dark: #424D56;
    --color-success-dark: #57a078;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #6DA4E2;
    --color-secondary-light: #6E8090;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --color-image-background: #f4f4f6;

    --font-primary: "source-sans-pro", sans-serif;
    --font-secondary: "nanumm-yeongjo", sans-serif;
    --font-tertiary: "eb-garamond", sans-serif;

    --font-default: "source-sans-pro", sans-serif;
    --font-default-bold: var(--font-default), sans-serif;
    --font-default-light: var(--font-default), sans-serif;

    --font-weight-200: 200;
    --font-weight-300: 300;
    --font-weight-400: 400;
    --font-weight-600: 600;
    --font-weight-700: 700;
    --font-weight-800: 800;
    --font-weight-900: 900;

    --font-default-weight: var(--font-weight-400);
    --font-weight-regular: var(--font-weight-400);
    --font-weight-extralight: var(--font-weight-200);
    --font-weight-light: var(--font-weight-300);
    --font-weight-semibold: var(--font-weight-600);
    --font-weight-bold: var(--font-weight-700);
    --font-weight-extrabold: var(--font-weight-800);
    --font-weight-black: var(--font-weight-900);

    --font-default-bold-weight: var(--font-weight-600);
    --font-default-compact-weight: var(--font-weight-300);
    --font-default-compact-style: normal;

    --font-size-default-xs: calc(14rem / 16);
    --font-size-default: calc(16rem / 16);
    --font-size-default-xxxl: calc(28rem / 16);

    --line-height-default-xs: calc(28rem / 16);
    --line-height-default: calc(28rem / 16);
    --line-height-default-xxxl: calc(50rem / 16);

    --font-size-small-xs: calc(12rem / 16);
    --font-size-small: calc(12rem / 16);
    --font-size-small-xxxl: calc(21rem / 16);

    --line-height-small-xs: calc(24rem / 16);
    --line-height-small: calc(24rem / 16);
    --line-height-small-xxxl: calc(32rem / 16);

    --transition-all-default: all 300ms ease-in-out;

    --margin-bottom-default: calc(140rem / 16);
}
