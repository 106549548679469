.mai__m27-sticky-bar {
    padding: calc(12rem/16) 0;
    background-color: #f5f3f2;

    position: fixed;
    top: var(--header-height-mobile);
    width: 100%;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s ease;

    @media (min-width: 768px) {
        top: var(--header-height);
    }

    @media (min-width: 1921px) {
        top: var(--header-height-xxl);
    }
}

.mai__m27-sticky-bar--visible {
    opacity: 1;
    z-index: 10;
}


/* Overwrites */

.mai__m27-sticky-bar .mai__m29-color-selector {
    padding-left: calc(20rem/16);
    position: relative;
    margin-top: 0 !important;
}

.mai__m27-sticky-bar__product-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(300rem/16);
}


.mai__m27-sticky-bar__color-name {
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(144rem/16);

    @media (min-width: 1400px) {
        max-width: calc(200rem/16);
    }
}

.mai__m27-sticky-bar .mai__m29-color-selector__media,
.mai__m27-sticky-bar .mai__m29-color-selector__item,
.mai__m27-sticky-bar .mai__m29-color-selector__arrow {
    width: calc(56rem/16);
    height: calc(56rem/16);
    margin-bottom: 0;
    background-color: var(--color-white);
}

.mai__m27-sticky-bar .mai__m29-color-selector__item:not(.splide__slide) {
    margin-left: calc(10rem/16);
}

.mai__m27-sticky-bar .mai__m29-color-selector__text {
    display: none;
}

[data-sticky-bar-buybutton] .icon {
    font-size: calc(15rem/16);
    position: relative;
    top: 2px;
}
