.mai__m4-shop-the-look__slider-item {
    @media (min-width: 992px) {
        width: 100%;
    }
}

.mai__m4-shop-the-look__slider-item .mai__product-card__image-container {
    transition: 150ms margin-bottom ease-in-out;

    @media (min-width: 992px) {
        margin-bottom: 0;
    }
}

.mai__m4-shop-the-look__slider-item .mai__product-card__awards,
.mai__m4-shop-the-look__slider-item .mai__product-card__badge-container {
    transition: transform 150ms ease-in-out;
}

.mai__m4-shop-the-look__slider-item.is-active .mai__product-card__awards {
    transform: translate(.3125rem, -.875rem);

    @media (min-width: 992px) {
        transform: none;
    }
}

.mai__m4-shop-the-look__slider-item.is-active .mai__product-card__badge-container {
    transform: translate(-.3125rem, -.875rem);

    @media (min-width: 992px) {
        transform: none;
    }
}

.mai__m4-shop-the-look__slider-item .mai__product-card__image-container::before {
    @media (min-width: 992px) {
        display: none;
    }
}

.mai__m4-shop-the-look__slider-item .mai__product-card__badge-container .mai__product-card__badge-item {
    @media (max-width: 767px) {
        display: none;
        &:first-child {
            display: block;
        }
    }
}

.mai__m4-shop-the-look__slider-item .mai__product-card__image-wrapper {
    transition: transform 150ms ease-in-out;
}

.mai__m4-shop-the-look__slider-item .mai__product-card__image {
    height: calc(100% - (2.1875rem * 2));
    object-fit: cover;

    @media (min-width: 992px) {
        object-fit: contain;
    }
}

/* .mai__product-card__body:hover .mai__product-card__image-wrapper,
.mai__product-card__body:focus .mai__product-card__image-wrapper, */
.mai__m4-shop-the-look__slider .mai__product-card__image-wrapper {
    @media (max-width: 767px) {
        padding: calc(10rem/16) !important;
    }
}

.mai__m4-shop-the-look__slider .mai__product-card__image-wrapper::before {
    @media (min-width: 992px) {
        padding-bottom: 75%;
    }

    @media (min-width: 1200px) {
        padding-bottom: 110%;
    }

    @media (min-width: 1400px) {
        padding-bottom: 125%;
    }
}

.mai__m4-shop-the-look__slider-item.is-active .mai__product-card__image-wrapper {
    transform: scale(1.2);

    @media (min-width: 992px) {
        transform: none;
    }

    @media (max-width: 520px) {
        padding: calc(15rem/16) !important;
    }

    @media (min-width: 521px) and (max-width: 767px) {
        padding: calc(15rem/16) calc(25rem/16) !important;
    }
}

.mai__m4-shop-the-look__slider-item .mai__product-card__image-background {
    transition: transform 150ms ease-in-out;
}

.mai__m4-shop-the-look__slider-item .mai__product-card__image-background::before {
    content: '';
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: inherit;
    box-shadow: 0 calc(1.85rem / 16) calc(3.15rem / 16) 0 rgba(0,0,0,.02),
                0 calc(8.15rem / 16) calc(6.52rem / 16) 0 rgba(0,0,0,.03),
                0 calc(20rem / 16) calc(13rem / 16) 0 rgba(0,0,0,.04),
                0 calc(38.52rem / 16) calc(25.48rem / 16) 0 rgba(0,0,0,.04);

    z-index: 1;
    pointer-events: none;

    opacity: 0;

    transition: opacity .15s ease-in-out;

    @media (min-width: 992px) {
        display: none;
    }
}

/* .mai__product-card__body:hover .mai__product-card__image-background,
.mai__product-card__body:focus .mai__product-card__image-background, */
.mai__m4-shop-the-look__slider-item.is-active .mai__product-card__image-background {
    transform: scaleY(1.1) scaleX(1.05);

    @media (min-width: 992px) {
        transform: none;
    }
}

.mai__m4-shop-the-look__slider-item.is-active .mai__product-card__image-background::before {
    opacity: 1;
}

.mai__m4-shop-the-look__slider-item .mai__product-card__content {
    position: relative;
    z-index: 2;

    @media (min-width: 992px) {
        position: static;
    }
}

.mai__m4-shop-the-look__slider-item .mai__product-card__body {
    transform: translateY(0);
    transition: transform .15s ease-in-out;

    @media (min-width: 992px) {
        transform: none;
    }
}

.mai__m4-shop-the-look__slider-item.is-active .mai__product-card__body {
    transform: translateY(.625rem);

    @media (min-width: 992px) {
        transform: none;
    }
}
