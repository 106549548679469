.cart-teaser__remove {
    background: none;
    border: none;
    color: var(--color-sand);
    transition: transform 200ms ease-in-out;
    position: absolute;
    right: 0;
    top: 0;
    font-size: calc(12rem / 16);
    z-index: 2;
}

.cart-teaser__remove:hover,
.cart-teaser__remove:focus {
    color: var(--color-primary);
}
