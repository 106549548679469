/* Libraries  */
/* global utilites eg. viewport mixins */
/**
    devices with hover capabilty (= mouse) vs devices without
*/
/* Global variables and settings */
@import url(./style/icons.css);
/* General base styling & reset (mostly plain element styling; very low specificity ) */
@import url(./style/base/base.css);
@import url(./style/base/checkout-steps.css);
@import url(./style/base/embed-responsive.css);
@import url(../shared/unhide-by-login-state/unhide-by-login-state.css);
@import url(../shared/cookie-overlay/cookie-overlay.css);
@import url(./style/components/accordion/accordion.css);
@import url(./style/components/language-switch/language-switch.css);
@import url(./style/components/modal/modal.css);
@import url(./style/components/overlay/overlay.css);
@import url(./style/components/pagination/pagination.css);
@import url(./style/components/rating/rating.css);
@import url(./style/components/typeahead/typeahead.css);
@import url(./style/components/page-alert/page-alert.css);
@import url(./style/components/alert-notification/alert-notification.css);
@import url(./style/components/card-teaser/card-teaser.css);
@import url(./style/components/lightbox/lightbox.css);
@import url(./style/components/anchor/anchor.css);
@import url(./style/components/product-card/product-card.css);
@import url(./style/components/module-headline/module-headline.css);
@import url(./style/components/slider-pagination/slider-pagination.css);
@import url(./style/components/slider-arrows/slider-arrows.css);
@import url(./style/components/base-video/video.css);
@import url(./style/components/ellipse/ellipse.css);
@import url(./style/components/loading/loading-overlay.css);
@import url(./style/components/loading/loading-spinner.css);
@import url(./style/components/loading/load-container.css);
@import url(./style/components/tab/tab.css);
@import url(./style/components/product-grid/product-grid.css);
@import url(./style/components/anchor-menu/anchor-menu.css);
@import url(./style/components/badge/badge.css);
@import url(./style/components/vertical-gutter/vertical-gutter.css);
@import url(./style/components/scrolling-area/scrolling-area.css);
@import url(./style/components/cookie-banner/cookie-banner.css);
@import url(./style/components/pdsLightbox/pdsLightbox.css);
@import url(./style/components/global-alert/global-alert.css);
@import url(./style/components/product-availability/product-availability.css);
@import url(./style/components/followup-product-card/followup-product-card.css);
@import url(./style/modules/sr1-header/header.css);
@import url(./style/modules/sr1-header/header-responsive.css);
@import url(./style/modules/sr2-footer/footer.css);
@import url(./style/modules/compare-list/compare-list.css);
@import url(./style/modules/hotspot-area/hotspot-area.css);
@import url(./style/modules/wishlist/wishlist.css);
@import url(./style/modules/magazine/magazine-list.css);
@import url(./style/modules/m1-magazine-teaser/magazine-teaser.css);
@import url(./style/modules/lg-zoom/lg-zoom.css);
@import url(./style/modules/jobs/jobs.css);
@import url(./style/modules/m2-category-slider/category-slider.css);
@import url(./style/modules/m3-product-row/product-row.css);
@import url(./style/modules/m4-shop-the-look/shop-the-look.css);
@import url(./style/modules/m5-teaser/teaser.css);
@import url(./style/modules/m6-hero-stage/hero-stage.css);
@import url(./style/modules/m7-editorial/editorial.css);
@import url(./style/modules/m8-newsletter/newsletter.css);
@import url(./style/modules/m10-quote/quote.css);
@import url(./style/modules/m11-content-teaser/content-teaser.css);
@import url(./style/modules/m12-content-header/content-header.css);
@import url(./style/modules/m13-headline/headline.css);
@import url(./style/modules/m14-gallery/gallery.css);
@import url(./style/modules/m15-wysiwyg/wysiwyg.css);
@import url(./style/modules/m16-icon/icon.css);
@import url(./style/modules/m17-accordion-grafik/accordion-grafik.css);
@import url(./style/modules/m18-text-media/text-media.css);
@import url(./style/modules/m19-video/video.css);
@import url(./style/modules/m20-raffle/raffle.css);
@import url(./style/modules/m21-teaser-cards/teaser-cards.css);
@import url(./style/modules/m23-form/form.css);
@import url(./style/modules/m25-product-slider/product-slider.css);
@import url(./style/modules/m26-checkout-cta-size-select/checkout-cta-size-select.css);
@import url(./style/modules/m27-sticky-bar/sticky-bar.css);
@import url(./style/modules/m28-info-box/info-box.css);
@import url(./style/modules/m29-color-selector/color-selector.css);
@import url(./style/modules/m30-icons-list/icons-list.css);
@import url(./style/modules/m31-outtra-local/outtra-local.css);
@import url(./style/modules/m32-product-informations/productinformations.css);
@import url(./style/modules/m33-reviews/reviews.css);
@import url(./style/modules/m34-highlight-activities/highlight-activities.css);
@import url(./style/modules/m35a-awards/awards.css);
@import url(./style/modules/m35b-quote/quote.css);
@import url(./style/modules/m40-pl-header/pl-header.css);
@import url(./style/modules/m43-pl-eyecatcher/pl-eyecatcher.css);
@import url(./style/modules/m52-social-wall/social-wall.css);
@import url(../shared/unhide-by-login-state/unhide-by-login-state.css);
@import url(./style/modules/cart-teaser.css);
@import url(./style/number-spinner.css);
@import url(./style/oocss.css);
@import url(./style/table-checkout.css);
@import url(./style/payment-payone.css);
.checkout-header {
  padding: 2em; }

.checkout-header__logo__image {
  height: 1.8rem; }
  @media (min-width: 576px) {
    .checkout-header__logo__image {
      height: 3rem; } }

.checkout-header__sub-logo__additional-text {
  line-height: 1rem; }

.checkout-header__sub-logo__image {
  height: 1.7rem; }
  @media (min-width: 576px) {
    .checkout-header__sub-logo__image {
      height: 2rem; } }

.checkout-steps {
  padding-top: 3em;
  font-size: 1.2rem; }

.heidelpaySandboxNotify {
  display: none !important; }

.back-link .icon {
  padding-right: 0.5em;
  font-size: 1rem; }

.is-finished .btn-cart__default,
.is-loading .btn-cart__default {
  opacity: 0; }

.btn-cart__finished {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: opacity 200ms ease-in-out;
  font-size: calc(22rem / 16); }

.is-finished .btn-cart__finished {
  opacity: 1; }

.account-select-box {
  padding: 1em; }

.payment-methods__item--disabled,
.shipping-methods__item--disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: .5; }

.payment-methods__img,
.shipping-methods__img {
  position: relative;
  display: flex;
  justify-content: center; }

.payment-methods__select-icon,
.shipping-methods__select-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  visibility: hidden;
  pointer-events: none;
  z-index: 1; }

.payment-methods__item.payment-methods__item--selected .payment-methods__select-icon,
.shipping-methods__item.shipping-methods__item--selected .shipping-methods__select-icon {
  visibility: visible; }

.slide-account {
  margin-bottom: 5em; }

.account-select-box {
  padding: 1em; }

.payment-methods__select-icon,
.shipping-methods__select-icon {
  visibility: hidden; }

.payment-methods__item.payment-methods__item--selected .payment-methods__select-icon,
.shipping-methods__item.shipping-methods__item--selected .shipping-methods__select-icon {
  visibility: visible; }

.slide-account {
  margin-bottom: 5em; }

.account-order__lbl {
  font-weight: bolder; }

.account-order__lbl ~ .text-warning {
  color: var(--color-red) !important; }

.account-order {
  padding: 1em; }

.account-order.even {
  background: lightgrey; }

.account-order.odd {
  background: linen; }

.btn .icon-abmelden:before {
  position: relative;
  top: calc(2.1rem / 16); }

.account-order-no-target .select__input-wrapper::before {
  font-size: 0.5rem; }

.customer-backend__orders-articles {
  padding: 3em; }

.customer-backend__orders-address {
  padding: 3em; }

.js-ajax-form__result .customer-backend-collapse-icon > .icon-rotate-180 {
  transition: var(--transition-all-default); }

.js-ajax-form__result .customer-backend-collapse-icon:not(.collapsed) > .icon-rotate-180 {
  transform: rotate(270deg); }

/* Components */
/**
    devices with hover capabilty (= mouse) vs devices without
*/
.in-page-menu {
  z-index: 100; }
  @media screen and (max-width: 767px) {
    .in-page-menu {
      top: 0; } }

@media screen and (min-width: 768px) {
  .in-page-menu__wrapper {
    display: inline-block;
    position: relative; } }

.in-page-menu__dropdown {
  font-size: 1rem;
  background: var(--color-secondary);
  position: absolute;
  z-index: 1;
  text-align: left;
  top: 100%;
  margin-top: 3px;
  left: 0;
  right: 0;
  line-height: 2; }
  @media screen and (min-width: 768px) {
    .in-page-menu__dropdown {
      right: calc(-40rem / 16); } }

.in-page-menu__btn {
  min-width: 150px;
  text-align: left;
  text-transform: uppercase;
  padding: calc(17rem / 16) calc(20rem / 16) calc(15rem / 16);
  background: var(--color-secondary);
  border: none;
  display: inline-block; }
  @media screen and (max-width: 767px) {
    .in-page-menu__btn {
      width: 100%; } }

.in-page-menu__btn-label {
  color: var(--color-primary);
  opacity: 0.5;
  font-family: var(--font-default);
  font-size: calc(13rem / 16);
  line-height: 1.4; }

.in-page-menu__btn-title {
  font-size: 1rem;
  color: var(--color-primary);
  font-weight: var(--font-weight-bold);
  font-family: var(--font-default-bold);
  margin-top: -.25rem;
  /* pull to top to center correctly if the label is not shown */ }

.in-page-menu__btn-label + .in-page-menu__btn-title {
  margin-top: 0; }

.in-page-menu__btn:not(.collapsed) .in-page-menu__icon {
  transform: rotate(180deg); }

.in-page-menu__icon {
  font-size: 7px; }

.in-page-menu__first-level {
  margin: 0;
  padding: 12px 20px 9px; }
  @media screen and (min-width: 768px) {
    .in-page-menu__first-level {
      padding: 11px 40px 8px 20px; } }

.in-page-menu__first-level-link,
.in-page-menu__second-level-link {
  display: block;
  flex: auto;
  color: var(--color-primary); }

.in-page-menu__first-level-item:hover .in-page-menu__first-level-link,
.in-page-menu__first-level-link:focus,
.in-page-menu__first-level-link:active,
.in-page-menu__first-level-link.is-active,
.in-page-menu__second-level-item:hover .in-page-menu__second-level-link,
.in-page-menu__second-level-link:focus,
.in-page-menu__second-level-link:active,
.in-page-menu__second-level-link.is-active {
  color: var(--color-primary);
  font-weight: var(--font-default-bold-weight); }

/* Second level*/
.in-page-menu__second-level {
  position: absolute;
  top: 0;
  padding: 11px 20px 8px;
  background-color: var(--color-secondary); }
  @media screen and (max-width: 767px) {
    .in-page-menu__second-level {
      left: 0;
      right: 0;
      z-index: 1;
      min-height: 100%;
      padding-top: 12px; } }
  @media screen and (min-width: 768px) {
    .in-page-menu__second-level {
      padding-left: 0;
      padding-right: 0;
      left: calc(100% - 20px);
      min-width: 100%;
      right: auto;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      z-index: -1; } }

@media screen and (min-width: 768px) {
  .in-page-menu__second-level:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: calc(-100% + 20px);
    top: 0;
    bottom: 0;
    right: 0;
    background-color: var(--color-secondary); } }

.in-page-menu__second-level-list {
  position: relative; }
  @media screen and (min-width: 768px) {
    .in-page-menu__second-level-list {
      flex: auto;
      padding-left: 20px;
      padding-right: 20px;
      max-width: calc(280rem / 16); }
      .in-page-menu__second-level-list:after {
        content: '';
        position: absolute;
        border-right: 1px solid #b8b7b6;
        top: 9px;
        left: 0;
        bottom: 12px; } }

@media screen and (min-width: 768px) {
  .in-page-menu__second-level-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }

.in-page-menu__touch-only {
  display: none; }
  @media not screen and (hover: hover) and (pointer: fine) {
    .in-page-menu__touch-only {
      display: block; } }

.in-page-menu__close-second-level {
  white-space: nowrap; }
  @media screen and (max-width: 767px) {
    .in-page-menu__close-second-level {
      font-size: calc(13rem / 16); } }

.in-page-menu__close-second-level .icon {
  font-size: calc(8rem / 16);
  margin-right: calc(7rem / 16);
  margin-left: -0.5em;
  color: var(--color-red);
  transform: rotate(90deg); }

.in-page-menu__dropdown-detail-icon {
  display: none; }
  @media not screen and (hover: hover) and (pointer: fine) {
    .in-page-menu__dropdown-detail-icon {
      display: block;
      font-size: 7px;
      line-height: 1;
      height: auto;
      align-self: center;
      transform-origin: center center;
      transform: rotate(-90deg);
      color: var(--color-primary); } }

.modal--country-mismatch-popup h3 {
  font-size: 1.5rem;
  line-height: 1.75rem; }

.modal--country-mismatch-popup img.countryMismatchPopup_icon {
  margin-right: 5px; }

.modal--country-mismatch-popup .js-countryMismatchPopup--select-all {
  line-height: 1;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 2px; }

.modal--country-mismatch-popup label {
  font-size: calc(13rem/16); }

.modal--country-mismatch-popup .mt-20 {
  margin-top: 20px !important; }

.modal--country-mismatch-popup .language-switcher > div {
  flex: 1; }

.modal--country-mismatch-popup .language-switcher div:last-child {
  margin-left: 1rem; }

.modal--country-mismatch-popup .language-switcher input[type="radio"] {
  display: none; }
  .modal--country-mismatch-popup .language-switcher input[type="radio"]:not(:disabled) ~ label {
    cursor: pointer; }

.modal--country-mismatch-popup .language-switcher label {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  outline: 1px solid #dee2e6;
  padding: 0.85rem;
  margin-bottom: 1rem;
  text-align: center;
  position: relative;
  justify-content: center; }

.modal--country-mismatch-popup .language-switcher input[type="radio"]:checked + label {
  outline: 2px solid #3c3c3b; }
  .modal--country-mismatch-popup .language-switcher input[type="radio"]:checked + label::after {
    font-family: iconfont;
    content: var(--icon-check);
    position: absolute;
    top: 10px;
    right: 16px;
    display: inline-block;
    border-radius: 500px;
    font-size: 6px;
    line-height: 9px;
    padding: 5px;
    background: #292928;
    color: #fff;
    width: 18px;
    height: 18px; }

.friendlycaptcha {
  z-index: 1; }
  .friendlycaptcha .frc-container {
    font-size: .75rem;
    min-height: 46px; }
  .friendlycaptcha .frc-container svg.frc-icon {
    width: 28px;
    height: 28px;
    margin-top: 0; }
  .friendlycaptcha .frc-button {
    background: transparent;
    text-align: right;
    padding-right: 0; }
  .friendlycaptcha .frc-content {
    margin-right: 0;
    margin-top: 0; }
  .friendlycaptcha .frc-banner {
    margin-right: -6px; }
  .friendlycaptcha .frc-text {
    text-align: right; }

@media (min-width: 768px) {
  #mai__friendlycaptcha-overlay-toggle {
    position: fixed;
    z-index: -1;
    left: -9999px;
    opacity: 0;
    visibility: hidden; }
  .mai__friendlycaptcha-overlay-icon-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--color-red);
    cursor: pointer;
    transition: .2s ease-in color; }
    .mai__friendlycaptcha-overlay-icon-wrapper::before {
      content: '';
      display: block;
      width: 0.8em;
      height: 0.8em;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 9999px;
      transform: translate(-50%, -50%);
      transition: .2s ease-in background-color;
      background-color: var(--color-white);
      z-index: -1; }
  .mai__friendlycaptcha-overlay {
    position: absolute;
    top: 50%;
    right: 100%;
    max-width: none;
    width: 400px;
    padding: 5px 10px;
    opacity: 0;
    visibility: hidden;
    background: rgba(255, 255, 255, 0.9);
    transform: translateY(-50%);
    transition: .2s ease-in opacity, .2s ease-in visibility;
    z-index: 1; }
  #mai__friendlycaptcha-overlay-toggle:checked ~ .js-raffle__form .mai__friendlycaptcha-overlay-icon-wrapper,
  #mai__friendlycaptcha-overlay-toggle:checked ~ .mai__m8-newsletter__form .mai__friendlycaptcha-overlay-icon-wrapper {
    color: var(--color-white); }
    #mai__friendlycaptcha-overlay-toggle:checked ~ .js-raffle__form .mai__friendlycaptcha-overlay-icon-wrapper::before,
    #mai__friendlycaptcha-overlay-toggle:checked ~ .mai__m8-newsletter__form .mai__friendlycaptcha-overlay-icon-wrapper::before {
      background-color: var(--color-red); }
  #mai__friendlycaptcha-overlay-toggle:checked ~ .js-raffle__form .mai__friendlycaptcha-overlay,
  #mai__friendlycaptcha-overlay-toggle:checked ~ .mai__m8-newsletter__form .mai__friendlycaptcha-overlay {
    opacity: 1;
    visibility: visible; }
  .grecaptcha-badge {
    visibility: hidden; } }

/* Modules */
.pimcore_area_tiles .mai__slider-pagination {
  margin-top: 20px;
  margin-bottom: 0px; }

/* content */
.tab-module_nav-tabs {
  border: none;
  justify-content: center; }

.tab-module_nav-link, .tab-module_nav-link.active {
  background-color: transparent !important;
  border: none;
  outline: none;
  padding: 0;
  width: 100%;
  border-radius: 0;
  transition: opacity 0.15s linear; }

.tab-module_nav-link.active .tab-module_tab-label .h1, .tab-module_nav-link.active .h2, .tab-module_nav-link.active .h3, .tab-module_nav-link.active .h4, .tab-module_nav-link.active .h5, .tab-module_nav-link.active .h6, .tab-module_nav-link.active h1, .tab-module_nav-link.active h2, .tab-module_nav-link.active h3, .tab-module_nav-link.active h4, .tab-module_nav-link.active h5, .tab-module_nav-link.active h6 {
  font-weight: bold !important; }

.tab-module_nav-item {
  display: flex;
  flex-direction: column;
  width: 130px;
  text-align: center;
  position: relative; }

.tab-module_nav-link:not(.active), .tab-module_nav-link:not(.active) ~ * {
  opacity: 0.5; }

.tab-module_tab-label {
  margin-top: 1em;
  margin-bottom: 0.5em;
  word-break: break-word; }
  @media screen and (min-width: 1920px) {
    .tab-module_tab-label {
      font-size: calc(27rem / 16); } }
  @media screen and (max-width: 767px) {
    .tab-module_tab-label {
      font-size: calc(14rem / 16); } }

.tab-module_bottom-line {
  margin-top: 3px;
  width: 100%;
  border: 2.5px solid;
  border-radius: 8px; }

.tab-module_bottom-line--small {
  border: 1px solid;
  width: 60%;
  border-radius: 8px;
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: -5px;
  left: 50%; }

/* desktop modifications */
@media screen and (min-width: 768px) {
  .tab-module_nav-item {
    width: 256px; }
  .tab-module_nav-item + .tab-module_nav-item {
    margin-left: 20px; } }

/* content adjusts inside a tab */
.tab-module_tab-content > div > div .container, .tab-module_tab-content > div > div .container-fluid, .tab-module_tab-content > div > div .container-lg, .tab-module_tab-content > div > div .container-md, .tab-module_tab-content > div > div .container-sm, .tab-module_tab-content > div > div .container-xl, .tab-module_tab-content > div > div .container-xxl, .tab-module_tab-content > div > div .container-xxxl {
  padding-right: 0;
  padding-left: 0; }

/* content */
/* content */
.tab-module_nav-tabs {
  border: none;
  justify-content: center; }

.tab-module_nav-link, .tab-module_nav-link.active {
  background-color: transparent !important;
  border: none;
  outline: none;
  padding: 0;
  width: 100%;
  border-radius: 0;
  transition: opacity 0.15s linear; }

.tab-module_nav-link.active .tab-module_tab-label .h1, .tab-module_nav-link.active .h2, .tab-module_nav-link.active .h3, .tab-module_nav-link.active .h4, .tab-module_nav-link.active .h5, .tab-module_nav-link.active .h6, .tab-module_nav-link.active h1, .tab-module_nav-link.active h2, .tab-module_nav-link.active h3, .tab-module_nav-link.active h4, .tab-module_nav-link.active h5, .tab-module_nav-link.active h6 {
  font-weight: bold !important; }

.tab-module_nav-item {
  display: flex;
  flex-direction: column;
  width: 130px;
  text-align: center;
  position: relative; }

.tab-module_nav-link:not(.active), .tab-module_nav-link:not(.active) ~ * {
  opacity: 0.5; }

.tab-module_tab-label {
  margin-top: 1em;
  margin-bottom: 0.5em;
  word-break: break-word; }
  @media screen and (min-width: 1920px) {
    .tab-module_tab-label {
      font-size: calc(27rem / 16); } }
  @media screen and (max-width: 767px) {
    .tab-module_tab-label {
      font-size: calc(14rem / 16); } }

.tab-module_bottom-line {
  margin-top: 3px;
  width: 100%;
  border: 2.5px solid;
  border-radius: 8px; }

.tab-module_bottom-line--small {
  border: 1px solid;
  width: 60%;
  border-radius: 8px;
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: -5px;
  left: 50%; }

/* desktop modifications */
@media screen and (min-width: 768px) {
  .tab-module_nav-item {
    width: 256px; }
  .tab-module_nav-item + .tab-module_nav-item {
    margin-left: 20px; } }

/* content adjusts inside a tab */
.tab-module_tab-content > div > div .container, .tab-module_tab-content > div > div .container-fluid, .tab-module_tab-content > div > div .container-lg, .tab-module_tab-content > div > div .container-md, .tab-module_tab-content > div > div .container-sm, .tab-module_tab-content > div > div .container-xl, .tab-module_tab-content > div > div .container-xxl, .tab-module_tab-content > div > div .container-xxxl {
  padding-right: 0;
  padding-left: 0; }

.text-colorset-blue.tab-module_heading, .text-colorset-blue.tab-module_heading .heading {
  color: var(--color-petrol) !important; }

.text-colorset-blue.tab-module_nav-tabs .tab-module_tab-label,
.text-colorset-blue.tab-module_nav-tabs .tab-module_nav-link {
  color: var(--color-light-mint) !important; }

.text-colorset-blue.tab-module_nav-tabs .tab-module_bottom-line {
  border-color: var(--color-light-mint) !important;
  background-color: var(--color-light-mint) !important; }

.text-colorset-blue.tab-module_nav-tabs .tab-module_nav-link.active {
  color: var(--color-petrol) !important; }
  .text-colorset-blue.tab-module_nav-tabs .tab-module_nav-link.active .tab-module_tab-label {
    color: var(--color-petrol) !important; }
  .text-colorset-blue.tab-module_nav-tabs .tab-module_nav-link.active ~ .tab-module_bottom-line {
    border-color: var(--color-petrol) !important; }

.text-colorset-text.tab-module_heading, .text-colorset-text.tab-module_heading .heading {
  color: var(--color-anthracite) !important; }

.text-colorset-text.tab-module_nav-tabs .tab-module_tab-label,
.text-colorset-text.tab-module_nav-tabs .tab-module_nav-link {
  color: var(--color-anthracite) !important; }

.text-colorset-text.tab-module_nav-tabs .tab-module_bottom-line {
  border-color: var(--color-anthracite) !important;
  background-color: var(--color-anthracite) !important; }

.text-colorset-text.tab-module_nav-tabs .tab-module_nav-link.active {
  color: var(--color-anthracite) !important; }
  .text-colorset-text.tab-module_nav-tabs .tab-module_nav-link.active .tab-module_tab-label {
    color: var(--color-anthracite) !important; }
  .text-colorset-text.tab-module_nav-tabs .tab-module_nav-link.active ~ .tab-module_bottom-line {
    border-color: var(--color-anthracite) !important; }

.text-colorset-black.tab-module_heading, .text-colorset-black.tab-module_heading .heading {
  color: var(--color-black) !important; }

.text-colorset-black.tab-module_nav-tabs .tab-module_tab-label,
.text-colorset-black.tab-module_nav-tabs .tab-module_nav-link {
  color: var(--color-black) !important; }

.text-colorset-black.tab-module_nav-tabs .tab-module_bottom-line {
  border-color: var(--color-black) !important;
  background-color: var(--color-black) !important; }

.text-colorset-black.tab-module_nav-tabs .tab-module_nav-link.active {
  color: var(--color-black) !important; }
  .text-colorset-black.tab-module_nav-tabs .tab-module_nav-link.active .tab-module_tab-label {
    color: var(--color-black) !important; }
  .text-colorset-black.tab-module_nav-tabs .tab-module_nav-link.active ~ .tab-module_bottom-line {
    border-color: var(--color-black) !important; }

.text-colorset-white.tab-module_heading, .text-colorset-white.tab-module_heading .heading {
  color: var(--color-white) !important; }

.text-colorset-white.tab-module_nav-tabs .tab-module_tab-label,
.text-colorset-white.tab-module_nav-tabs .tab-module_nav-link {
  color: var(--color-white) !important; }

.text-colorset-white.tab-module_nav-tabs .tab-module_bottom-line {
  border-color: var(--color-white) !important;
  background-color: var(--color-white) !important; }

.text-colorset-white.tab-module_nav-tabs .tab-module_nav-link.active {
  color: var(--color-white) !important; }
  .text-colorset-white.tab-module_nav-tabs .tab-module_nav-link.active .tab-module_tab-label {
    color: var(--color-white) !important; }
  .text-colorset-white.tab-module_nav-tabs .tab-module_nav-link.active ~ .tab-module_bottom-line {
    border-color: var(--color-white) !important; }

.tab-module_nav-item-circle-nav {
  display: flex;
  align-self: center;
  justify-self: center;
  text-align: center;
  z-index: 2; }

.tab-module_nav-tabs-circle-nav {
  position: relative;
  width: 260px;
  height: 260px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr); }
  @media screen and (min-width: 768px) {
    .tab-module_nav-tabs-circle-nav {
      width: 360px;
      height: 360px; } }

.tab-module_nav-item-circle-nav:nth-child(1) {
  grid-area: 1 / 2; }

.tab-module_nav-item-circle-nav:nth-child(2) {
  grid-area: 2 / 3; }

.tab-module_nav-item-circle-nav:nth-child(3) {
  grid-area: 3 / 2; }

.tab-module_nav-item-circle-nav:nth-child(4) {
  grid-area: 2 / 1; }

.tab-module_nav-circle-nav-line {
  position: absolute;
  left: 16%;
  top: 16%;
  border-radius: 100%;
  border: 8px solid;
  width: 68%;
  height: 68%;
  pointer-events: none; }

.tab-module_nav-item-circle-nav img {
  width: 4em;
  height: 4em;
  border-radius: 100%;
  z-index: 3;
  position: relative; }

.tab-module_nav-item-circle-nav .tab-module_nav-link.active img {
  width: 6em;
  height: 6em;
  opacity: 1; }

.tab-module_nav-item-circle-nav .tab-module_nav-link:not(.active) {
  opacity: 1; }

.tab-module_nav-item-circle-nav .tab-module_nav-link:not(.active) .tab-module_nav-item-circle-nav-overlay {
  background-color: rgba(255, 255, 255, 0.5); }

.tab-module_nav-item-circle-nav-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 100%;
  z-index: 4; }

.tab-module_nav-circle-arrow-start {
  left: 10px;
  top: 10px;
  rotate: 3deg; }
  @media (min-width: 768px) {
    .tab-module_nav-circle-arrow-start {
      left: 25px;
      top: 30px; } }

.tab-module_nav-circle-arrow-end {
  right: 10px;
  bottom: 10px;
  rotate: 183deg; }
  @media (min-width: 768px) {
    .tab-module_nav-circle-arrow-end {
      right: 25px;
      bottom: 30px; } }

.tab-module_nav-circle-arrow-start, .tab-module_nav-circle-arrow-end {
  position: absolute;
  height: 80px; }
  @media (min-width: 768px) {
    .tab-module_nav-circle-arrow-start, .tab-module_nav-circle-arrow-end {
      height: 100px; } }

/* Utility & Helper Classes (mostly atomic classes; high specificity; eg: mt10; fz20)*/
