@import "../../../../js/libs/@elements/elements-job-bundle/styles/list.css";

:root {
    --ejb-list-border-color: #dee2e6;
    --ejb-list-head-background-color: #fff;
    --ejb-list-cell-background-color-hover: var(--color-light-grey);
    --ejb-list-ara__form-group-label-color: #A5ACAF;
}
.jobs__apply h3,
.ejb-heading {
    color:var(--color-primary);
}

.ejb-list-area__title {
    margin: 0 0 calc(15rem/16) 0;
    color:var(--color-primary);

    @media (min-width: 992px) {
        padding-left: calc(1.5vw + .4rem);
    }
}

.ejb-list-ara__filter {

    @media (min-width: 1921px) {
        position: relative;
        top: calc(10rem/16); 
    }
    
}

.ejb-list-ara__form-group {
    margin: 0 0 calc(20rem/16) 0;

    @media (min-width: 992px) {
        margin-bottom: calc(22rem/16);
    }
}

.ejb-list-ara__form-group-label {
    font-size: calc(16rem /16);
    color: var(--ejb-list-ara__form-group-label-color);
    transform: scale(.75)  translateX(-.375rem);
    position: relative;
    top: 4px;

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
        line-height: calc(50rem/16);

    }
}

.ejb-list__head {
    color: #000;
    text-transform: uppercase;
}

.ejb-list__cell {
    @media (max-width: 991px) {
        line-height: calc(23rem/16);
    }   
}

.ejb-list__cell--head {
    font-size: calc(12rem/16);
    line-height: calc(24rem/16);
    font-weight: var(--font-weight-bold);

    @media (min-width: 1921px) {
        font-size: calc(21rem/16);
        line-height: calc(32rem/16);
    }
}

tr td.ejb-list__cell--main {
    
}

td.ejb-list__cell--detail {
    padding-right: 0;
    text-align: right;
}

td.ejb-list__division {
    
}

td.ejb-list__cell--location {
    
}

.ejb-list__item-title {
    font-size: calc(14rem/16);
    padding: 0;
    margin: 0;
    text-transform: uppercase;

    @media (max-width: 991px) {
        line-height: calc(22rem/16);
    }

    @media (min-width: 992px) {
        font-size: calc(16rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
        line-height: calc(50rem/16)
    }
}

.ejb-list__item-title-link {
    color: var(--color-text-default);
    vertical-align: sub;
}

.ejb-list__item-title-link:hover,
.ejb-list__item-title-link:focus {
    color: var(--color-text-default);
}

.ejb-list__item-detail-link {
    color: var(--color-text-default);
    font-size: calc(10rem / 16);

    &:hover,
    &:focus {
        color: var(--color-text-default);
    }
}

.li-has-arrow {
    font-size: calc(10rem / 16);
}

.jobs__apply {
    background-color: var(--color-light-grey);
}

.mai__jobs-detail,
.mai__jobs-list {
    margin-bottom: calc(80rem/16);

    @media (min-width: 992px) {
        margin-bottom: calc(140rem/16);
    }
}

.mai__jobs-detail .ejb-heading {
    margin-bottom: calc(20rem/16);
    
    @media (min-width: 992px) {
        margin-bottom: calc(40rem/16);
    }

    @media (min-width: 1921px) {
        margin-bottom: calc(50rem/16);
    }
}