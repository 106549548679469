/* Workaround for core splideJS Bug, can't control elements with display: none */
.mai__m4-shop-the-look__nav-slider-wrapper {
    position: fixed;
    top: 0;
    left: -9999px;
    opacity: 0;
    pointer-events: none;

    @media (min-width: 992px) {
        position: static;
        opacity: 1;
        pointer-events: auto;
    }
}

.mai__m4-shop-the-look__nav-slider {
    @media (min-width: 992px) {
        border-top: 1px solid #f5f5f5;
    }
}

.mai__m4-shop-the-look__nav-slider-arrow {
    position: absolute;
    top: calc(70rem / 16);
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: calc(30rem / 16);
    height: calc(30rem / 16);

    background: var(--color-white) !important;
    border-radius: 9999px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.33);

    z-index: 1;

    @media (min-width: 992px) {
        top: calc(75rem / 16);
    }

    @media (min-width: 1200px) {
        top: calc(85rem / 16);
    }

    @media (min-width: 1400px) {
        top: calc(120rem / 16);
    }

    @media (min-width: 1921px) {
        top: calc(160rem / 16);
    }
}

.mai__m4-shop-the-look__nav-slider-arrow.splide__arrow--next {
    right: 0;
    left: auto;
}

.mai__m4-shop-the-look__nav-slider-item {
    cursor: pointer;
}

.mai__m4-shop-the-look__nav-slider-image-wrapper {
    border-radius: 6px;
    border: 1px solid #f5f5f5;
    background: #f4f4f6;

    transition: border-color 150ms ease-in-out;
}

.mai__m4-shop-the-look__nav-slider-item:hover .mai__m4-shop-the-look__nav-slider-image-wrapper,
.mai__m4-shop-the-look__nav-slider-item:focus .mai__m4-shop-the-look__nav-slider-image-wrapper,
.mai__m4-shop-the-look__nav-slider-item.is-active .mai__m4-shop-the-look__nav-slider-image-wrapper {
    border-color: var(--color-anthracite);
}

.mai__m4-shop-the-look__nav-slider-headline {
    max-width: calc(70rem / 16);

    font-weight: var(--font-weight-600);
    font-style: normal;
    color: var(--color-black);
    text-transform: none;

    @media (min-width: 992px) {
        max-width: calc(75rem / 16);
    }

    @media (min-width: 1200px) {
        max-width: calc(92rem / 16);
    }

    @media (min-width: 1400px) {
        max-width: calc(135rem / 16);
    }

    @media (min-width: 1921px) {
        max-width: calc(188rem / 16);
    }
}
