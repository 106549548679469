.mai__m43-pl-eyecatcher__container > .mai__m43-pl-eyecatcher__wrapper:nth-of-type(1) {
    order: 5;

    @media (min-width: 992px) {
        order: 7;
    }
}

.mai__m43-pl-eyecatcher__container > .mai__m43-pl-eyecatcher__wrapper:nth-of-type(2) {
    order: 14;

    @media (min-width: 992px) {
        order: 17;
    }
}

/* Mobile order */
.mai__m43-pl-eyecatcher__container > *:nth-of-type(n+5) {
    order: 6;

    @media (min-width: 992px) {
        order: 0;
    }
}

.mai__m43-pl-eyecatcher__container > *:nth-of-type(n+13) {
    order: 15;

    @media (min-width: 992px) {
        order: 0;
    }
}

/* Desktop order */
.mai__m43-pl-eyecatcher__container > *:nth-of-type(n+7) {
    @media (min-width: 992px) {
        order: 8;
    }
}

.mai__m43-pl-eyecatcher__container > *:nth-of-type(n+16) {
    @media (min-width: 992px) {
        order: 18;
    }
}
