.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: calc(20rem / 16);
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img:not(.ratio-item) {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a:not(.btn) {
    color: var(--color-primary);
}
.wysiwyg a:not(.btn):hover,
.wysiwyg a:not(.btn):focus,
.wysiwyg a:not(.btn):active {
    color: var(--color-primary);
    text-decoration: underline;
}

/* wysiwyg list */
.wysiwyg ul {
    list-style: none;
    padding: 0;
}
.wysiwyg ul li {
    position: relative;
    padding-left: calc(15rem / 16);

    @media (min-width: 1921px) {
        padding-left: calc(20rem / 16);
    }
}
.wysiwyg ul li:before {
    content: '';
    position: absolute;
    width: calc(5rem / 16);
    height: calc(5rem / 16);
    left: 0;
    top: calc(11rem / 16);
    background: var(--color-primary);

    @media (min-width: 1921px) {
        top: calc(21rem / 16);
        width: calc(6rem / 16);
        height: calc(6rem / 16);
    }
}
.wysiwyg ul.list-with-checkmarks>li {
    padding-left: calc(24rem / 16);
    margin-bottom: .5rem;
}
.wysiwyg ul.list-with-checkmarks>li:before {
    font-family: iconfont;
    content: var(--icon-check);
    color: #629677;
    font-size: .875rem;
    top:.125rem;
    background: transparent;
}

.wysiwyg hr:first-child {
    margin-top: 0;
}

/* wysiwyg-with-medium */
.wysiwyg-with-medium {
    @media (max-width: 767px) {
        padding: 0 calc(10rem / 16);
    }
}
.wysiwyg-with-medium__content {
    @media (min-width: 768px) {
        padding-right: calc(40rem / 16);

    }
}
.wysiwyg-with-medium__col--media + .wysiwyg-with-medium__col .wysiwyg-with-medium__content {
    @media (min-width: 768px) {
        padding-right: 0;
        padding-left: calc(40rem / 16);
    }
}

.wysiwyg-with-medium--text-only .wysiwyg-with-medium__content {
    @media (min-width: 768px) {
        padding: 0;
    }
}

/* small wysiwyg contents */
.wysiwyg--sm.wysiwyg li {
    padding-left: calc(7rem / 16);
}
.wysiwyg--sm.wysiwyg li:before {
    width: calc(3rem / 16);
    height: calc(3rem / 16);
    border-radius: 50%;
    top: calc(9rem / 16);
}