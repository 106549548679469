.mai__m16-icon {
    padding-top: calc(60rem/16);

    @media (min-width: 992px) {
        padding-top: calc(80rem/16);
    }
}

.mai__m16-icon--spaceBelow {
    margin-bottom: calc(80rem/16);

    @media (min-width: 992px) {
        margin-bottom: calc(140rem/16);
    }
}

.mai__m16-icon__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.mai__m16-icon__item {
    padding-bottom: calc(50rem/16);
    width: 50%;
    flex-basis: 50%;

    @media (min-width: 992px) {
        padding-bottom: calc(80rem/16);
        width: 25%;
        flex-basis: 25%;
    }
}

.mai__m16-icon__media {
    margin: 0 auto calc(20rem/16);
    width: calc(80rem/16);
    height: calc(80rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white);
    border-radius: 100%;
    box-shadow:  0px 0px 50px rgba(0, 0, 0, 0.1);

    @media (min-width: 992px) {
        margin-bottom: calc(40rem/16);
        width: calc(180rem/16);
        height: calc(180rem/16);

        box-shadow:  0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0425185),
                0px 20px 13px rgba(0, 0, 0, 0.035),
                0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815),
                0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519);
    }

    @media (min-width: 1921px) {
        width: calc(240rem/16);
        height: calc(240rem/16);
    }
}

.mai__m16-icon__icon {
    font-size: calc(20rem/16);

    @media (min-width: 992px) {
        font-size: calc(33rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(44rem/16);
    }

}

.mai__m16-icon__text {
    color: var(--color-anthracite);
    font-size: 1rem;
    font-weight: var(--font-weight-semibold);
    line-height: calc(34rem/16);

    @media (min-width: 992px) {
        font-size: calc(24rem/16);
        line-height: calc(30rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(43rem/16);
        line-height: calc(50rem/16);
    }
}

.pimcore_area_m16-icon + .pimcore_area_m16-icon .mai__m16-icon {
    padding-top: calc(40rem/16);
}