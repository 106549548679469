.mai__m26-checkout-cta-size-select__tabs {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mai__m26-checkout-cta-size-select__tab-wrapper {
    padding-left: calc(1rem / 16);
    padding-right: calc(1rem / 16);

    @media (min-width: 992px) {
        padding-left: calc(4rem / 16);
        padding-right: calc(4rem / 16);
    }

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
}

.mai__m26-checkout-cta-size-select__tab::before {
    display: none;
}

.mai__m26-checkout-cta-size-select__tab.mai__m26-checkout-cta-size-select__tab {
    display: block;
    text-align: center;
    width: 100%;
    color: #cbcbcb;
    border-bottom: 2px solid #cbcbcb;
    font-size: calc(16rem / 16);
    line-height: calc(24rem / 16);
    padding-bottom: calc(8rem / 16);

    transition: color .15s ease-in-out;

    @media (min-width: 1921px) {
        font-size: calc(24rem / 16);
        line-height: calc(43rem / 16);
    }
}

.mai__m26-checkout-cta-size-select__tab.active {
    color: var(--color-red);
    border-color: var(--color-red);
}
