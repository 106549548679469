.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.tab-pane::before {
    display: none;
}
