.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}

b,
strong,
.strong,
.font-default-bold {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}

.font-default-compact {
    font-family: var(--font-default-compact);
    font-weight: var(--font-default-compact-weight);
    font-style: var(--font-default-compact-style);
}

.font-semibold {
    font-weight: var(--font-weight-semibold);
}

small,
.small,
.text-small {
    font-size: var(--font-size-small-xs);
    line-height: var(--line-height-small-xs);

    @media (min-width: 992px) {
        font-size: var(--font-size-small);
        line-height: var(--line-height-small);
    }

    @media (min-width: 1921px) {
        font-size: var(--font-size-small-xxxl);
        line-height: var(--line-height-small-xxxl);
    }
}


blockquote,
.mai__quote {
    color: var(--color-anthracite);
    font-size: calc(32rem/16);
    font-family: var(--font-tertiary);
    font-weight: var(--font-weight-regular);
    line-height: calc(38rem/16);

    @media (min-width: 992px) {
        font-size: calc(48rem/16);
        line-height: calc(58rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(85rem/16);
        line-height: calc(103rem/16);
    }

    &.mai__quote--big {
        font-size: calc(48rem/16);
        line-height: calc(58rem/16);

        @media (min-width: 992px) {
            font-size: calc(100rem/16);
            line-height: calc(115rem/16);
        }

        @media (min-width: 1921px) {
            font-size: calc(178rem/16);
            line-height: calc(204rem/16);
        }
    }

    &.mai__quote--small {
        @media (min-width: 992px) {
            font-size: calc(32rem/16);
            line-height: calc(38rem/16);
        }

        @media (min-width: 1921px) {
            font-size: calc(57rem/16);
            line-height: calc(68rem/16);
        }
    }
}

.text-overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.text-overflow-sm-ellipsis {
    @media (min-width: 576px) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.text-overflow-md-ellipsis {
    @media (min-width: 768px) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.text-overflow-lg-ellipsis {
    @media (min-width: 992px) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.text-overflow-xl-ellipsis {
    @media (min-width: 1200px) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.text-overflow-xxl-ellipsis {
    @media (min-width: 1400px) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.text-overflow-clip {
    text-overflow: clip;
    overflow: visible;
    white-space: normal;
}

.text-overflow-sm-clip {
    @media (min-width: 576px) {
        text-overflow: clip;
        overflow: visible;
        white-space: normal;
    }
}

.text-overflow-md-clip {
    @media (min-width: 768px) {
        text-overflow: clip;
        overflow: visible;
        white-space: normal;
    }
}

.text-overflow-lg-clip {
    @media (min-width: 992px) {
        text-overflow: clip;
        overflow: visible;
        white-space: normal;
    }
}

.text-overflow-xl-clip {
    @media (min-width: 1200px) {
        text-overflow: clip;
        overflow: visible;
        white-space: normal;
    }
}

.text-overflow-xxl-clip {
    @media (min-width: 1400px) {
        text-overflow: clip;
        overflow: visible;
        white-space: normal;
    }
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.line-clamp-sm-2 {
    @media (min-width: 576px) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.line-clamp-md-2 {
    @media (min-width: 768px) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.line-clamp-lg-2 {
    @media (min-width: 992px) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.line-clamp-xl-2 {
    @media (min-width: 1200px) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.line-clamp-xxl-2 {
    @media (min-width: 1400px) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.line-clamp-none {
    display: initial;
    -webkit-line-clamp: none;
    -webkit-box-orient: inline-axis;
    overflow: visible;
    text-overflow: clip;
}

.line-clamp-sm-none {
    @media (min-width: 576px) {
        display: initial;
        -webkit-line-clamp: none;
        -webkit-box-orient: inline-axis;
        overflow: visible;
        text-overflow: clip;
    }
}

.line-clamp-md-none {
    @media (min-width: 768px) {
        display: initial;
        -webkit-line-clamp: none;
        -webkit-box-orient: inline-axis;
        overflow: visible;
        text-overflow: clip;
    }
}

.line-clamp-lg-none {
    @media (min-width: 992px) {
        display: initial;
        -webkit-line-clamp: none;
        -webkit-box-orient: inline-axis;
        overflow: visible;
        text-overflow: clip;
    }
}

.line-clamp-xl-none {
    @media (min-width: 1200px) {
        display: initial;
        -webkit-line-clamp: none;
        -webkit-box-orient: inline-axis;
        overflow: visible;
        text-overflow: clip;
    }
}

.line-clamp-xxl-none {
    @media (min-width: 1400px) {
        display: initial;
        -webkit-line-clamp: none;
        -webkit-box-orient: inline-axis;
        overflow: visible;
        text-overflow: clip;
    }
}

.text-color-anthracite {
    color: var(--color-anthracite);
}

.text-color-anthracite-50 {
    color: var(--color-anthracite-50);
}

.text-color-anthracite-40 {
    color: var(--color-anthracite-40);
}

.text-color-red {
    color: var(--color-red);
}

.text-color-petrol {
    color: var(--color-petrol);
}

.text-color-dark-mint {
    color: var(--color-dark-mint);
}
