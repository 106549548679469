.mai__m34-highlights__image-dot--active .mai__m4-shop-the-look__image-dot-inner,
.mai__m34-highlights__image-dot--active .mai__m4-shop-the-look__image-dot-outer::before,
.mai__m34-highlights__image-dot--active .mai__m4-shop-the-look__image-dot-outer::after {
    opacity: 1;
}

.mai__m34-highlights .bs-popover-auto[data-popper-placement^=bottom],
.mai__m34-highlights .bs-popover-bottom {
    margin-top: calc(40rem / 16) !important;
}

.mai__m34-highlights .bs-popover-auto[data-popper-placement^=right],
.mai__m34-highlights .bs-popover-end {
    margin-left: calc(40rem / 16) !important;
}

.mai__m34-highlights .bs-popover-auto[data-popper-placement^=left],
.mai__m34-highlights .bs-popover-start {
    margin-right:  calc(40rem / 16) !important;
}

.mai__m34-highlights .bs-popover-auto[data-popper-placement^=top],
.mai__m34-highlights .bs-popover-top {
    margin-bottom: calc(40rem / 16) !important;
}

.mai__m34-highlights .mai__popover {
    padding: calc(20rem / 16);
    border-radius: calc(4rem / 16);
    box-shadow: 0 calc(1.85rem / 16) calc(3.15rem / 16) 0 rgba(0,0,0,.02),
                0 calc(8.15rem / 16) calc(6.52rem / 16) 0 rgba(0,0,0,.03),
                0 calc(20rem / 16) calc(13rem / 16) 0 rgba(0,0,0,.04),
                0 calc(38.52rem / 16) calc(25.48rem / 16) 0 rgba(0,0,0,.04);
}

.mai__m34-highlights .mai__popover .arrow {
    display: none;
}

.mai__m34-highlights .mai__popover-header {
    margin-bottom: calc(15rem / 16);
}

.mai__m34-highlights .mai__popover,
.mai__m34-highlights .mai__popover__header,
.mai__m34-highlights .mai__popover__text {
    font-size: inherit;
    line-height: inherit;
}
