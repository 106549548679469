.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top:3rem;
    background: rgba(255, 255, 255, .7);
    z-index: 10;
    text-align: center;
}
.loading-overlay--centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}