.mai__m34-activities__headline {
    font-size: calc(12rem / 16);
    line-height: calc(24rem / 16);
    color: var(--color-white);

    border-radius: calc(4rem / 16);

    background: var(--color-anthracite);
}

.mai__m34-activities__headline .icon {
    font-size: calc(12rem / 16);

    @media (min-width: 992px) {
        font-size: calc(24rem / 16);
    }

    @media (min-width: 1921px) {
        font-size: calc(43rem / 16);
    }
}

.mai__m34-activities__activity .icon {
    font-size: calc(40rem / 16);

    @media (min-width: 992px) {
        font-size: calc(65rem / 16);
    }

    @media (min-width: 1921px) {
        font-size: calc(90rem / 16);
    }
}

.mai__m34-activities__activity-label {
    @media (min-width: 992px) {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem / 16);
        line-height: calc(43rem / 16);
    }
}

.mai__m34-activities__activity-levels {
    width: 60%;
    flex-shrink: 0;

    display: flex;
    justify-content: space-between;

    margin-left: calc(-3rem / 16);
    margin-right: calc(-3rem / 16);

    @media (min-width: 992px) {
        margin-left: calc(-5rem / 16);
        margin-right: calc(-5rem / 16);
    }
}

.mai__m34-activities__activity-level {
    flex: 1 0;

    display: flex;
    height: calc(3rem / 16);

    border-radius: calc(8rem / 16);
    padding-left: calc(3rem / 16);
    padding-right: calc(3rem / 16);

    @media (min-width: 992px) {
        height: calc(5rem / 16);
        padding-left: calc(5rem / 16);
        padding-right: calc(5rem / 16);
    }

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;

        border-radius: inherit;

        background: rgba(49,51,48,.2);
    }
}

.mai__m34-activities__activity-level--active {
    &::before {
        background: var(--color-red);
    }
}
