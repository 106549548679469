.mai__m6-hero-stage__item--mintpetrol .mai__m6-hero-stage__item-inner {
    background-color: var(--color-mintpetrol);
}

.mai__m6-hero-stage__item--mintpetrol .mai__m6-hero-stage__topline,
.mai__m6-hero-stage__item--mintpetrol .mai__m6-hero-stage__headline,
.mai__m6-hero-stage__item--mintpetrol .mai__m6-hero-stage__cta-buttons .mai__btn {
    color: var(--color-white);
}

.mai__m6-hero-stage__item--mintpetrol .mai__m6-hero-stage__cta-buttons .mai__btn:hover,
.mai__m6-hero-stage__item--mintpetrol .mai__m6-hero-stage__cta-buttons .mai__btn:focus {
    color: var(--color-mintpetrol);
    border-color: var(--color-white);
    background-color: var(--color-white);
}

.mai__m6-hero-stage__item--lightmint .mai__m6-hero-stage__item-inner {
    background-color: var(--color-light-mint);
}

.mai__m6-hero-stage__item--lightmint .mai__m6-hero-stage__topline,
.mai__m6-hero-stage__item--lightmint .mai__m6-hero-stage__headline,
.mai__m6-hero-stage__item--lightmint .mai__m6-hero-stage__cta-buttons .mai__btn {
    color: var(--color-petrol);
}

.mai__m6-hero-stage__item--lightmint .mai__m6-hero-stage__cta-buttons .mai__btn:hover,
.mai__m6-hero-stage__item--lightmint .mai__m6-hero-stage__cta-buttons .mai__btn:focus {
    color: var(--color-light-mint);
    border-color: var(--color-petrol);
    background-color: var(--color-petrol);
}

.mai__m6-hero-stage__item--petrol .mai__m6-hero-stage__item-inner {
    background-color: var(--color-petrol);
}

.mai__m6-hero-stage__item--petrol .mai__m6-hero-stage__cta-buttons .mai__btn:hover,
.mai__m6-hero-stage__item--petrol .mai__m6-hero-stage__cta-buttons .mai__btn:focus {
    color: var(--color-petrol);
    border-color: var(--color-white);
    background-color: var(--color-white)
}

.mai__m6-hero-stage__item--warmgrey .mai__m6-hero-stage__item-inner {
    background-color: var(--color-warm-grey);
}

.mai__m6-hero-stage__item--warmgrey .mai__m6-hero-stage__topline,
.mai__m6-hero-stage__item--warmgrey .mai__m6-hero-stage__headline,
.mai__m6-hero-stage__item--warmgrey .mai__m6-hero-stage__cta-buttons .mai__btn {
    color: var(--color-red);
}

.mai__m6-hero-stage__item--warmgrey .mai__m6-hero-stage__cta-buttons .mai__btn:hover,
.mai__m6-hero-stage__item--warmgrey .mai__m6-hero-stage__cta-buttons .mai__btn:focus {
    color: var(--color-warm-grey);
}

.mai__m6-hero-stage__item--yellow .mai__m6-hero-stage__item-inner {
    background-color: var(--color-yellow);
}

.mai__m6-hero-stage__item--yellow .mai__m6-hero-stage__topline,
.mai__m6-hero-stage__item--yellow .mai__m6-hero-stage__headline,
.mai__m6-hero-stage__item--yellow .mai__m6-hero-stage__cta-buttons .mai__btn {
    color: var(--color-petrol);
}

.mai__m6-hero-stage__item--yellow .mai__m6-hero-stage__cta-buttons .mai__btn:hover,
.mai__m6-hero-stage__item--yellow .mai__m6-hero-stage__cta-buttons .mai__btn:focus {
    color: var(--color-yellow);
    border-color: var(--color-petrol);
    background-color: var(--color-petrol);
}
