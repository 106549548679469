.mai__product-grid__modal {
    @media (min-width: 992px) {
        position: static;

        display: block;
        width: auto;
        height: auto;

        overflow: visible;

        z-index: auto;
        transform: none;
    }
}

.mai__product-grid__modal.mai__product-grid__modal {
    @media (min-width: 992px) {
        opacity: 1;
    }
}

.mai__product-grid__modal .modal-header {
    border-bottom: 0;
}

.mai__product-grid__modal .modal-dialog.modal-dialog {
    @media (min-width: 992px) {
        max-width: none;
        height: auto;

        transform: none;
    }
}

.mai__product-grid__modal .modal-content {
    @media (min-width: 992px) {
        max-height: none;
        overflow: visible;
        border-radius: 0;

        background: transparent;
    }
}

.mai__product-grid__modal .modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1;
}

.mai__product-grid .mai__product-grid__modal-footer-btn {
    color: var(--color-white);
    font-weight: var(--font-weight-600);
    font-size: calc(16rem / 16);
    line-height: calc(28rem / 16);

    border-color: var(--color-petrol);
    border-radius: calc(8rem / 16);

    background-color: var(--color-petrol);
    overflow: hidden;

    box-shadow: 0 calc(1.85rem / 16) calc(3.15rem / 16) 0 rgba(0,0,0,.02),
                0 calc(8.15rem / 16) calc(6.52rem / 16) 0 rgba(0,0,0,.03),
                0 calc(20rem / 16) calc(13rem / 16) 0 rgba(0,0,0,.04),
                0 calc(38.52rem / 16) calc(25.48rem / 16) 0 rgba(0,0,0,.04);

    &:hover,
    &:focus {
        background-color: var(--color-petrol);
    }
}
