.mai__m30a-awards__content {
    margin-left: calc(-2 * var(--bs-gutter-x));
    margin-right: calc(-2 * var(--bs-gutter-x));

    @media (min-width: 992px) {
        margin-left: 0;
        margin-right: 0;
    }
}

.mai__m30a-awards__topline {
    background-color: var(--color-light-beige);
    border-radius: 4px;

    @media (min-width: 992px) {
        padding-top: calc(8rem/16);
        padding-bottom: calc(8rem/16);
    }
}

.mai__m30a-awards__topline .icon {
    color: var(--color-red);
    font-size: calc(12rem/16);

    @media (min-width: 992px) {
        color: var(--color-anthracite);
        font-size: calc(18rem/16);
    }
}

.mai__m30a-awards__topline__text {
    color: var(--color-red);
    font-size: calc(12rem/16);
    line-height: calc(24rem/16);
    text-transform: uppercase;

    @media (min-width: 992px) {
        color: var(--color-anthracite);
        font-size: calc(16rem/16);
    }

}

.mai__m30a-awards__items {
    @media (min-width: 992px) {
        flex-wrap: wrap !important;
    }
}

.mai__m30a-awards__item {
    flex: 1 0 calc(210rem/16);

    @media (min-width: 992px) {
        display: flex;
        flex: 0 0 50%;
        margin-bottom: calc(60rem/16);
    }
}

.mai__m30a-awards__item:nth-child(odd) {
    @media (min-width: 992px) {
        padding-right: calc(4.166665% + 0.625rem);
    }
}

.mai__m30a-awards__item:nth-child(even) {
    @media (min-width: 992px) {
        padding-left: calc(4.166665% + 0.625rem);
    }  
}

.mai__m30a-awards__info {
    @media (min-width: 992px) {
        padding-right: calc(45rem/16);
    }
}

.mai__m30a-awards__item__heading,
.mai__m30a-awards__item__subline,
.mai__m30a-awards__item__text {
    color: var(--color-black);
}

.mai__m30a-awards__item__subline {
    font-weight: var(--font-weight-semibold);
    line-height: calc(22rem/16);
    text-transform: uppercase;
   
   @media (min-width: 992px) {
    line-height: calc(24rem/16);
   }

   @media (min-width: 1921px) {
    line-height: calc(43rem/16);
   }
}

.mai__m30a-awards__media {
    height: calc(130rem/16);
    width: calc(130rem/16);
    flex: 0 0 auto;

    @media (min-width: 1921px) {
        height: calc(190rem/16);
        width: calc(190rem/16);
    }
}

.mai__m30a-awards__media__image {
    display: inline-block;
    width: 100%;
    height: auto;
    object-fit: cover;
} 