h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    margin-bottom: 0;
    display: block;
    font-family: var(--font-primary);
    font-weight: var(--font-weight-semibold);
    font-style: normal;
    color: var(--color-anthracite);
    letter-spacing: 0;
}

h1, .h1, .mai__editorial {
    font-size: calc(32rem/16);
    line-height: calc(38rem/16);

    @media (min-width: 992px) {
        font-size: calc(48rem/16);
        line-height: calc(60rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(85rem/16);
        line-height: calc(107rem/16);
    }
}

h2, .h2 {
    font-size: calc(24rem/16);
    line-height: calc(30rem/16);

    @media (min-width: 992px) {
        font-size: calc(32rem/16);
        line-height: calc(40rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(57rem/16);
        line-height: calc(71rem/16);
    }
}

h3, .h3 {
    font-size: calc(16rem/16);
    line-height: calc(24rem/16);

    @media (min-width: 992px) {
        font-size: calc(24rem/16);
        line-height: calc(30rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(43rem/16);
        line-height: calc(53rem/16);
    }
}

h4, .h4 {
    font-size: calc(15rem/16);
    line-height: calc(23rem/16);

    @media (min-width: 992px) {
        font-size: calc(20rem/16);
        line-height: calc(27rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(35rem/16);
        line-height: calc(48rem/16);
    }
}

h5, .h5,
.mai__subline {
    font-size: calc(14rem/16);
    line-height: calc(22rem/16);

    @media (min-width: 992px) {
        font-size: calc(16rem/16);
        line-height: calc(24rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
        line-height: calc(43rem/16);
    }
}

.no-hl-style {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    font-style: normal;
    text-transform: none;
    color: inherit;
}
