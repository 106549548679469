/* #47270: rgba Farbwert macht Probleme im Safari, stattdessen wird nicht transparenter Farbwert auf weißen Hintergrund genommen */
.mai__m17-accordion-grafik__item {
    border-radius: 0;
    border: none;
    /* border-bottom: 1px solid rgba(0,0,0,.25); */
    border-bottom: 1px solid #bfbfbf;
    box-shadow: none;
}

.mai__m17-accordion-grafik__item:last-of-type {
    border-radius: 0;
}


.mai__m17-accordion-grafik__item .accordion-button,
.mai__m17-accordion-grafik__item .accordion-button:focus {
    font-size: inherit;
    line-height: inherit;
    font-weight: var(--font-weight-600);
    color: var(--color-anthracite);
    border: none;
    outline: none;
    box-shadow: none;border-radius: 0;
}

.mai__m17-accordion-grafik__item .accordion-button::after {
    display: none;
}

.mai__m17-accordion-grafik__accordion-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    width: calc(30rem / 16);
    height: calc(30rem / 16);

    transform: rotate(90deg);
    transition: transform .15s ease-in-out;
}

.collapsed .mai__m17-accordion-grafik__accordion-icon-wrapper {
    transform: rotate(0deg);
}

.mai__m17-accordion-grafik__accordion-icon-wrapper .icon {
    font-size: calc(13.3333rem / 16);

    @media (min-width: 1921px) {
        font-size: calc(24rem / 16);
    }
}

.mai__m17-accordion-grafik .mai__m14-gallery__image {
    object-fit: cover;
}
