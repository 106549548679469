.mai__m31-outtra-local {
    color: var(--color-anthracite);

    & * button.mai__btn {
        width: auto !important;
    }
}

.mai__m31-outtra-local #outtra-base-container {
    margin-top: calc(40rem / 16);

    @media (min-width: 992px) {
        margin-top: 0;
    }
}

.mai__m31-outtra-local .outtra-inplace-container {
    margin-top: 0;
}

.mai__m31-outtra-local .outtra-show-place-search-container {
    margin-bottom: calc(15rem / 16);
}

.mai__m31-outtra-local ul#outtra-shop-suggestions-list {
    margin-top: 0;
}

.mai__m31-outtra-local .outtra-place-search-display {
    font-weight: var(--font-weight-semibold);
    text-decoration: none;
}

.mai__m31-outtra-local li.outtra-shop-list-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mai__m31-outtra-local #outtra-shop-suggestions-list .outtra-shop-list-item {
    margin-bottom: calc(10rem / 16);

    &:last-child {
        margin-bottom: 0;
    }
}

.mai__m31-outtra-local .outtra-inplace-list-item-grid-item {
    padding-left: calc(10rem / 16);
    padding-right: calc(10rem / 16);
    border-color: #8cbebe;
}

.mai__m31-outtra-local .outtra-shop-info-name,
.mai__m31-outtra-local .outtra-shop-info-distance-text {
    font-weight: var(--font-weight-semibold);
}

.mai__m31-outtra-local .outtra-shop-info {
    display: flex;
    width: 100%;

    @media (max-width: 767px) {
        border-right: 0;
    }

    @media (min-width: 768px) {
        width: 40%;
    }
}

.mai__m31-outtra-local .outtra-distance-icon {
    flex-shrink: 0;

    width: calc(24rem / 16);
    height: calc(24rem / 16);

    @media (min-width: 1921px) {
        width: calc(44rem / 16);
        height: calc(44rem / 16);

        margin-right: calc(20rem / 16);
    }
}

.mai__m31-outtra-local .outtra-shop-info-available-sizes,
.mai__m31-outtra-local .outtra-shop-info-distance {
    flex: 1 0;
}

.mai__m31-outtra-local .outtra-shop-info-available-sizes-text {
    color: var(--color-anthracite-50);
}

.mai__m31-outtra-local .outtra-shop-info-available-sizes-list {
    color: var(--color-anthracite);
}

/* .text-small */
.mai__m31-outtra-local .outtra-modal-trigger-button {
    width: auto;
    height: auto;

    margin-top: calc(10rem / 16);
    padding: 0;

    font-size: var(--font-size-small-xs);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-small-xs);

    text-align: left;
    text-decoration: none;

    @media (min-width: 768px) {
        font-size: var(--font-size-small);
        line-height: var(--line-height-small);
    }

    @media (min-width: 1921px) {
        font-size: var(--font-size-small-xxxl);
        line-height: var(--line-height-small-xxxl);
    }
}

/* Outtra modal */
.modal.micromodal-slide {
    transform: translateZ(99999px)
}

.outtra-offers {
    transform: translateZ(99999px)
}
