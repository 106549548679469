.mai__m32-product-informations__video .mai__base-video__play-btn,
.mai__m32-product-informations__video .mai__base-video__pause-btn,
.mai__m32-product-informations__video .js-video_youtube-container .video-js__playbtn {
    display: flex;
    align-items: start;
    justify-content: end;

    padding-top: calc(20rem/16);
    padding-right: calc(20rem/16);
}

.mai__m32-product-informations__image {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}