.mai__m1-magazine-teaser {
    margin-bottom: calc(60rem/16);

    @media (min-width: 992px) {
        margin-bottom: calc(140rem/16);
    }
}

.mai__m1-magazine-teaser__content.splide {
    margin-left: calc(20rem/16 * -1);
    margin-right: calc(20rem/16 * -1);

    @media (min-width: 992px) {
        margin-left: calc(40rem/16 * -1);
        margin-right: calc(40rem/16 * -1);
    }
}

.mai__m1-magazine-teaser__heading .mai__module-headline {
    font-size: calc(16rem/16);
    line-height: calc(28rem/16);
    margin-bottom: 0 !important;

    @media (min-width: 992px) {
        font-size: calc(24rem/16);
        margin-bottom: calc(20rem/16) !important;
    }

    @media (min-width: 1921px) {
        font-size: calc(43rem/16);
        line-height: calc(50rem/16);
    }
}

.mai__m1-magazine-teaser__heading .mai__module-headline .icon {
    @media (min-width: 1921px) {
        font-size: calc(24rem/16);
    }
}

.mai__m1-magazine-teaser__items:not(.splide__list) {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 calc(-10rem/16);
}

.mai__m1-magazine-teaser__item {
    padding: calc(20rem/16) 0 0;
    flex: 1;

    &:not(.splide__slide) {
        padding: 0 calc(10rem/16);
    }

    &.splide__slide {
        flex: 0 0 calc(249rem/16);

        @media (min-width: 992px) {
            flex: 0 0 31.8%;
        }

        @media (min-width: 1200px) {
            flex: 0 0 32.1%;
        }
    }
}

.mai__m1-magazine-teaser__link {
    text-decoration: none;
}

.mai__m1-magazine-teaser__item:hover .mai__m1-magazine-teaser__img,
.mai__m1-magazine-teaser__item:hover .mai__m1-magazine-teaser__media img,
.mai__m1-magazine-teaser__item:focus .mai__m1-magazine-teaser__img,
.mai__m1-magazine-teaser__item:focus .mai__m1-magazine-teaser__media img {
    
    @media (min-width: 992px) {
        transform: scale(1.05);
        box-shadow: 0 calc(1.85rem / 16) calc(3.15rem / 16) 0 rgba(0,0,0,.02),
                    0 calc(8.15rem / 16) calc(6.52rem / 16) 0 rgba(0,0,0,.03),
                    0 calc(20rem / 16) calc(13rem / 16) 0 rgba(0,0,0,.04),
                    0 calc(38.52rem / 16) calc(25.48rem / 16) 0 rgba(0,0,0,.04);
    }

    @media (min-width: 1200px) {
        transform: scale(1.038);
    }

    @media (min-width: 1200px) {
        transform: scale(1.02);
    }

}

.mai__m1-magazine-teaser__media {
    margin-bottom: calc(20rem/16);

    @media (min-width: 992px) {
        position: relative;
        padding-bottom: 95.454%;
    }
}

.mai__m1-magazine-teaser__media--2 {
    @media (min-width: 992px) {
        padding-bottom: 95.652%;
    }
}

.mai__m1-magazine-teaser__img,
.mai__m1-magazine-teaser__media img {
    display: block;
    border-radius: 8px;
    width: 100%;
    height: calc(320rem/16);
    transition: all 0.3s ease;
    object-fit: cover;

    @media (min-width: 992px) {
        position: absolute;
        height: 100%;
    }
}

.mai__m1-magazine-teaser__tag {
    color: var(--color-mintpetrol);
    font-size: calc(12rem/16);
    font-weight: var(--font-weight-600);
    line-height: calc(22rem/16);

    display: inline-block;
    margin-bottom: calc(10rem/16);
    padding: 0 calc(6rem/16);

    border: 1px solid var(--color-mintpetrol);
    border-radius: calc(4rem/16);

    text-transform: none;

    @media (min-width: 992px) {
        margin-bottom: calc(5rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(21rem/16);
        line-height: calc(32rem/16);
    }
}

.mai__m1-magazine-teaser__titel,
.mai__m1-magazine-teaser__text {
    text-transform: none;
    text-overflow: ellipsis;
    overflow: hidden;

    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.mai__m1-magazine-teaser__titel {
    --m1-title-line-height: calc(22rem/16);
    --m1-title-line-clamp: 2;
    --m1-title-height: calc(var(--m1-title-line-height) * var(--m1-title-line-clamp));

    color: var(--color-anthracite);
    font-size: calc(14rem/16);
    font-weight: var(--font-weight-600);
    line-height: var(--m1-title-line-height);
    height: var(--m1-title-height);
    max-height: var(--m1-title-height);

    margin-bottom: calc(15rem/16);
    -webkit-line-clamp: 2;

    @media (min-width: 992px) {
        --m1-title-line-clamp: 1;

        -webkit-line-clamp: var(--m1-title-line-clamp);
        height: auto;
        max-height: unset;

        font-size: calc(16rem/16);
        line-height: calc(24rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
        line-height: calc(43rem/16);
    }
}

.mai__m1-magazine-teaser__text {
    --m1-text-line-height: calc(28rem/16);
    --m1-text-line-clamp: 4;
    --m1-text-height: calc(var(--m1-text-line-height) * var(--m1-text-line-clamp));


    color: var(--color-anthracite);
    font-size: calc(14rem/16);
    font-weight: var(--font-weight-400);
    line-height: var(--m1-text-line-height);

    max-height: var(--m1-text-height);
    height: var(--m1-text-height);
    margin-bottom: calc(20rem/16);

    -webkit-line-clamp: var(--m1-text-line-clamp);

    @media (min-width: 992px) {
        --m1-text-line-height: calc(22rem/16);

        font-size: calc(12rem/16);
        line-height: calc(22rem/16);
        margin-bottom: calc(30rem/16);
    }

    @media (min-width: 1921px) {
        --m1-text-line-height: calc(32rem/16);

        font-size: calc(21rem/16);
        line-height: var(--m1-text-line-height);
        height: var(--m1-text-height);
        max-height: (--m1-text-height);

    }
}

.mai__m1-magazine-teaser__link {
    color: var(--color-red);
    font-size: calc(12rem/16);
    font-weight: var(--font-weight-600);
    line-height: calc(22rem/16);
    text-decoration: none;
    text-transform: uppercase;

    @media (min-width: 1921px) {
        font-size: calc(21rem/16);
    }
}

.mai__m1-magazine-teaser__arrows {
    display: none !important;

    @media (min-width: 992px) {
        right: calc(20rem/16);
    }

    @media (min-width: 1200px) {
        right: calc(30rem/16);
    }

    @media (min-width: 1921px) {
        right: calc(45rem/16);
    }
}

.mai__m1-magazine-teaser__content.is-initialized .mai__m1-magazine-teaser__arrows {
    @media (min-width: 992px) {
        display: flex !important;
    }
}

.mai__m1-magazine-teaser .mai__slider-arrows {
    @media (min-width: 992px) {
        top: calc(50% - 6.75rem);
    }

    @media (min-width: 1921px) {
        top: calc(50% - 12.281rem);
    }
}