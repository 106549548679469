:root {
    --m52-arrows-bottom: calc(50rem/16);
    --m52-arrows-bottom--xxl: calc(60rem/16);

}

.mai__m52-social-wall {
    position: relative;
    margin-bottom: calc(80rem/16);

    @media (min-width: 992px) {
        margin-bottom: calc(140rem/16 + var(--m52-arrows-bottom));
    }
}

.mai__m52-social-wall__heading {
    margin-bottom: calc(15rem/16);

    @media (min-width: 992px) {
        margin-bottom: calc(30rem/16);
    }

    @media (min-width: 1921px) {
        margin-bottom: calc(40rem/16);
    }
}

.mai__m52-social-wall__slider {
    padding-top: calc(20rem/16);
}

.mai__m52-social-wall__content {
    margin-left: calc(20rem/16 * -1);
    margin-right: calc(20rem/16 * -1);

    @media (min-width: 992px) {
        margin-left: calc(40rem/16 * -1);
        margin-right: calc(40rem/16 * -1);
        margin-bottom: var(--m52-arrows-bottom);
    }

    @media (min-width: 1921px) {
        margin-bottom: var(--m52-arrows-bottom--xxl);
    }
    
}

.mai__m52-social-wall__media {
    margin-bottom: calc(20rem/16);
    transition: all 0.3s ease;
    border-radius: 0.5rem;

    @media (min-width: 1921px) {
        margin-bottom: calc(30rem/16);
    }
}

.mai__m52-social-wall__item:hover .mai__m52-social-wall__media,
.mai__m52-social-wall__item:focus .mai__m52-social-wall__media {
    @media (min-width: 992px) {
        transform: scale(1.05);
        box-shadow: 0 .11563rem .19688rem 0 rgba(0,0,0,.02),0 .50938rem .4075rem 0 rgba(0,0,0,.03),0 1.25rem .8125rem 0 rgba(0,0,0,.04),0 2.4075rem 1.5925rem 0 rgba(0,0,0,.04);
    }   
} 

.mai__m52-social-wall .mai__base-video__play-btn,
.mai__m52-social-wall .js-video_youtube-container .video-js__playbtn {
    @media (min-width: 992px) {
        font-size: calc(24rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(42rem/16);
    }
}

.mai__m52-social-wall .mai__base-video__play-text,
.mai__m52-social-wall .js-video_youtube-container .play-text {
    @media (min-width: 992px) {
        font-size: calc(16rem/16);
        line-height: calc(34rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(32rem/16);
        line-height: calc(40rem/16);
    }
}

.mai__m52-social-wall__text {
    @media (min-width: 992px) {
        padding-bottom: calc(30rem/16);
    }

    @media (min-width: 1921px) {
        padding-bottom: calc(50rem/16);
    }
}

.mai__m52-social-wall__arrows {
    display: none;

    @media (min-width: 992px) {
        display: inline-block;
        bottom: calc(-1 * var(--m52-arrows-bottom));
        top: auto;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }

    @media (min-width: 1921px) {
        bottom: calc(-1 * var(--m52-arrows-bottom--xxl));
    }

}

/* Hide the play button (fix Safari) */

.mai__m52-social-wall video
{
    opacity: 0;
}

.mai__m52-social-wall .js-video_youtube-container.is-playing video,
.mai__m52-social-wall .mai__base-video--is-playing video {
    opacity: 1;
}