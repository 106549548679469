:root {
    --header-height: calc(80rem/16);
    --header-height-mobile: calc(60rem/16);
    --header-height-xxl: calc(142rem/16);
    --flyout-height-xl: calc(690rem/16);
    --flyout-max-height-xl: calc(98vh - var(--header-height) - 4rem);
    /*--flyout-height-xxxl: calc(1227rem/16);*/
    --flyout-height-xxxl: calc(1140rem/16);
    --flyout-max-height-xxxl: calc(98vh - var(--header-height-xxl) - 4rem);
}

.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1070;
    transform: translateZ(1070px);
    pointer-events: none;
}

.metabar{
    z-index: 0;
    height: var(--metabar-height);
    pointer-events: auto;
}

.header__nav{
    background-color: white;
    height: var(--header-height-mobile);
    z-index: 1;
    pointer-events: auto;
    border-bottom: 1px solid #eee;
    @media (min-width: 768px) {
        height: var(--header-height);
    }

    @media (min-width: 1921px) {
        height: var(--header-height-xxl);
    }
}

.header__nav.open {
    @media (max-width: 1199px) {
        background-color: var(--color-warm-grey);
        border-bottom-color: transparent;
    }
}

.header__nav__wrapper {
    height: 100%;
}

.header__btn {
    height: 100%;

    padding: 0.5em .25em;
    border: 0;
    margin: 0;

    @media (min-width: 576px) {
        padding-right: .5em;
        padding-left: .5em;
    }
}

.header__btn.show,
.btn:first-child:active {
    border-color: transparent;
}

.header__btn > .icon {
    color: var(--color-red);
    font-size: calc(18rem / 16);

    @media (min-width: 768px) {
        font-size: calc(24rem / 16);
    }

    @media (min-width: 1921px) {
        font-size: calc(39rem / 16);
    }
}

.header__logo__wrapper {
    @media (min-width: 1200px) {
        max-width: calc(160rem / 16);
    }
}

.header__logo{
    height: calc((2.4rem / 16) * 10);
    width: calc((12rem / 16) * 10);
    display: block;
    flex-shrink: 0;
    margin: 0 auto;

    @media (min-width: 768px) {
        height: calc((3.4rem / 16) * 10);
        width: calc((17rem / 16) * 10);
    }

    @media (min-width: 1200px) {
        height: calc((3rem / 16) * 10);
        width: calc((14.6rem / 16) * 10);
    }

    @media (min-width: 1921px) {
        height: calc((5.325rem / 16) * 10);
        width: calc((25.959rem / 16) * 10);
    }
}

.header__logo__image {
    width: 100%;
    height: auto;
}

.header__bookmark.is-active .icon-heart,
.header__bookmark .icon-heart-filled {
    display: none;
}

.header__bookmark .icon-heart,
.header__bookmark.is-active .icon-heart-filled {
    display: block;
}

.header__opennav,
.header__btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:focus {
        box-shadow: none;
    }
}


#main-nav{
    flex-wrap: nowrap;
    margin-bottom: 0;

    @media (max-width: 1199px) {
        flex-direction: column;
        padding-top: calc(45rem/16)!important; /** because of back button in 2nd layer **/ /** 60px - 15px **/
        padding-bottom: calc(60rem/16); /** 75px - 15px **/
    }
    @media (min-width: 1200px) {
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.nav-items {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.nav-items-list {
    padding-bottom: calc(65rem/16); /** 75px - 10px **/

    @media (min-width: 1200px) {
        padding-bottom: calc(36rem/16); /** 50px - 14px **/

    }

    @media (min-width: 1921px) {
        padding-bottom: calc(70rem/16); /** 100px - 30px **/
    }
}

.header__nav .nav-item{
    height: 100%;

    @media (max-width: 1199px) {
        display: flex;
        align-items: center;
    }

    @media (min-width: 1200px) {
        padding: 0 calc(20rem / 16);

        &:first-child {
            padding-left: 0;
        }
    }
}

.header__nav .nav-item > a{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    color:var(--color-primary);
    font-family:var(--font-default);

    @media (max-width: 1199px) {
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        font-weight: var(--font-weight-600);
        font-size: calc(32rem / 16);
        line-height: calc(38rem / 16);
        color: var(--color-anthracite);
    }
}

@media (max-width: 1199px) {
    .header__nav .nav-item > a .icon{
        font-size: .75rem;
        margin-left:.5rem;
    }
}

.nav-item a.has-submenu.show {
    font-weight: var(--font-weight-600);
}

.header__nav .nav-item.nav-item--special > a {
    display: inline-block;
    padding:.25rem .75rem;
    border-radius: 3px;
    background-color: #F7F2EC;

    @media (max-width: 1199px) {
        margin-left: 15px;
        margin-top: 15px;
    }
}
.header__nav .nav-item.nav-item--special>a:hover,
.header__nav .nav-item.nav-item--special>a:focus {
    background-color: #ebe6e0;
}

.header__navItem {
    text-decoration: none;
}

.header__navItem-text {
    color: var(--color-anthracite);
    padding: calc(15rem / 16) 0 calc(15rem / 16) calc(20rem / 16);


    @media (max-width: 1199px) {
        display:block;
    }

    @media (min-width: 1200px) {
        display: inline-flex;
        flex-direction: column;
        word-break: initial;
        padding-left: 0;

        &:hover,
        &:focus {
            font-weight: var(--font-weight-600);
        }
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
        line-height: calc(43rem/16);
    }

    & .icon {
        display: none;
    }
}

.header__submenu-heading {
    margin-bottom: calc(20rem / 16);
}

.header__navItem-text--sub {
    padding-top: calc(10rem / 16);
    padding-bottom: calc(10rem / 16);
    font-size: calc(24rem/16);
    line-height: calc(30rem/16);

    @media (min-width: 1200px) {
        font-size: calc(16rem/16);
        line-height: calc(28rem/16);
        padding: 0 0 calc(8rem/16) 0; /* 20 - (28 - 16) */
        margin-top: 0;
    }

    @media (min-width: 2560px) {
        font-size: calc(28rem/16);
        line-height: calc(50rem/16);
        padding: 0 0 calc(2rem/16) 0;
    }
}

.has-submenu .header__navItem-text--sub {
    @media (min-width: 1200px) {
        opacity: .5;
        line-height: calc(28rem/16);
        padding: 0 0 calc(8rem/16) 0; /* 20 - (28 - 16) */
        font-weight: var(--font-weight-600);
    }

    @media (min-width: 2560px) {
        font-size: calc(28rem/16);
        line-height: calc(50rem/16);
        padding: 0 0 calc(12rem/16) 0;
    }
}

.header__searchDropdown.header__dropdown{
    margin: 0;
    padding: calc(20rem/16) 0 2vh;
}
.submit-btn-append {
    position: absolute;
    right:0;
    top:.75rem;
}

.header__dropdown{
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
    display: none;

    max-height: calc(100vh - var(--header-height-mobile));
    height: calc(100vh - var(--header-height-mobile));
    overflow-y: auto;
    overflow-x: hidden;

    @media (min-width: 768px) {
        max-height: var(--flyout-max-height-xl);
        height: var(--flyout-max-height-xl);
    }

    @media (min-width: 1921px) {
        max-height: var(--flyout-max-height-xxxl);
        height: var(--flyout-max-height-xxxl);
    }
}
.header__dropdown.show{
    display: flex;
    opacity: 1;
}

.header__searchDropdown.header__dropdown.show{
    display: block;
}

.header__backdrop{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    transition-duration: .15s;
    transition-property: opacity;
}

.header__backdrop.fade{
    opacity: 0;
}
.header__backdrop.show{
    opacity: .5;
}

.nav-item > a.header__dropdown-subheading{
    width: 100%;
    font-family: var(--font-default);
    font-weight: 400;
    color:var(--color-primary) !important;
    @media (min-width: 1200px){
        height: auto;
        align-items: flex-start;
    }
}

.header__navbar{
    height: calc(100% + 1px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (max-width: 1199px){
        position: fixed;
        top: 0;
        left: 0;
        height: calc(100vh - var(--header-height-mobile));
        width: 100vw;
        min-width: 320px;
        background-color: var(--color-warm-grey);
        z-index: 1001;
        transform: translateX(110%);
        transition-duration: .33s;
        transition-property: transform, opacity;
        margin-top: var(--header-height-mobile);
        color: var(--color-primary);
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        visibility: hidden;
        display: none;
    }

    @media (min-width: 1200px) {
        justify-content: flex-start;
    }

}

.header__navbar.show{
    @media (max-width: 1199px) {
        display: block;
        visibility: visible;
        opacity: 1;
        transform: translate(0);
        overflow-y: scroll;
    }
}



.header__navbar-mobile-meta{
    width: 100%;
    border-top: 1px solid var(--color-grey);
    justify-content: center;
    align-items: center;
    padding: 1rem;
}


.header__submenu{
    width: 100%;
    background-color: var(--color-warm-grey);

    @media (max-width: 1199px) {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        transition-duration: .33s;
        transition-property: transform;
        transform: translateX(110%);
        display: none;
        will-change: transform;
        opacity: 1;
    }
}
@media (min-width: 1200px) {
    .header__submenu .nav-item{
        padding:0;
    }
    .header__submenu .nav-item a{
        color: #555555fa;
        padding:0;
    }
    .header__submenu .nav-item a:hover{
        color: #000;
    }
}

.header__cartBtn{
    padding-right: 0;
    margin-right: 0;
}

.header__menu-hyphen{
    height: 100%;
    display: flex;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    transform: translateX(15px);
    @media (max-width: 1199px) {
        display: none;
    }
}
.header__menu-hyphen:after{
    content: '';
    width: 1px;
    height: 30%;
    background-color: #dedede;
}

.header__submenu-sub{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header__submenu-sub .nav-item a{
    margin-bottom: 0;
    word-break: break-word;
    @media (max-width: 1199px) {
        min-height: 44px;
        font-family:var(--font-default);
        font-size:.9375rem;
    }
}


.header__submenu.show{
    @media (max-width: 1199px) {
        padding-top: calc(60rem/16)!important;
        display: block !important;
        transform: translate(0);
    }
}

.header__submenu .nav-item{
    text-align: left ;
    height: auto;
}


.header__submenu-heading{
    display: flex;
    align-items: flex-start;
}
.header__submenu-heading-back{
    z-index: 1;
    display: flex;
    align-items: center;
    color: var(--color-default);
    text-decoration: none;
    margin-top: calc(2rem/16);
    height: calc(30rem/16);
    width: calc(30rem/16);
}

.header__submenu-heading-link{
    margin-left: calc(24rem/16);
    font-size: calc(16rem/16);
    line-height: calc(34rem/16);
    color: var(--color-anthracite);
    text-decoration: none;
    font-weight: var(--font-weight-600);
}
.header__submenu-heading-category{
    opacity: .5;
    padding-right: calc(15rem/16);
}

.header__image-textoverlay{
    text-align: center;

    @media (min-width: 1200px){
        text-align: left;
        position: absolute;
        bottom: calc(60rem/16);
        left: calc(80rem/16);
    }
}

.header__image-txt {
    color: var(--color-anthracite);
    font-family:var(--font-default);
    font-size: calc(24rem/16);
    font-weight: var(--font-weight-600);
    line-height: calc(30rem/16);
    margin-bottom: calc(20rem/16);

    @media (min-width: 1200px){
        color: var(--color-white);
        font-size: calc(48rem/16);
        line-height: calc(60rem/16);
        margin-bottom: calc(36rem/16);
    }

    @media (min-width: 1921px){
        font-size: calc(85rem/16);
        line-height: calc(107rem/16);
        margin-bottom: calc(60rem/16);
    }
}

.header__image-btn {
    margin-bottom: calc(15rem/16);

    @media (min-width: 1200px){
        color: var(--color-white);
        border: 1px solid var(--color-white);
        background-color: transparent;


        &:hover,
        &:focus {
            color: var(--color-red);
            background-color: var(--color-white);
        }

        &.btn--disabled {
            color: var(--color-white);
        }
    }
}

.header__image-btn:last-child {
    margin-bottom: 0;
}

.searchbar-spacing{
    margin: 0;
}

.tt-menu.tt-open{
    @media (max-width: 767px){
        width: 100%;
    }
}

.header__opennav {
    padding-left: 0;
    padding-right: 0;
    align-self: center;

    @media (min-width: 1200px) {
        display: none;
    }
}

.header__opennav .icon {
    color: var(--color-anthracite);
    font-size: calc(9rem/16);

    &.icon-x {
        font-size: calc(21rem / 16);
    }
}

.header__opennav:not(.show) .icon-x {
    display: none
}

.header__opennav.show .icon-burger {
    display: none;
}

.header-spacer {
    margin-top: var(--header-height-mobile);

    @media (min-width: 768px) {
        margin-top: var(--header-height);
    }

    @media (min-width: 1921px) {
        margin-top: var(--header-height-xxl);
    }
}


.mai__nav-activities {
    margin-bottom: calc(73rem/16);
    overflow: hidden;

    @media (min-width: 1200px) {
        margin-bottom: calc(33rem/16);
    }

    @media (min-width: 1921px) {
        margin-bottom: calc(84rem/16);
    }
}

.mai__nav-activities .splide__track {
    overflow: visible;
}

.mai__nav-activities a {
    display: flex;
    text-decoration: none;
    color: var(--color-anthracite);
    flex-direction: column;
    align-items: center;

    &:hover {
        font-weight: var(--font-weight-600);
    }
}

.mai__nav-activity {
    display: block;
    height: calc(100rem/16);
    width: calc(100rem/16);
    margin: calc(4rem/16) 0 calc(12rem/16) 0; /* to fix ellipses shadow */

    @media (min-width: 1921px) {
        height: calc(178rem/16);
        width: calc(178rem/16);
        margin-bottom: calc(15rem/16);
    }
}

.mai__nav-activity-text {
    font-size: calc(16rem / 16);
    line-height: calc(28rem / 16);

    @media (min-width: 1200px) {
        line-height: calc(38rem / 16);
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem / 16);
        line-height: calc(50rem / 16);
    }
}

.mai__nav-activity .icon {
    font-size: calc(50rem/16);

    @media (min-width: 1921px) {
        font-size: calc(110rem/16);
    }
}

.mai__nav-headline {
    color: var(--color-anthracite);
    opacity: .5;
    font-size: calc(16rem/16);
    line-height: calc(34rem/16);
    font-weight: var(--font-weight-600);

    @media (min-width: 1200px) {
        border-top: 2px solid #b8b7b6; /** #313330 opacity .3 **/
        padding-top: calc(45rem/16);
        line-height: calc(24rem/16);
    }

    @media (min-width: 1921px) {
        padding-top: calc(95rem/16);
        font-size: calc(28rem/16);
        line-height: calc(50rem/16);
    }
}

.mai__nav-slider {
    margin-bottom: calc(55rem/16);
    position: relative;

    @media (min-width: 1200px) {
        margin-bottom: 0;
    }
}

.mai__nav-slider--desktop {
    @media (min-width: 1200px) {
        height: var(--flyout-max-height-xl);
    }

    @media (min-width: 1921px) {
        height: var(--flyout-max-height-xxxl);
    }
}

.mai__nav-slider--desktop .splide__track {
    height: 100%;
}

.mai__nav-slider .splide__slide {
    width: calc(290rem/16);

    @media (min-width: 1200px) {
        width: 100%;
        height: 100%;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: calc(604rem/16);
            background: linear-gradient(360deg, #313330 0%, rgba(196, 196, 196, 0) 100%);
            mix-blend-mode: multiply;
        }
    }
}

.mai__nav-slider .splide__slide img {
    border-radius: 6px;
    max-height: calc(355rem/16);
    object-fit: cover;
    margin-bottom: calc(30rem/16);

    @media (min-width: 1200px) {
        border-radius: 0;
        max-height: 100%;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
    }
}

.header__subnav-close {
    @media (min-width: 1200px) {
        position: absolute;
        top: 0;
        right: 0;
        height: calc(78rem/16);

        display: flex;
        align-items: center;
        justify-content: center;

        z-index: 1;
    }
}

.header__subnav-close .icon {
    font-size: calc(18rem/16);
}

.navbar-dropdown__link {
    color: var(--color-text-default);
}

.navbar-dropdown__link:hover,
.navbar-dropdown__link:focus {
    color: var(--color-red);
}
