.alert-notification--fixed {
    z-index: 11;
    pointer-events: auto;
}
.alert-notification .alert .close {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: 0;
    color: currentColor;
    padding: calc(3rem / 16);
    width: calc(30rem / 16);
    height: calc(30rem / 16);
    cursor: pointer;
    line-height: 0;
}
.alert-notification .alert .close:before {
    font-family: iconfont;
    font-size: calc(16rem/16);
    content: var(--icon-open);
    transform: rotate(45deg);
    display: block;
}
.alert-notification .alert .close > span {
    display: none;
}
.alert-heading {
    font-size: calc(18rem/16);
    line-height: 1;
    letter-spacing: calc(.5rem / 16);

    @media screen and (min-width: 992px) {
        font-size: calc(32rem/16);
    }
}
.alert-notification .alert {
    color: #fff;
}
.alert-notification .alert-warning {
    color: var(--color-black);
}
.alert-danger {
    background: #FFA988;
    border-color: #FFA988;
}
.alert-success {
    background: var(--color-success);
    border-color: var(--color-success);
}
.alert-info {
    background: var(--color-warm-grey);
    border-color: var(--color-warm-grey);
}