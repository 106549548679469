@import "header-accordion.css";
@import "breadcrumb.css";

.mai__m40-pl-header {
    position: relative;
    color: var(--color-white);
    z-index: 0;
}

.mai__m40-pl-header__image-container {
    position: relative;

    &::before {
        content: '';

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        background: var(--color-anthracite);
        opacity: .5;

        mix-blend-mode: multiply;

        pointer-events: none;

        z-index: 2;
    }

    &::after {
        content: '';

        display: block;
        width: 100%;
        padding-bottom: 132.34783%;

        @media (min-width: 576px) {
            padding-bottom: 132.20339%;
        }

        @media (min-width: 768px) {
            padding-bottom: 132.29062%;
        }

        @media (min-width: 992px) {
            padding-bottom: 50%;
        }
    }
}

.mai__m40-pl-header__image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1;
}


.mai__m40-pl-header__content-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 2;
}

.mai__m40-pl-header__headline {
    color: inherit;
}

.mai__m40-pl-header__accordion {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mai__m40-pl-header__accordion-trigger .icon {
    font-size: calc(12rem / 16);
}

.mai__m40-pl-header__accordion-toggle:checked ~ .mai__m40-pl-header__accordion-trigger .icon {
    transform: rotate(180deg);
}
