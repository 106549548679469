.mai__module-headline {
    font-weight: var(--font-weight-regular);
    font-size: calc(16rem/16);
    line-height: calc(28rem/16);
    margin-bottom: 0 !important;

    @media (min-width: 992px) {
        font-size: calc(24rem/16);
        margin-bottom: calc(20rem/16) !important;
    }

    @media (min-width: 1921px) {
        font-size: calc(43rem/16);
        line-height: calc(50rem/16);
    }
}

.mai__module-headline .icon {
    font-size: calc(14rem/16);
    color: var(--color-red);

    @media (min-width: 992px) {
        font-size: calc(12rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(24rem/16);
    }
}
