.accordion-item {
    background: #fff;
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.1);
}
.accordion-item + .accordion-item {
    margin-top: calc(10rem / 16);
}
.accordion-button {
    border: 0;
    border-radius: calc(4rem / 16);
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    transition: color 200ms ease-in-out;
    padding: calc(24rem / 16) calc(40rem / 16) calc(20rem / 16) calc(20rem / 16);
    font-size: calc(14rem / 16);
    text-align: left;

    @media (min-width: 768px) {
        font-size: calc(16rem / 16);
        padding: calc(25rem / 16) calc(30rem / 16) calc(16rem / 16);
    }
}
.accordion-button:after {
    content: var(--icon-open);
    font-family: iconfont;
    background: none;
    font-size: calc(8rem / 16);
    color: var(--color-dark-grey);
    width: auto;
    height: auto;
    position: absolute;
    right: calc(20rem / 16);
    top: 50%;
    transform: translateY(-50%);

    @media (min-width: 768px) {
        right: calc(30rem / 16);
    }
}
.accordion-button:focus,
.accordion-button:not(.collapsed) {
    background: #fff;
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}
.accordion-button:not(.collapsed):after {
    background: none;
    transform: rotate(45deg) translateY(-50%) translateX(calc(-5rem / 16));
}
.accordion-collapse {
    border: 0;
}
.accordion-body {
    padding: calc(4rem / 16) calc(30rem / 16) calc(15rem / 16) calc(20rem / 16);

    @media (min-width: 768px){
        padding: calc(4rem / 16) calc(70rem / 16) calc(30rem / 16) calc(30rem / 16);
    }
}

/* light style */
.accordion--light .accordion-item {
    background: none;
    box-shadow: none;
    border-top: calc(1rem / 16) solid var(--color-sand-20);
    border-bottom: calc(1rem / 16) solid var(--color-sand-20);
}
.accordion--light .accordion-button {
    padding: calc(19rem / 16) calc(30rem / 16) calc(13rem / 16) calc(10rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    text-transform: uppercase;
    line-height: 1;
    color: var(--color-text-default);
    font-size: calc(16rem / 16);
}
.accordion--light .accordion-button:after {
    right: calc(10rem / 16);
}
.accordion--light .accordion-body {
    padding: calc(4rem / 16) calc(10rem / 16) calc(10rem / 16);
}
.accordion--light .accordion-button:focus,
.accordion--light .accordion-button:not(.collapsed) {
    background: none;
}

/* accordion inline rating */
.accordion-button__rating .bv_stars_button_container .bv_stars_svg_no_wrap svg {
    max-width: calc(14rem / 16) !important;
    max-height: calc(14rem / 16) !important;
}
.accordion-button__rating.accordion-button__rating .bv_main_container .bv_text, .accordion-button__rating .bv_main_container .bv_text {
    font-size: 12px !important;
    font-family: var(--font-default) !important;
    font-weight: var(--font-default-weight) !important;
    line-height: 1 !important;
}
.accordion-button__rating.accordion-button__rating .bv_main_container .bv_averageRating_component_container .bv_text{
    font-family: var(--font-default-bold) !important;
    font-weight: var(--font-default-bold-weight) !important;
}