@import "tab.css";
@import "tab-pane.css";

.mai__m26-checkout-cta-size-select__wrapper {
    position: relative;
}

.mai__m26-checkout-cta-size-select {
    @media (min-width: 992px) {
        position: absolute;
        width: calc(100rem / 16);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) translateZ(1px);
        z-index: 1;
    }

    @media (min-width: 1921px) {
        width: calc(180rem / 16);
    }
}

.mai__m26-checkout-cta-size-select--sticky {
    @media (max-width: 991px) {
        position: fixed;
        left: 0;
        bottom: calc(30rem / 16);
        z-index: 10;

    }
    @media (min-width: 576px) {
        padding-right: calc(var(--bs-gutter-x) * 2);
        padding-left: calc(var(--bs-gutter-x) * 2);
    }
}

.mai__m26-checkout-cta-size-select__btn {
    font-size: calc(16rem / 16);
    line-height: calc(24rem / 16);
    font-weight: var(--font-weight-semibold);
    color: var(--color-white);
    background-color: var(--color-red);
    text-transform: none;

    border-radius: calc(8rem / 16);

    @media (min-width: 992px) {
        font-size: calc(24rem / 16);
        line-height: calc(30rem / 16);
    }

    @media (min-width: 1921px) {
        font-size: calc(43rem / 16);
        line-height: calc(53rem / 16);
    }
}

.mai__m26-checkout-cta-size-select .mai__m26-checkout-cta-size-select__btn-icon,
.mai__m26-checkout-cta-size-select__submit .mai__m26-checkout-cta-size-select__btn-icon {
    font-size: calc(25rem / 16);
}

.mai__m26-checkout-cta-size-select .mai__m26-checkout-cta-size-select__btn-icon {
    @media (min-width: 992px) {
        font-size: calc(42rem / 16);
    }

    @media (min-width: 1921px) {
        font-size: calc(75rem / 16);
    }
}

.mai__m26-checkout-cta-size-select__col {
    border-left: 1px solid rgba(255,255,255,.3);

    @media (min-width: 992px) {
        border-left: 0;
        border-bottom: 1px solid rgba(255,255,255,.3);
    }

    &:first-child {
        border-left: 0;

        @media (min-width: 992px) {
            border-bottom: 0;
        }
    }
}

.mai__m26-checkout-cta-size-select__size-sheet {
    display: block;

    font-size: calc(16rem / 16);
    line-height: calc(24rem / 16);
    text-align: center;

    @media (min-width: 1921px) {
        font-size: calc(24rem / 16);
        line-height: calc(43rem / 16);
    }

    &,
    &:hover,
    &:focus {
        color: inherit;
    }
}

.mai__m26-checkout-cta-size-select__size-sheet-icon {
    font-size: calc(8rem / 16);
}

.mai__m26-checkout-cta-size-select__outtra-wrapper .outtra-local-button,
.mai__m26-checkout-cta-size-select__submit {
    text-transform: uppercase;
    font-weight: var(--font-weight-regular);
    font-size: calc(12rem / 16);
    line-height: calc(18rem / 16);

    border: 0;

    @media (max-width: 991px) {
        border-radius: 0;
    }

    @media (min-width: 992px) {
        font-size: calc(12rem / 16);
        line-height: calc(18rem / 16);
        padding: calc(15rem / 16) calc(20rem / 16) calc(15rem / 16) calc(25rem / 16);
    }

    @media (min-width: 1921px) {
        font-size: calc(20rem / 16);
        line-height: calc(24rem / 16);
    }
}

.mai__m26-checkout-cta-size-select__submit .mai__m26-checkout-cta-size-select__btn-icon {
    @media (min-width: 1921px) {
        font-size: calc(45rem / 16);
    }
}

.mai__m26-checkout-cta-size-select__outtra-wrapper .row {
    display: none;
    margin-bottom: 0 !important;

    &:last-child {
        display: flex;
    }
}

/* .mai__m26-checkout-cta-size-select__outtra-wrapper .outtra-local-button {
    height: auto;

    text-align: center;
    text-decoration: none;

    padding: 0;
    margin-bottom: 0;
    border-radius: 0;
    border: 0;

    box-shadow: none;
    transition: opacity .15s ease-in-out;

    @media (min-width: 992px) {
        transition: color .15s ease-in-out,
                    background-color .15s ease-in-out,
                    border-color .15s ease-in-out,
                    box-shadow .15s ease-in-out;
    }

    &:hover,
    &:focus {
        color: var(--color-red) !important;
        background-color: transparent !important;
        outline: 0;
        box-shadow: none;
    }
} */

.mai__m26-checkout-cta-size-select__outtra-vendor-wrapper .outtra-online-shops-grid {
    display: flex;
    justify-content: center;

    margin-top: calc(10rem / 16);
}

.mai__m26-checkout-cta-size-select__outtra-vendor-wrapper .outtra-online-shop-grid-item {
    height: calc(60rem / 16);
    padding: calc(10rem / 16);
    margin-right: calc(10rem / 16);

    border-radius: 0;
    border: 1px solid transparent;
}

.mai__m26-checkout-cta-size-select__outtra-vendor-wrapper .outtra-online-shop-grid-item:hover,
.mai__m26-checkout-cta-size-select__outtra-vendor-wrapper .outtra-online-shop-grid-item:focus {
    border-color: var(--color-warm-grey);
}

.mai__m26-checkout-cta-size-select__outtra-vendor-wrapper .outtra-online-shop-grid-item:last-child {
    margin-right: 0;
}

.mai__m26-checkout-cta-size-select__outtra-vendor-wrapper .outtra-online-shop-grid-item img {
    pointer-events: none;
}

.mai__m26-checkout-cta-size-select__loading-overlay {
    position: absolute;
    width: 100%;
    height: 100%;

    pointer-events: auto;
}

.mai__m26-checkout-cta-size-select__loading-overlay.fade {
    pointer-events: none;
}

.mai__m26-checkout-cta-size-select__add-to-cart-btn {
    position: relative;

    font-size: calc(16rem / 16);
    line-height: calc(24rem / 16);
    color: var(--color-white);

    background: var(--color-red);

    @media (min-width: 992px) {
        font-size: calc(16rem / 16);
        line-height: calc(24rem / 16);
    }
}

.mai__m26-checkout-cta-size-select__add-to-cart-btn:hover,
.mai__m26-checkout-cta-size-select__add-to-cart-btn:focus {
    background: var(--color-white);
    color: var(--color-red);
}

.mai__btn.mai__m26-checkout-cta-size-select__add-to-cart-btn.mai__m26-checkout-cta-size-select__add-to-cart-btn {
    -webkit-transition: opacity .15s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: opacity .15s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.mai__m26-checkout-cta-size-select__add-to-cart-btn .icon {
    margin-left: 0;
    font-size: calc(16rem / 16);
}

.mai__m26-checkout-cta-size-select__add-to-cart-btn[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

.is-finished .mai__m26-checkout-cta-size-select__add-to-cart-btn,
.is-finished .mai__m26-checkout-cta-size-select__add-to-cart-btn:hover,
.is-finished .mai__m26-checkout-cta-size-select__add-to-cart-btn:focus {
    background: var(--color-red);
    color: var(--color-red);
}

.is-finished .mai__m26-checkout-cta-size-select__add-to-cart-btn .btn-cart__finished {
    color: var(--color-white);
}
