.mai__m10-quote {
    min-height: calc(375rem/16);
    background: url('/maiersports_ng/static/img/m10-quote-background.svg') no-repeat center center;
    background-size: auto calc(375rem/16);

    @media (min-width: 992px) {
        min-height: calc(477rem/16);
        background-size: auto;
    }
}

.mai__m10-quote--background-2 {
    min-height: calc(354rem/16);
    background-image: url('/maiersports_ng/static/img/m10-quote-background-2.svg');
    background-size: auto;

    @media (min-width: 992px) {
        min-height: calc(354rem/16);
        background-size: auto;
    }
}

.mai__m10-quote__wysiwyg {
    font-size: calc(32rem/16);
    line-height: calc(38rem/16);
    font-family: var(--font-tertiary);
    font-weight: var(--font-weight-400);

    @media (min-width: 992px) {
        font-size: calc(48rem/16);
        line-height: calc(58rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(85rem/16);
        line-height: calc(103rem/16);
    }
}

.mai__m10-quote__wysiwyg > *:last-child {
    margin-bottom: 0;
}

.mai__m10-quote__sub-top-line {
    font-size: calc(14rem/16);
    line-height: calc(22rem/16);
    font-weight: var(--font-weight-600);

    @media (min-width: 992px) {
        font-size: calc(16rem/16);
        line-height: calc(24rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
        line-height: calc(43rem/16);
    }
}
