.mai__product-availability__icon::before {
    content: '';

    display: block;
    width: calc(16rem/16);
    height: calc(16rem/16);

    margin-right: calc(20rem/16);

    border-radius: 9999px;
    background: var(--color-black);
}


.mai__product-availability__icon.text-danger::before {
    background: var(--color-danger);
}

.mai__product-availability__icon.text-success::before {
    background: var(--color-success);
}
