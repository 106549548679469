.floating-label {
    max-width: calc(100% - 0.75rem);
    overflow: hidden;
    font-size: calc(16rem/16);
    position: absolute;
    left: calc(12rem / 16);
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
    color: #A5ACAF;
    white-space: nowrap;

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
    }
}

textarea.form-control {
    position: relative;
    top: 0;
    transform: translate(0);
}

textarea ~ .floating-label{
    pointer-events: none;
    position: absolute;
    transform: translate3d(0, 10px, 0) scale(1);
    transform-origin: left top;
    transition: 300ms;
    top: 0;
}

textarea.form-control.has-value ~ .floating-label,
textarea.form-control:focus ~ .floating-label {
    transform: translateY(-75%) scale(.75) translateX(calc(-15rem / 16));

    @media (min-width: 1921px) {
        transform: translateY(-65%) scale(.65) translateX(calc(-15rem / 16));
    }
}

select~.floating-label,
.form-control:focus ~ .floating-label,
.form-control.has-value ~ .floating-label {
    transform: translateY(-160%) scale(.75) translateX(calc(-15rem / 16));
    overflow: visible;

    @media (min-width: 1921px) {
        transform: translateY(-117%) scale(.65) translateX(calc(-15rem / 16));
    }
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(-160%) scale(.75) translateX(calc(-15rem / 16));

    @media (min-width: 1921px) {
        transform: translateY(-117%) scale(.65) translateX(calc(-15rem / 16));
    }
}

.select-custom, .select-custom select {
    position: relative;
}
