.mai__m8-newsletter__inner {
    min-height: calc(450rem/16);
}

.mai__m8-newsletter__imagewrapper {
    overflow: hidden;
    max-height: calc(500rem/16);

    @media (max-width: 767px) {
        max-height: calc(300rem/16);
    }
}

.mai__m8-newsletter__imagewrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.mai__m8-newsletter__contentwrapper {
    background-color: var(--color-light-mint);
    position: relative;
}

.mai__m8-newsletter__image-moveit-horizontal,
.mai__m8-newsletter__image-moveit-vertical {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    border-radius: .5rem;
}

.mai__m8-newsletter__image-moveit-horizontal {
    width: calc(267rem/16);
    height: calc(50rem/16);
    top: 0;
    left: 50%;
    background: url('/maiersports_ng/static/img/m8-newsletter-banner-logo-horizontal.svg') left 1rem center no-repeat var(--color-red);
}

.mai__m8-newsletter__image-moveit-vertical {
    width: calc(44rem/16);
    height: calc(295rem/16);
    top: 50%;
    left: 0;
    background: url('/maiersports_ng/static/img/m8-newsletter-banner-logo-vertical.svg') bottom 1rem center no-repeat var(--color-red);
}

.mai__m8-newsletter__slogan {
    position: absolute;
    width: 10rem;
    display: block;
    color: var(--color-white-light);
    font-size: .8rem;
    font-weight: var(--font-weight-600);
    text-transform: uppercase;
}

.mai__m8-newsletter__image-moveit-horizontal .mai__m8-newsletter__slogan {
    transform: translateY(-50%);
    left: 25%;
    top: 50%;
}

.mai__m8-newsletter__image-moveit-vertical .mai__m8-newsletter__slogan {
    transform: rotate(-90deg) translateY(-50%);
    transform-origin: 0 0 0;
    bottom: 35%;
    left: 50%;
}

.mai__m8-newsletter__content {
    @media (min-width: 768px) {
        position: absolute;
        top: 50%;
        right: 3rem;
        left: 3rem;
        transform: translateY(-50%);
    }
}

.mai__m8-newsletter__headline {
    color: var(--color-anthracite);
    font-weight: var(--font-weight-600);
    font-style: normal;
    text-transform: unset;

    font-size: 1.5rem;
    line-height: 2.5rem;

    @media (min-width: 768px) and (max-width: 991px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 1.3rem;
        line-height: 2rem;
    }

    @media (min-width: 1200px) {
        font-size: 1.5rem;
        line-height: 2.3rem;
    }

}

.mai__m8-newsletter__form-group {
    @media (min-width: 992px) {
        max-width: calc(280rem / 16);
    }
}

.mai__m8-newsletter__emailinput {
    height: calc(50rem / 16);
    padding: 1.05rem 5rem 1.05rem 1.25rem;
    padding-right: calc(80rem / 16);
    border-radius: .25rem;
    font-weight: var(--font-weight-600);

    @media (min-width: 992px) {
        padding: .7rem 5rem .7rem 1.25rem;
        height: 2.5rem;
    }
}

.mai__m8-newsletter__emailinput::placeholder {
    color: var(--color-anthracite);
    opacity: .25;
}

.form-group.has-success .mai__m8-newsletter__emailinput {
    padding-right: 5rem;
    background-position-x: calc(100% - 3rem);
}

.mai__m8-newsletter__emailinput:focus {
    box-shadow: none;
    outline: 0;
}

.mai__m8-newsletter__overlayfade {
    position: absolute;
    z-index: 9000;
    top: 1px;
    left: 1px;
    width: 1.7rem;
    height: calc(3.125rem - 2px);
    border-radius: .25rem;
    background: linear-gradient(90deg, var(--color-white) 70%, transparent);
    opacity: 0;
    transition: opacity .3s;
    pointer-events: none;

    @media (min-width: 992px) {
        height: calc(2.5rem - 2px);
    }
}

.mai__m8-newsletter__form--show-overlay .mai__m8-newsletter__overlayfade {
    opacity: 1;
}

.mai__m8-newsletter__submitbtn {
    color: var(--color-petrol);
    font-weight: var(--font-weight-600);
    position: absolute;
    top: 0;
    right: 1px;
    height: calc(50rem / 16);

    @media (min-width: 992px) {
        height: 2.5rem;
    }
}

.mai__m8-newsletter__submitbtn:hover {
    color: var(--color-mintpetrol);
}

.mai__m8-newsletter__submitbtn:disabled,
.mai__m8-newsletter__submitbtn[disabled]{
    color: var(--color-light-mint);
}

.mai__m8-newsletter__success {
    height: 3.1rem;
    background-color: var(--color-petrol);
    color: var(--color-white);
    text-align: center;
    padding: .6rem;

    @media (min-width: 768px) {
        max-width: 17.3125rem;
    }

    @media (min-width: 992px) {
        height: 2.5rem;
        padding: .3rem;
    }
}

.mai__m8-newsletter__success-check {
    filter: brightness(0) invert(1);
    margin-left: 1.5rem;
}
