.mai__base-video--wrapper {

    &:not(.mai__base-video--is-playing):not(.is-playing):not(.no-hover) {
        &:hover, &:focus {
            transform: scale(1.02);
        }
    }

    position: relative;

    transition: var(--transition-all-default);
    background: var(--color-black);

    overflow: hidden;

    /* Fix iOS Safari overflow hidden bug */
    z-index: 1;

    border-radius: calc(6rem / 16);

    @media (min-width: 992px) {
        border-radius: calc(8rem / 16);
    }

    @media (min-width: 1921px) {
        border-radius: calc(14rem / 16);
    }
}

.mai__base-video__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    object-fit: cover;
}

.js-video_youtube-container-thumbnail,
.mai__base-video__video-poster-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.js-video_youtube-container-thumbnail::before,
.mai__base-video__video-poster-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-anthracite);
    opacity: 0.7;
    mix-blend-mode: multiply;
    z-index: 1;
    pointer-events: none;
}

.mai__base-video__video-poster {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.video-js__playbtn,
.video-js__playbtn:hover,
.video-js__playbtn:active,
.video-js__playbtn:focus,
.video-js__playbtn:visited,
.mai__base-video__play-btn,
.mai__base-video__pause-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;

    font-size: calc(27rem / 16);
    cursor: pointer;
    color: var(--color-mintpetrol);

    z-index: 1;

    @media (min-width: 992px) {
        font-size: calc(42rem / 16);
    }
}

.mai__base-video__pause-btn {
    display: none!important;
}

.mai__base-video--is-playing .mai__base-video__play-btn {
    display: none!important;
}

.mai__base-video--is-playing .mai__base-video__pause-btn {
    display: flex!important;
}

.js-video_youtube-container-buttons .play-text,
.mai__base-video__play-text {
    margin-left: calc(20rem / 16);
    font-size: calc(24rem / 16);
    line-height: calc(30rem / 16);
    font-weight: var(--font-weight-semibold);

    @media (min-width: 992px) {
        font-size: calc(32rem / 16);
        line-height: calc(40rem / 16);
    }
}

