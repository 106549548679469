.checkout-steps {
    font-family: var(--font-default);
    font-size: .875rem;
    margin:calc(40rem / 16) auto;
    width:42rem;

    @media screen and (max-width: 768px) {
        white-space:nowrap;
        margin:1.5rem auto;
        width:20rem;
    }
}
.checkout-steps .checkout-steps__item{
    position: relative;
    display: inline-block;
    width: 32%;
    margin:0;
    color:#B5ADA6;
}
.checkout-steps .checkout-steps__item>a,
.checkout-steps .checkout-steps__item>span {
    display: inline-block;
    padding-top:1.25rem;
    position: relative;
    color:inherit;

    @media screen and (max-width: 767px) {
        text-indent:-19999px;
    }
}
.checkout-steps .checkout-steps__item:before {
    content:'';
    width:1rem;
    height:1rem;
    line-height:1.25rem;
    border-radius: 50%;
    position: absolute;
    top:0;
    left:50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: .625rem;
    display: inline-block;
    pointer-events: none;
    z-index:1;
    background:#F0EEEC;
}
@media screen and (max-width: 767px) {
    .checkout-steps .checkout-steps__item:before {
        content:'1';
    }
    .checkout-steps .checkout-steps__item + .checkout-steps__item:before {
        content:'2';
    }
    .checkout-steps .checkout-steps__item + .checkout-steps__item + .checkout-steps__item:before {
        content:'3';
    }
}
.checkout-steps .checkout-steps__item--active,
.checkout-steps .checkout-steps__item--complete {
    color:var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}
.checkout-steps .checkout-steps__item--active:before {
    color:#fff;
    background:var(--color-primary);
}
.checkout-steps .checkout-steps__item--complete:before {
    color:var(--color-primary);
    background:var(--color-primary);
}
@media screen and (min-width: 768px) {
    .checkout-steps .checkout-steps__item--complete>span:before,
    .checkout-steps .checkout-steps__item--complete>a:before {
        content:'';
        position: absolute;
        left: 50%;
        top: 2px;
        width: .325rem;
        height: .625rem;
        margin-left: -.125rem;
        border:2px solid #fff;
        border-width:0 2px 2px 0;
        transform:rotate(45deg);
        z-index:2;
    }
}
.checkout-steps .checkout-steps__item + .checkout-steps__item:after {
    content:'';
    display: block;
    position: absolute;
    right:50%;
    top:.5rem;
    width:14rem;
    height:.125rem;
    background:#F0EEEC;

    @media screen and (max-width: 767px) {
        width:7rem;
    }
}
.checkout-steps .checkout-steps__item.checkout-steps__item--active:after,
.checkout-steps .checkout-steps__item.checkout-steps__item--complete:after {
    background:var(--color-primary);
}
