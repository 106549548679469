.card-teaser {
    padding: calc(20rem / 16);
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.1);
    position: relative;

    @media (min-width: 768px) {
        padding: calc(20rem / 16) calc(20rem / 16) calc(35rem / 16);
    }
}
.card-teaser__title {
    padding-right: calc(20rem / 16);
    margin-top: calc(26rem / 16);
}
.card-teaser__toggle {
    background: none;
    border: 0;
    position: absolute;
    right: 0;
    top: 0;
    font-size: calc(9rem / 16);
    padding: calc(3rem / 16) 0;
    transition: transform 200ms ease-in-out;
}
.is-active .card-teaser__toggle {
    transform: rotate(45deg);
}

.card-teaser.is-active:after {
    content: '';
    position: absolute;
    bottom: calc(-20rem / 16);
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 0 calc(12rem / 16) calc(12rem / 16) calc(12rem / 16);
    border-color: transparent transparent var(--color-sand-20) transparent;

    @media (min-width: 768px) {
        bottom: calc(-30rem / 16);
        border-width: 0 calc(20rem / 16) calc(20rem / 16) calc(20rem / 16);
    }
}
.card-teaser-collapse {
    margin-top: calc(20rem / 16);
    background: var(--color-sand-20);
    padding: calc(20rem / 16) calc(15rem / 16);

    @media (min-width: 768px) {
        margin-top: calc(30rem / 16);
        padding: calc(60rem / 16) calc(70rem / 16);
    }
}
.card-teaser-collapse__social{
    font-size: calc(24rem / 16);
}

.collapse-row ~ .collapse-row {
    @media (min-width: 768px) {
        margin-top: calc(80rem / 16);
    }
}