.langmodal-switch,
.lang-switch.lang-switch{
    position: relative;
    padding: 0 0 0 1.5rem;
    text-align: left;
    text-transform: uppercase;
}
.lang-switch {
    margin-bottom:.5rem;
}
.langmodal-switch__img,
.lang-switch__img {
    position: absolute;
    top:-.125rem;
    left:0;
    display: inline-block;
    width: 1rem;
    height:1rem;
}
.lang-switch:hover .lang-switch__txt,
.lang-switch:focus .lang-switch__txt,
.langmodal-switch:hover .langmodal-switch__txt,
.langmodal-switch:focus .langmodal-switch__txt {
    text-decoration: underline;
}




/* modal */
.modal--lang .modal-header {
    border-bottom: 0;
}

.modal--lang .modal-body {
    @media (min-width: 992px) {
        max-width: 880px;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 992px) {
        max-width: 800px;
    }
}

.lang-switch__languages{
    margin-left:1.5rem;
    font-size: calc(14rem / 16);
}
.lang-switch__languages a:hover,
.lang-switch__languages a:focus{
    text-decoration: underline;
}

.lang-modal__btn {
    font-weight: var(--font-weight-600);
}

.lang-modal__btn img {
    margin-top: 2px;
}

.lang-modal__languages {
    font-size: calc(16rem / 16);
    transition: none;
}

.lang-modal__link {
    &,
    &:hover,
    &:focus {
        color: var(--color-anthracite);
    }

    &:hover,
    &:focus {
        text-decoration: none
    }
}

.lang-modal__link.is-active {
    &,
    &:hover,
    &:focus {
        color: var(--color-anthracite);
        text-decoration: none;
    }
}
