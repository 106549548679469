.mai__product-card__head,
.mai__product-card__content {
    text-decoration: none;
    color: var(--color-black);

    &:hover {
        color: var(--color-black);
    }

    /* fix for wrong layout defined line-height */
    @media (max-width: 991px) {
        & *:not(.mai__product-card__rating) {
            line-height: calc(19rem/16);
        }
    }
}

.mai__product-card__image-container {
    position: relative;
    z-index: 1;
}

.mai__product-card__image-container::before {
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: calc(6rem / 16);
    box-shadow: 0 calc(1.85rem / 16) calc(3.15rem / 16) 0 rgba(0,0,0,.02),
                0 calc(8.15rem / 16) calc(6.52rem / 16) 0 rgba(0,0,0,.03),
                0 calc(20rem / 16) calc(13rem / 16) 0 rgba(0,0,0,.04),
                0 calc(38.52rem / 16) calc(25.48rem / 16) 0 rgba(0,0,0,.04);

    z-index: 1;
    pointer-events: none;

    opacity: 0;

    transition: opacity .15s ease-in-out;

    @media (min-width: 992px) {
        content: '';
    }
}

.mai__product-card:hover .mai__product-card__image-container,
.mai__product-card:focus-within .mai__product-card__image-container,
.mai__product-card__head:hover .mai__product-card__image-container,
.mai__product-card__head:focus .mai__product-card__image-container {
    &::before {
        opacity: 1;
    }
}

.mai__product-card__badge-container {
    position: absolute;
    top: 0;
    right: 0;
    right: auto;
    left: 0;

    pointer-events: none;

    z-index: 3;
}

.mai__product-card__badge-item {
    color: var(--color-anthracite);
    font-size: calc(12rem / 16);
    line-height: 1;

    background-color: var(--color-warm-grey);
    border-radius: 4px;
    opacity: .5;

    @media (min-width: 1921px) {
        font-size: 21px;
    }
}

.mai__product-card__image-wrapper {
    position: relative;
    z-index: 2;
}

.mai__product-card__image-wrapper::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: calc(304% / 203 * 100);

    @media (min-width: 576px) {
        padding-bottom: calc(448%/299 * 100);
    }

    @media (min-width: 768px) {
        padding-bottom: calc(616%/411 * 100);
    }

    @media (min-width: 992px) {
        padding-bottom: calc(3%/2 * 100);
    }

    @media (min-width: 1200px) {
        padding-bottom: calc(275%/183 * 100);
    }

    @media (min-width: 1200px) {
        padding-bottom: calc(809%/540 * 100);
    }

    @media (min-width: 1921px) {
        padding-bottom: calc(376%/251 * 100);
    }
}

.mai__product-card__image {
    position: absolute;
    top: calc(35rem/16);
    left: calc(30rem/16);

    width: calc(100% - (60rem / 16));
    
    z-index: 1;
}

.mai__product-card__image-background {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: #f4f4f6;
    border-radius: 6px;

    pointer-events: none;
    z-index: 1;
}

.mai__product-card__body {
    position: relative;
    z-index: 1;
}

.mai__product-card__body-inner {
    transition: opacity 200ms ease-in-out,
                visibility 200ms ease-in-out;
}

.mai__product-card--show-colour-variants .mai__product-card__head:hover + .mai__product-card__body .mai__product-card__body-inner,
.mai__product-card--show-colour-variants .mai__product-card__head:focus + .mai__product-card__body .mai__product-card__body-inner,
.mai__product-card--show-colour-variants:hover .mai__product-card__body-inner,
.mai__product-card--show-colour-variants:focus-within .mai__product-card__body-inner {
    @media (min-width: 992px) {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
}

.mai__product-card__headline {
    max-width: calc(160rem / 16);
    width: 100%;

    font-size: inherit;
    line-height: inherit;
    font-style: inherit;
    font-weight: inherit;
    color: inherit;

    @media (min-width: 768px) {
        max-width: none;
        width: auto;
    }
}

.mai__product-card__color-variants {
    color: rgba(0,0,0,.5);
}

.mai__product-card__rating {
    color: var(--color-petrol);
}

.mai__product-card__action .icon {
    font-size: calc(24rem / 16);
    color: var(--color-red);

    @media (min-width: 1921px) {
        font-size: calc(32rem / 16);
    }
}

.mai__product-card__action.wishlist__btn:hover .icon::before,
.mai__product-card__action.wishlist__btn.is-active .icon::before {
    content: var(--icon-heart-filled);
}

.mai__product-card__colour-variants {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;

    opacity: 0;
    visibility: hidden;

    pointer-events: none;

    transition: opacity 200ms ease-in-out,
                visibility 200ms ease-in-out;
}

.mai__product-card--show-colour-variants .mai__product-card__head:hover + .mai__product-card__body .mai__product-card__colour-variants,
.mai__product-card--show-colour-variants .mai__product-card__head:focus + .mai__product-card__body .mai__product-card__colour-variants,
.mai__product-card--show-colour-variants:hover .mai__product-card__colour-variants,
.mai__product-card--show-colour-variants:focus-within .mai__product-card__colour-variants {
    @media (min-width: 992px) {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }
}

.mai__product-card--show-colour-variants-col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;

    width: 20%;
}

.mai__product-card__colour-variant {
    display: block;

    position: relative;
    height: 0;
    padding-bottom: 100%;

    color: var(--color-anthracite);
    text-decoration: none;

    border-radius: calc(4rem / 16);
    cursor: pointer;

    background-color: var(--color-white);

    transition: border-color 200ms ease-in-out;
}

.mai__product-card__colour-variant::before,
.mai__product-card__colour-variant::after {
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: inherit;
    box-shadow: 0 calc(1.85rem / 16) calc(3.15rem / 16) 0 rgba(0,0,0,.02),
                0 calc(8.15rem / 16) calc(6.52rem / 16) 0 rgba(0,0,0,.03),
                0 calc(20rem / 16) calc(13rem / 16) 0 rgba(0,0,0,.04),
                0 calc(38.52rem / 16) calc(25.48rem / 16) 0 rgba(0,0,0,.04);

    z-index: 1;
    pointer-events: none;

    opacity: 0;

    transition: opacity .15s ease-in-out;

    @media (min-width: 992px) {
        content: '';
    }
}

.mai__product-card__colour-variant::before {
    box-shadow: 0 calc(1.85rem / 16) calc(3.15rem / 16) 0 rgba(0,0,0,.02),
                0 calc(8.15rem / 16) calc(6.52rem / 16) 0 rgba(0,0,0,.03),
                0 calc(20rem / 16) calc(13rem / 16) 0 rgba(0,0,0,.04),
                0 calc(38.52rem / 16) calc(25.48rem / 16) 0 rgba(0,0,0,.04);
}

.mai__product-card__colour-variant::after {
    border: 1px solid currentColor;
}

.mai__product-card__nav-item.is-active .mai__product-card__colour-variant,
.mai__product-card__colour-variant:hover,
.mai__product-card__colour-variant:focus {
    color: var(--color-anthracite);

    &::before,
    &::after {
        opacity: 1;
    }
}

.mai__product-card__colour-variant-image-wrapper {
    background-color: var(--color-image-background);
}

.mai__product-card__colour-variant-image-wrapper,
.mai__product-card__colour-variant-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: inherit;

    z-index: 1
}

.mai__product-card__colour-variant-image {
    display: block;
    max-height: 100%;
}

.mai__product-card__footer-actions {
    padding: calc(5rem / 16) 0 calc(5rem / 16) 0;

    @media (min-width: 992px) {
        padding: calc(10rem / 16) 0 calc(10rem / 16) 0;
    }

    @media (min-width: 1921px) {
        padding: calc(20rem / 16) 0 calc(20rem / 16) 0;
    }
}

.mai__product-card__head {
    position: relative;
}

.mai__product-card__head .mai__product-card__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.mai__product-card__availability-text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 3;
}