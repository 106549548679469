.mai__m6-hero-stage__item--video .mai__m6-hero-stage__media-container {
    height: 0;
    
    padding-bottom: 103.582089552238806%;
    border-radius: 0;

    @media (min-width: 992px) {
        padding-bottom: 125.06265664160401%;
    }
}

.mai__m6-hero-stage__item--video .js-video_youtube,
.mai__m6-hero-stage__item--video .js-video_youtube-container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
}

.mai__m6-hero-stage__item--video .js-video_youtube-container {
    background-color: var(--color-black);
}

.mai__m6-hero-stage__item--video video,
.mai__m6-hero-stage__item--video iframe {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 1;
    transform: translateZ(1px);
}
