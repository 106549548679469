.mai__m4-shop-the-look__image-dot {
    width: calc(1rem / 16);
    height: calc(1rem / 16);
}

.mai__m4-shop-the-look__image-dot-outer {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: calc(24rem / 16);
    height: calc(24rem / 16);

    border-radius: 9999px;
    transform: translate(-50%, -50%);

    background: var(--color-white);
    box-shadow: 0 0 8px 0 rgba(0,0,0,.33);
}

.mai__m4-shop-the-look__image-dot-outer::before,
.mai__m4-shop-the-look__image-dot-outer::after {
    content: '';

    display: block;
    width: calc(40rem / 16);
    height: calc(40rem / 16);

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    opacity: 0;
    pointer-events: none;
    background: rgba(255,255,255,.33);
    border-radius: inherit;
    z-index: 2;

    transition: opacity .15s ease-in-out;
}

.mai__m4-shop-the-look__image-dot-outer::after {
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    z-index: 1;
}

.mai__m4-shop-the-look__image-dot-inner {
    position: relative;
    display: block;

    width: calc(12rem / 16);
    height: calc(12rem / 16);

    opacity: 0;
    pointer-events: none;
    background: var(--color-red);
    border-radius: 9999px;
    z-index: 3;

    transition: opacity .15s ease-in-out;
}

.mai__m4-shop-the-look__image-dot-toggle:checked + .mai__m4-shop-the-look__image-dot .mai__m4-shop-the-look__image-dot-inner,
.mai__m4-shop-the-look__image-dot-toggle:checked + .mai__m4-shop-the-look__image-dot .mai__m4-shop-the-look__image-dot-outer::before,
.mai__m4-shop-the-look__image-dot-toggle:checked + .mai__m4-shop-the-look__image-dot .mai__m4-shop-the-look__image-dot-outer::after {
    opacity: 1;
}
