@import "filter-modal.css";
@import "filter-accordion.css";
@import "loading.css";

.mai__product-grid__filter-btn {
    font-size: calc(12rem / 16);
    line-height: calc(28rem / 16);
}

.mai__product-grid__filter-btn,
.product-grid-filter-visible .mai__product-grid__filter-btn,
.product-grid-filter-column-hidden .mai__product-grid__filter-btn,
.mai__product-grid__filter-btn:hover,
.mai__product-grid__filter-btn:focus {
    color: var(--color-anthracite);
    border-color: var(--color-anhtracite);
    background-color: var(--color-white);
}

.product-grid-filter-visible .mai__product-grid__filter-btn {
    width: 100%;
}

.mai__product-grid__filter-btn--product-list {
    color: var(--color-white);
    border-color: currentColor;
    background-color: transparent;
}

.product-grid-filter-visible .mai__product-grid__filter-btn--product-list,
.product-grid-filter-column-hidden .mai__product-grid__filter-btn--product-list,
.mai__product-grid__filter-btn--product-list:hover,
.mai__product-grid__filter-btn--product-list:focus {
    color: var(--color-anthracite);
    background-color: var(--color-white);
    border-color: var(--color-white);
}

.mai__product-grid__filter-icon.mai__product-grid__filter-icon--open {
    font-size: calc(18rem / 16);

    @media (min-width: 1921px) {
        font-size: calc(24rem / 16);
    }
}

.mai__product-grid__filter-icon.mai__product-grid__filter-icon--close {
    font-size: calc(12rem / 16);

    @media (min-width: 1921px) {
        font-size: calc(20rem / 16);
    }
}

.mai__product-grid__filter--close,
.product-grid-filter-visible .mai__product-grid__filter--open,
.product-grid-filter-column-hidden .mai__product-grid__filter--open {
    display: none;

    @media (min-width: 992px) {
        display: flex;
    }
}

.mai__product-grid__filter--open,
.product-grid-filter-visible .mai__product-grid__filter--close,
.product-grid-filter-column-hidden .mai__product-grid__filter--close {
    display: flex;

    @media (min-width: 992px) {
        display: none;
    }
}

.mai__product-grid__overlay {
    pointer-events: none;

    &.show {
        pointer-events: auto;
        opacity: .5;
    }
}

.mai__product-grid__form {
    pointer-events: none;

    @media (min-width: 992px) {
        pointer-events: auto;
    }
}

.mai__product-grid__form.mai__product-grid__form {
    transform: none;
    background: transparent;
}

.mai__product-grid__form.is-open {
    z-index: 1090;
    transform: translateZ(1090px);
    transition: none;

    @media (min-width: 992px) {
        z-index: auto;
        transform: none;
    }
}

.mai__product-grid__form.overlay.is-open {
    @media (max-width: 991px) {
        transform: translateZ(1090px);
    }
}

.mai__product-grid .custom-checkbox:not(.custom-checkbox--color) {
    margin-bottom: calc(20rem / 16);

    &:first-child {
        margin-top: calc(10rem / 16);
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.mai__product-grid .custom-checkbox:not(.custom-checkbox--color) label {
    display: flex;
    align-items: center;
}

.mai__product-grid .custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__box {
    width: calc(20rem / 16);
    height: calc(20rem / 16);

    border-radius: calc(4rem / 16);
    background: var(--color-light-mint);

    border: 0;
    margin: 0;
    margin-right: calc(20rem / 16);

    transition: opacity .15s ease-in-out;

    @media (min-width: 1921px) {
        width: calc(30rem / 16);
        height: calc(30rem / 16);
    }
}

.mai__product-grid .custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__text {
    font-weight: var(--font-weight-400);
    line-height: calc(20rem / 16);
    color: var(--color-anthracite-50);

    transition: color .15s ease-in-out;

    @media (min-width: 1921px) {
        line-height: calc(32rem / 16);
    }
}

.mai__product-grid .custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__text span {
    font-size: inherit !important;
    line-height: inherit !important;
    font-weight: inherit !important;
    color: inherit !important;
}

.mai__product-grid .custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:checked ~ .custom-checkbox__box {
    background: var(--color-petrol);
}

.mai__product-grid .custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:checked ~ .custom-checkbox__box::after,
.mai__product-grid .custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:checked ~ .custom-checkbox__box::before {
    display: none;
}

.mai__product-grid .custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:checked ~ .custom-checkbox__text,
.mai__product-grid .custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:hover ~ .custom-checkbox__text,
.mai__product-grid .custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:focus ~ .custom-checkbox__text {
    color: var(--color-anthracite);
}

.mai__product-grid .custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:checked ~ .custom-checkbox__text {
    font-weight: var(--font-weight-600);
}

.mai__product-grid__remove-input {
    border: 1px solid rgba(182, 177, 173, 1);
    border-radius: calc(8rem / 16);

    background: var(--color-warm-grey);
    color: #4A4A49;
}

.mai__product-grid__remove-input .icon {
    color: inherit;
    transform: none;
}

.product-grid-filter-column-hidden .mai__product-grid__filter-col {
    @media (min-width: 992px) {
        display: none;
    }
}

.product-grid-filter-column-hidden .mai__product-grid__grid-col {
    @media (min-width: 992px) {
        width: 100%;
    }
}

.mai__product-grid__total-count {
    opacity: .5;
}
