.mai__m23-form {
    margin-bottom: calc(80rem/16);

    @media (min-width: 992px) {
        margin-bottom: calc(140rem/16);
    }
}

.mai__m23-form__subheading,
.mai__m23-form__heading {
    text-transform: none;
}

.mai__m23-form__subheading {
    color: var(--color-red);
}

.mai__m23-form__heading {
    margin-bottom: calc(40rem/16);
}
