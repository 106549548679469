.mai__m2-category-slider {
    margin-bottom: calc(80rem/16);

    @media (min-width: 992px) {
        margin-bottom: calc(110rem/16);
    }
}

.mai__m2-category-slider__content.splide {
    margin-left: calc(20rem/16 * -1);
    margin-right: calc(20rem/16 * -1);

    @media (min-width: 992px) {
        margin-left: calc(40rem/16 * -1);
        margin-right: calc(40rem/16 * -1);
    }
}

.mai__m2-category-slider__heading .mai__module-headline {
    font-size: calc(16rem/16);
    line-height: calc(28rem/16);
    margin-bottom: 0 !important;

    @media (min-width: 992px) {
        font-size: calc(24rem/16);
        margin-bottom: calc(20rem/16) !important;
    }

    @media (min-width: 1921px) {
        font-size: calc(43rem/16);
        line-height: calc(50rem/16);
        margin-bottom: calc(20rem/16) !important;
    }
}

.mai__m2-category-slider__heading .icon {
    @media (min-width: 992px) {
        font-size: calc(14rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(24rem/16);
    }
}

.mai__m2-category-slider__items:not(.splide__list) {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 calc(-10rem/16);
}

.mai__m2-category-slider__item {
    padding: calc(20rem/16) 0 0;
    flex: 1;

    &:not(.splide__slide) {
        padding: 0 calc(10rem/16);
    }

    &.splide__slide {
        flex: 0 0 calc(249rem/16);

        @media (min-width: 992px) {
            flex: 0 0 23.3%;
        }

        @media (min-width: 1200px) {
            flex: 0 0 23.9%;
        }
    }
}

.mai__m2-category-slider__link {
    text-decoration: none;
}

.mai__m2-category-slider__item:hover .mai__m2-category-slider__img,
.mai__m2-category-slider__item:hover .mai__m2-category-slider__media img,
.mai__m2-category-slider__item:focus .mai__m2-category-slider__img,
.mai__m2-category-slider__item:focus .mai__m2-category-slider__media img {
    @media (min-width: 992px) {
        transform: scale(1.05);
        box-shadow: 0 calc(1.85rem / 16) calc(3.15rem / 16) 0 rgba(0,0,0,.02),
        0 calc(8.15rem / 16) calc(6.52rem / 16) 0 rgba(0,0,0,.03),
        0 calc(20rem / 16) calc(13rem / 16) 0 rgba(0,0,0,.04),
        0 calc(38.52rem / 16) calc(25.48rem / 16) 0 rgba(0,0,0,.04);
    }
}


.mai__m2-category-slider__media {
    margin-bottom: calc(10rem/16);

    @media (min-width: 992px) {
        position: relative;
        margin-bottom: calc(20rem/16);
    }
}

.mai__m2-category-slider__media--3 {
    @media (min-width: 992px) {
        padding-bottom: 96.6%;
    }
}

.mai__m2-category-slider__media--4,
.mai__m2-category-slider__media--5 {
    @media (min-width: 992px) {
        padding-bottom: 129.24%;
    }
}

.mai__m2-category-slider__img {
    display: block;
    border-radius: 8px;
    width: 100%;
    height: calc(320rem/16);
    transition: all 0.3s ease;
    object-fit: cover;

    @media (min-width: 992px) {
        position: absolute;
        height: 100%;
    }
}

.mai__m2-category-slider__text {
    color: var(--color-anthracite);
    font-size: calc(16rem/16);
    font-weight: var(--font-weight-600);
    line-height: calc(28rem/16);

    @media (min-width: 992px) {
        font-size: calc(24rem/16);
        padding-bottom: calc(30rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(43rem/16);
        line-height: calc(53rem/16);
    }
}

.mai__m2-category-slider__arrows {
    display: none !important;

    @media (min-width: 992px) {
        right: calc(19rem/16);
        top: calc(50% - 1.875rem);
    }
}

.mai__m2-category-slider__content.is-initialized .mai__m2-category-slider__arrows {
    @media (min-width: 992px) {
        display: flex !important;
    }
}