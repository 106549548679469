@import "custom-checkbox.css";
@import "custom-radio.css";
@import "custom-file.css";
@import "form-errors.css";
@import "select.css";
@import "textarea.css";
@import "floating-label.css";

/* base stylings */
.form-control {
    font-size: 1rem;
    height: calc(48rem / 16);
    padding: calc(6rem / 16) calc(12rem / 16) calc(6rem / 16);
    background: #fff;
    border-radius: 0;
    border-color: var(--color-dark-grey);
    box-shadow: inset 0 1px 1px rgb(0, 0, 0, 0.08);

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
        height: calc(60rem/16);
    }

    &.form-control--border-btm {
         border-width: 0 0 1px 0;
         box-shadow: none;
     }

    &:focus {
         box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 0 3px rgba(4, 3, 8, 0.25);
     }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::placeholder {
         color: var(--color-sand);
         opacity: 1;
     }
}

.form-control.is-valid, .was-validated .form-control:valid,
.form-control.is-invalid, .was-validated .form-control:invalid {
    background: transparent;
}

.form-label {
    margin-bottom: 0;
    color:var(--color-text-default);
    font-size: .75rem;
}

.form-control--bordered {
    border: calc(1rem / 16) solid var(--color-sand-20);
}
.form-control--grey {
    background: var(--color-sand-20);
    box-shadow: none;
}
.form-group {
    margin-bottom: calc(40rem / 16);

    @media (min-width: 992px) {
        margin-bottom: calc(30rem / 16);
    }

    @media (min-width: 1921px) {
        margin-bottom: calc(40rem / 16);
    }
}

/* sizes */
.form-control.form-control-sm {
    font-size: calc(12rem / 16);
    padding: calc(7rem / 16) calc(10rem / 16) calc(5rem / 16);
}

/* addons */
.form-control-icon {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 0 calc(10rem / 16);
    text-decoration: none;
    font-size: calc(10rem / 16);
    background: none;
    border: 0;
    color: currentColor;
    display: flex;
}
.form-control-icon + .form-control {
    padding-left: calc(25rem / 16);
}

.form-control-icon--right {
    left: auto;
    right: 0;
    padding: 0 calc(20rem / 16);
}
.form-control-icon--right + .form-control {
    padding-left: calc(20rem / 16);
    padding-right: calc(40rem / 16);
}

.form-control-icon--lg {
    font-size: calc(16rem / 16);
}

.form-control.form-control--with-payment-icon {
    padding-left:3.5rem;
}
.form-control__payment-icon {
    position: absolute;
    left: 0;
    top: .75rem;
    padding: 0 calc(20rem / 16);
    font-size: calc(20rem / 16);
    background: none;
    border: 0;
    color: currentColor;
    pointer-events: none;

    @media (min-width: 992px) {
        top: .875rem;
    }
}
.form-control__payment-icon>img {
    position: relative;
    top: -.25rem;

    @media (min-width: 992px) {
        top: -.375rem;
    }
}


/*!* Validation *!*/
/*.form-group.has-success .form-control {*/
/*    border-color: var(--color-success);*/
/*    padding-right: calc(1.5em + .75rem);*/
/*    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");*/
/*    background-repeat: no-repeat;*/
/*    background-position: right calc(.375em + .1875rem) center;*/
/*    background-size: calc(.75em + .375rem) calc(.75em + .375rem);*/
/*}*/
