.select {
    position: relative;
}
.select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}

.select__input-wrapper {
    position: relative;
}
.select__input-wrapper:before,
.form-group select.form-control::before {
    font-family: "iconfont";
    content: var(--icon-dropdown);
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(3rem / 16);
    top: 50%;
    right: calc(16rem / 16);
    transform: translateY(-50%);
    z-index: 1;
    color: var(--color-anthracite);

    @media (min-width: 768px) {
        right: calc(20rem / 16);
    }
}

.form-group select.form-control {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00023 7.71309L13.0102 1.70309L11.5972 0.288086L7.00023 4.88809L2.40423 0.288086L0.990234 1.70209L7.00023 7.71309Z' fill='%23313330'/%3E%3C/svg%3E");
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 97% center;
    background-color: transparent;
}