.mai__m7-editorial__headline--big {
    @media screen and (min-width: 1200px) {
        font-size: calc(48rem / 16);
        line-height: calc(60rem / 16);
    }

    @media screen and (min-width: 1921px) {
        font-size: calc(85rem / 16);
        line-height: calc(107rem / 16);
    }
}

.mai__m7-editorial__item--1 .mai__m7-editorial__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mai__m7-editorial__item--1 .mai__m7-editorial__media {
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.mai__m7-editorial__item--3 .mai__m7-editorial__placeholder {
    display: none;

    @media (min-width: 992px) {
        display: block;
        width: 55%;
    }

    @media (min-width: 1200px) {
        width: 41%;
    }

    @media (min-width: 1200px) {
        width: 41.4%;
    }

    @media (min-width: 1921px) {
        width: 41.8%;
    }
}

/** gallery changes **/
.mai__m7-editorial .mai__m14-gallery {
    width: 100%;
}

.mai__m7-editorial .mai__m14-gallery__items {
    padding-bottom: 0 !important;
}

.mai__m7-editorial .mai__m14-gallery__image {
    object-fit: cover;
}