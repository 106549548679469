.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl,
.container-xxxl {
    --bs-gutter-x: 20px;
}

@media screen and (max-width: 991px) {
    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl,
    .container-xxxl {
        --bs-gutter-x: 40px;
    }
}

@media screen and (min-width: 992px) {
    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl,
    .container-xxxl {
        --bs-gutter-x: 80px;
    }
}
