/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 3, 2021 */

/* Source Sans Pro */
@font-face {
    font-family: 'source-sans-pro';
    src: url('/maiersports_ng/static/fonts/SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/SourceSansPro-SemiBoldItalic.woff') format('woff'),
    url('/maiersports_ng/static/fonts/SourceSansPro-SemiBoldItalic.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'source-sans-pro';
    src: url('/maiersports_ng/static/fonts/SourceSansPro-Regular.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/SourceSansPro-Regular.woff') format('woff'),
    url('/maiersports_ng/static/fonts/SourceSansPro-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'source-sans-pro';
    src: url('/maiersports_ng/static/fonts/SourceSansPro-LightItalic.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/SourceSansPro-LightItalic.woff') format('woff'),
    url('/maiersports_ng/static/fonts/SourceSansPro-LightItalic.ttf') format('ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'source-sans-pro';
    src: url('/maiersports_ng/static/fonts/SourceSansPro-Light.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/SourceSansPro-Light.woff') format('woff'),
    url('/maiersports_ng/static/fonts/SourceSansPro-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'source-sans-pro';
    src: url('/maiersports_ng/static/fonts/SourceSansPro-Italic.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/SourceSansPro-Italic.woff') format('woff'),
    url('/maiersports_ng/static/fonts/SourceSansPro-Italic.ttf') format('ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'source-sans-pro';
    src: url('/maiersports_ng/static/fonts/SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/SourceSansPro-ExtraLightItalic.woff') format('woff'),
    url('/maiersports_ng/static/fonts/SourceSansPro-ExtraLightItalic.ttf') format('ttf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'source-sans-pro';
    src: url('/maiersports_ng/static/fonts/SourceSansPro-ExtraLight.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/SourceSansPro-ExtraLight.woff') format('woff'),
    url('/maiersports_ng/static/fonts/SourceSansPro-ExtraLight.ttf') format('ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'source-sans-pro';
    src: url('/maiersports_ng/static/fonts/SourceSansPro-BoldItalic.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/SourceSansPro-BoldItalic.woff') format('woff'),
    url('/maiersports_ng/static/fonts/SourceSansPro-BoldItalic.ttf') format('ttf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'source-sans-pro';
    src: url('/maiersports_ng/static/fonts/SourceSansPro-Bold.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/SourceSansPro-Bold.woff') format('woff'),
    url('/maiersports_ng/static/fonts/SourceSansPro-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'source-sans-pro';
    src: url('/maiersports_ng/static/fonts/SourceSansPro-BlackItalic.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/SourceSansPro-BlackItalic.woff') format('woff'),
    url('/maiersports_ng/static/fonts/SourceSansPro-BlackItalic.ttf') format('ttf');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'source-sans-pro';
    src: url('/maiersports_ng/static/fonts/SourceSansPro-Black.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/SourceSansPro-Black.woff') format('woff'),
    url('/maiersports_ng/static/fonts/SourceSansPro-Black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'source-sans-pro';
    src: url('/maiersports_ng/static/fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/SourceSansPro-SemiBold.woff') format('woff'),
    url('/maiersports_ng/static/fonts/SourceSansPro-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
}

/* Nanumm Yeongjo */
@font-face {
    font-family: 'nanumm-yeongjo';
    src: url('/maiersports_ng/static/fonts/NanumMyeongjo-Regular.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/NanumMyeongjo-Regular.woff') format('woff'),
    url('/maiersports_ng/static/fonts/NanumMyeongjo-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'nanumm-yeongjo';
    src: url('/maiersports_ng/static/fonts/NanumMyeongjo-Bold.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/NanumMyeongjo-Bold.woff') format('woff'),
    url('/maiersports_ng/static/fonts/NanumMyeongjo-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'nanumm-yeongjo';
    src: url('/maiersports_ng/static/fonts/NanumMyeongjo-ExtraBold.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/NanumMyeongjo-ExtraBold.woff') format('woff'),
    url('/maiersports_ng/static/fonts/NanumMyeongjo-ExtraBold.ttf') format('ttf');
    font-weight: 800;
    font-style: normal;
}


/* EB Garamond */
@font-face {
    font-family: 'eb-garamond';
    src: url('/maiersports_ng/static/fonts/EBGaramond-Regular.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/EBGaramond-Regular.woff') format('woff'),
    url('/maiersports_ng/static/fonts/EBGaramond-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'eb-garamond';
    src: url('/maiersports_ng/static/fonts/EBGaramond-Bold.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/EBGaramond-Bold.woff') format('woff'),
    url('/maiersports_ng/static/fonts/EBGaramond-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'eb-garamond';
    src: url('/maiersports_ng/static/fonts/EBGaramond-Medium.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/EBGaramond-Medium.woff') format('woff'),
    url('/maiersports_ng/static/fonts/EBGaramond-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'eb-garamond';
    src: url('/maiersports_ng/static/fonts/EBGaramond-SemiBold.woff2') format('woff2'),
    url('/maiersports_ng/static/fonts/EBGaramond-SemiBold.woff') format('woff'),
    url('/maiersports_ng/static/fonts/EBGaramond-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
}

/* Lightbox LG Fonts */
@font-face {
    font-family: 'lg';
    src: url('/maiersports_ng/static/fonts/lg.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}