.mai__m14-gallery__items {
    padding: 0;
    margin: 0;

    list-style: none;
}

.mai__m14-gallery__media {
    overflow: hidden;
    background-color: gray;
}

.mai__m14-gallery__slider-arrows {
    top: auto;
    right: auto;
    bottom: calc(40rem/16);
    left: 50%;
    transform: translateX(-50%);
}

.mai__m14-gallery__slider-arrows--has-caption {
    margin-bottom: calc(var(--line-height-default-xs) + 10px);

    @media (min-width: 992px) {
        margin-bottom: calc(var(--line-height-default) + 20px);
    }

    @media (min-width: 1921px) {
        margin-bottom: calc(var(--line-height-default-xxxl) + 20px);
    }
}
