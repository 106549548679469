.mai__m33-reviews__slider__content.is-initialized {
    margin-bottom: calc(86rem/16);

    @media (min-width: 992px) {
        margin-bottom: 0;
    }
}

.mai__m33-reviews-rating {
    background-color: var(--color-light-mint);
    border-radius: 4px;
    padding: 0 calc(10rem/16);
    margin-bottom: calc(49rem/16);

    @media (min-width: 992px) {
        padding: calc(8rem/16) calc(22rem/16) calc(8rem/16) calc(20rem/16);
        margin-bottom: calc(20rem/16)
    }
}

.mai__product-rating__icon {
    font-size: calc(12rem/16);
    margin-right: 0 !important;

    @media (min-width: 992px) {
        font-size: calc(19rem/16);
    }
}

.mai__m33-reviews__rating-text {
    color: var(--color-petrol);
    font-size: calc(12rem/16);
    line-height: calc(24rem/16);
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    position: relative;
    top: 1px;

    @media (min-width: 992px) {
        font-size: calc(16rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(21rem/16);
        line-height: calc(32rem/16);
    }
}

.mai__m33-reviews__toggle {
    display: none;
    visibility: hidden;
    opacity: 0;
    height: 1px;
}

.mai__m33-reviews__toggle + .mai__m33-reviews__review__text {
    --m33-text-line-height: calc(38rem/16);
    --m33-text-max-lines: 4;
    --m33-text-max-height: calc(var(--m33-text-line-height) * var(--m33-text-max-lines));

    max-height: var(--m33-text-max-height);
    overflow: hidden;

    background: var(--color-anthracite);
    background: -webkit-linear-gradient(to bottom, var(--color-anthracite) 40%, var(--color-white) 100%);
    background: -moz-linear-gradient(to bottom, var(--color-anthracite) 40%, var(--color-white) 100%);
    background: linear-gradient(to bottom, var(--color-anthracite) 40%, var(--color-white) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (min-width: 992px) {
        --m33-text-line-height: calc(58rem/16);
        max-height: var(--m33-text-max-height);
    }

    @media (min-width: 1921px) {
        --m33-text-line-height: calc(103rem/16);
    }
}

.mai__m33-reviews__toggle:checked + .mai__m33-reviews__review__text {
    max-height: unset;
    -webkit-text-fill-color: var(--color-anthracite);
}

.mai__m33-reviews__review [data-review-expanded] {
    display: none;
}

.mai__m33-reviews__toggle:checked ~ .mai__m33-reviews__toggle-label [data-review-collaped] {
    display: none;
}

.mai__m33-reviews__toggle:checked ~ .mai__m33-reviews__toggle-label [data-review-expanded] {
    display: block;
}

.mai__m33-reviews__toggle-label {
    color: var(--color-anthracite);
    font-size: calc(12rem/16);
    font-weight: var(--font-weight-semibold);
    line-height: calc(42rem/16);
    text-transform: uppercase;
    text-align: center;

    @media (min-width: 1921px) {
        font-size: calc(21rem/16);
        margin-bottom: calc(30rem/16);
    }
}

.mai__m33-reviews [data-review-collaped],
.mai__m33-reviews [data-review-expanded] {
    cursor: pointer;
}

.mai__m33-reviews .mai__product-rating__icon {
    color: var(--color-petrol);
}

.mai__m33-reviews__review__text {
    margin-bottom: calc(34rem/16);

    @media (min-width: 992px) {
        line-height: calc(58rem/16);
        margin-bottom: calc(30rem/16);
    }
}

.mai__m33-reviews__review__user {

    @media (min-width: 992px) {
        margin-bottom: calc(40rem/16);
    }

    @media (min-width: 1921px) {
        margin-bottom: calc(60rem/16);
    }
}

.mai__m33-reviews__review__name,
.mai__m33-reviews__review__date {
    font-size: calc(14rem/16);
    line-height: calc(28rem/16);

    @media (min-width: 992px) {
        font-size: calc(16rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
        line-height: calc(50rem/16);
    }
}

.mai__m33-reviews__review__date {
    opacity: 0.5;
}

.mai__m33-reviews__reply::before {
    content: '';
    position: absolute;
    top: 12px;
    left: 0;
    height: calc(50rem/16);
    width: calc(50rem/16);
    display: inline-block;
    background-color: var(--color-warm-grey);
    transform: skewY(39deg);
    transform-origin: top right;
    border-radius: 15%;

    @media (min-width: 1921px) {
        top: 8px;
        height: calc(60rem/16);
        width: calc(60rem/16);
    }
}

.mai__m33-reviews__reply {
    margin-bottom: 0;
    margin-top: calc(35rem/16);
    padding: calc(35rem/16) calc(10rem/16) calc(20rem/16);
    background-color: var(--color-warm-grey);
    position: relative;
    border-radius: 4px;

    @media (min-width: 992px) {
        margin-top: 0;
        margin-left: calc(30rem/16);
        margin-bottom: calc(54rem/16);
        padding-top: calc(22rem/16);
    }
}

.mai__m33-reviews__reply__content {
    padding-left: calc(40rem/16);
    position: relative;

    @media (min-width: 992px) {
        padding-left: calc(70rem/16);
    }
}

.mai__m33-reviews__reply__logo {
   color: var(--color-red);
   font-size: calc(11rem/16);
   height: calc(29rem/16);
   width: calc(29rem/16);
   border-radius: 100%;
   background-color: var(--color-white);
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   top: -3px;
   left: -4px;

   @media (min-width: 992px) {
    left: calc(20rem/16);
   }

   @media (min-width: 1921px) {
    font-size: calc(15rem/16);
    height: calc(40rem/16);
    width: calc(40rem/16);
    top: -5px;
   }
}

.mai__m33-reviews__reply__replier {
    margin-bottom: calc(5rem/12);
    opacity: 0.5;
}

.mai__m33-reviews__reply__replier,
.mai__m33-reviews__reply__text {
    color: var(--color-anthracite);
    font-size: calc(12rem/16);
    line-height: calc(22rem/16);

    @media (min-width: 992px) {
        line-height: calc(24rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(21rem/16);
        line-height: calc(32rem/16);
    }
}

.mai__m33-reviews__reply__text {
    @media (min-width: 992px) {
        font-size: calc(16rem/16);
        line-height: calc(28rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
        line-height: calc(50rem/16);
    }
}

.mai__m33-reviews__action {
    text-align: center;

    @media (min-width: 992px) {
        text-align: left;
    }
}

.mai__m33-reviews__slider__arrows {
    top: 73px;
    right: 0;
    transform: translateX(120%);
}

/* pagination mobile */
.mai__m33-reviews__pagination {
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    bottom: calc(-45rem/16);
}

/* Review: modal - form */
.mai__m33-reviews__modal {
    z-index: 1080;
    transform: translateZ(1080px);
}

.mai__m33-reviews__modal .modal-header {
    background-color: var(--color-light-grey);
}

.mai__m33-reviews__rating {
    color: var(--color-anthracite);
}

.mai__m33-reviews__form .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.mai__m33-reviews__form .custom-control-inline {
    display: inline-flex;
}

.mai__m33-reviews__form .js-rate-product__star {
    position: relative;
    padding-left: 0;
}

.mai__m33-reviews__form .js-rate-product-star-visual {
    color: var(--color-petrol);
    font-size: calc(23rem/16);
}

.mai__m33-reviews__ajax-review-form-modal-content-wrapper {
    min-height: calc(200rem / 16);
}

.mai__m33-reviews__ajax-review-form-modal-loading-overlay {
    padding: 0;
    pointer-events: none;
}

.modal.is-loading .mai__m33-reviews__ajax-review-form-modal-loading-overlay {
    pointer-events: auto;
    opacity: 1;
}

.mai__m33-reviews__review__auto-translate-original-container,
.mai__m33-reviews__review__auto-translate-translation-container {
    font-size: 1rem;
}