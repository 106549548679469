.typeahead > form {
    overflow: hidden;
}

.typeahead .react-select__control {
    flex: 0 0 auto;
    min-height: calc(30rem/16);

    border: 0;
    padding: 0;

    font-size: calc(24rem/16);
    line-height: calc(30rem/16);

    background: var(--color-white);
    box-shadow: none;
}

.typeahead .react-select__value-container {
    padding: calc(20rem / 16);
    padding-right: calc(88rem / 16);

    border-radius: inherit;
    overflow: hidden;
}

.typeahead .react-select__placeholder {
    margin: 0;
    color: var(--color-anthracite-50);
}

.typeahead .react-select__value-container > [class*="css-"]:last-child {
    max-width: 100%;

    margin: 0;
    padding: 0;

    overflow: hidden;
}

.typeahead__value-container-submit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(68rem / 16);

    border-radius: 0;
    z-index: 1;
}

.typeahead__value-container-submit .icon {
    font-size: calc(24rem / 16);
    line-height: 1;
}

.typeahead .react-select__indicators {
    display: none;
}

.typeahead .react-select-wrapper {
    display: flex;
    flex-direction: column;

    height: 100%;
    overflow: hidden;

    @media (min-width: 768px) {
        width: calc(680rem / 16);
    }
}

.typeahead .typeahead__headline {
    font-size: calc(24rem / 16);
    line-height: calc(30rem / 16);
}

.typeahead .react-select__menu {
    position: static;
    margin: calc(20rem/16) 0 0 0;
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.2);
    border-radius: calc(4rem / 16);
    padding: calc(30rem/16) calc(10rem/16) calc(35rem/16) calc(20rem/16);

    overflow: hidden;

    @media (min-width: 768px) {
        right: 0;

        padding: calc(40rem/16) calc(10rem/16) calc(50rem/16) calc(30rem/16);
    }
}
.typeahead .react-select__menu-list {
    max-height: 100%;
    padding: 0;
    padding-right: calc(10rem/16);

    @media (min-width: 768px) {
        padding-right: calc(20rem/16);
    }
}
.typeahead div:not(.typeahead__group--product) > .react-select__option {
    color: inherit;
    font-size: inherit;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
}
.typeahead .typeahead__group > .react-select__option,
.typeahead .typeahead__group--product > .react-select__option {
    padding-right: calc(var(--bs-gutter-x)*0.5);
    padding-left: calc(var(--bs-gutter-x)*0.5);
    width: 100%;

    @media (min-width: 768px) {
        width: 25%;
    }
}
.typeahead .react-select__option--is-focused,
.typeahead .react-select__option:hover {
    cursor: pointer;
}
.typeahead .react-select__option:empty {
    display: none;
}

.typeahead .react-select__group {
    padding: 0;
    padding-top: calc(30rem/16);
    margin-top: calc(30rem/16);
    border-top: 1px solid var(--color-warm-grey);
}

.typeahead .react-select__group-heading  {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    font-size: inherit;
    line-height: inherit;

    margin: 0;
    padding: 0;
    border: 0;
    margin-bottom: calc(20rem/16);

    @media (min-width: 768px) {
        font-size: calc(16rem / 16);
        line-height: calc(22rem/16);
    }
}

.typeahead .mai__product-card__content :not(.mai__product-card__rating),
.typeahead .react-select__group-link {
    font-size: calc(12rem / 16);
    line-height: calc(22rem/16);
}

.typeahead__img {
    padding: calc(6rem / 16);
}
.typeahead__img__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    max-height: 100%;
    width: auto;
}
.typeahead__group--product .typeahead__title {
    font-family: var(--font-default-compact);
    font-weight: var(--font-default-compact-weight);
    font-style: var(--font-default-compact-style);
    text-transform: uppercase;
    color: var(--color-primary);
    font-size: calc(18rem/16);
    line-height: 1;
    letter-spacing: calc(.5rem / 16);

    @media (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}

.react-select__menu-list::-webkit-scrollbar-track {
    background-color: transparent;
    transition: all 120ms ease;
}

.react-select__menu-list::-webkit-scrollbar {
    width: calc(4rem / 16);
    height: calc(2rem / 16);
    background-color: transparent;
    transition: all 120ms ease;
    border-radius: 9999px;
    overflow: hidden;
}

.react-select__menu-list::-webkit-scrollbar-thumb {
    background-color: var(--color-anthracite-50);
    transition: all 120ms ease;
    border-radius: 9999px;
}

.react-select-wrapper .mai__product-card__image {
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    top: 0;
    bottom: 0;
}

.typeahead__magazine-link:hover {
    color: var(--color-red)!important;
}
