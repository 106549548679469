.mai__footer {
    padding:4.125rem 0 1.875rem;
    font-size: calc(14rem/16);
    background: var(--color-light-beige);
    position: relative;
    margin-top: calc((46 / 16) * 1rem);

    @media (min-width: 1200px) {
        font-size: 1rem;
        padding: calc(85rem/16) 0 Calc(45rem/16);

    }

    @media (min-width: 1921px) {
        padding-top: calc(150rem/16);
    }
}

.mai__footer__logo {
    color: var(--color-white);
    width: calc((278 / 16) * 1rem);
    padding: calc(15rem/16);
    background-color: var(--color-red);
    border-radius: calc(4rem/16);
    position: absolute;
    top: 0;
    transform: translateY(-50%);

    @media (min-width: 1200px) {
        width: calc((478 / 16) * 1rem);
        padding: calc(20rem/16);
    }

    @media (min-width: 1921px) {
        width: calc((850 / 16) * 1rem);
        padding: calc(35rem/16);
        border-radius: calc(5rem/16);
    }
}

.mai__footer__logo-icon {
    width: 8.875rem;
    height: 1.875rem;
    margin-right: calc(15rem/16);

    @media (min-width: 1200px) {
        margin-right: calc(28rem/16);
    }

    @media (min-width: 1921px) {
        width: calc((252 / 16) * 1rem);
        height: calc((53 / 16) * 1rem);
        margin-right: calc(50rem/16);
    }
}

.mai__footer__logo-icon--small {
    width: 2.187rem;
    height: 1.5rem;
    margin-right: 1rem;
}

.mai__footer__logo-slogan {
    font-weight: var(--font-weight-bold);
    display: flex;
    align-items: center;
    letter-spacing: 3px;

    @media (min-width: 1200px) {
        font-size: calc(17rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
    }
}

.mai__footer__headline {
    color: var(--color-anthracite);
    font-family: var(--font-default);
    font-size: calc(16rem/16);
    font-weight: var(--font-weight-semibold);
    line-height: calc(34rem/16);
    display: block;

    @media (min-width: 1200px) {
        line-height: calc(38rem/16);
        margin-bottom: calc(10rem / 16);
        pointer-events: none;
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
        line-height: calc(50rem/16);
        margin-bottom: calc(40rem / 16);
    }
}

.mai__footer__headline .icon-chevron {
    font-size: 0.45rem;

    @media (min-width: 1200px) {
        display: none;
    }
}

.mai__footer__headline[aria-expanded="true"] .icon-chevron {
    transform: rotateZ(180deg);
}

.mai__footer__nav-block {
    font-size: 0.875rem;
    padding: 0;
    list-style: none;
    margin-bottom: 0;

    @media (min-width: 1200px) {
        margin-bottom: calc(16rem/16);
    }
}

.mai__footer__nav-list {
    transform: translateY(-0.9375rem);

    @media (min-width: 1200px) {
        display: block !important;
        transform: none;
    }
}

.mai__footer__nav-link {
    color: var(--color-anthracite);
    font-family: var(--font-default);
    line-height: calc(40rem/16);
    display: block;

    @media (min-width: 1200px) {
        font-size: calc(16rem/16);
        line-height: 2.375rem;
    }

    @media (min-width: 1921px) {
        font-size: 1.75rem;
        line-height: 4.25rem;
    }
}

.mai__footer__headline:hover,
.mai__footer__headline:focus,
.mai__footer__nav-link:hover, 
.mai__footer__nav-link:focus {
	color: inherit;
}

.mai__footer__contact {
    line-height: 1.5rem;

    @media (min-width: 1200px) {
        font-size: 1rem;
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem / 16);
    }
}

.mai__footer__contact__address {
    @media (min-width: 1200px) {
        margin-top: calc(6rem/16);
    }

    @media (min-width: 1921px) {
        line-height: calc(43rem / 16);
    }
}

.mai__footer__contact .mai__footer__nav-list .icon {
    color: var(--color-anthracite);
    font-size: 1.15rem;
    opacity: 0.5;
    padding-left: calc(2rem/16);

    @media (min-width: 1921px) {
        font-size: calc(32rem/16);
    }
}

.mai__footer__contact .icon-mail {
    font-size: calc(16rem/16) !important;

    @media (min-width: 1921px) {
        font-size: calc(28rem/16) !important;
    }
}

.mai__footer__language {
   margin: calc(45rem/16) 0 calc(60rem/16);

    @media (min-width: 1200px) {
        margin: 0;
    }
}

.mai__footer__language .mai__footer__headline {
    margin-bottom: calc(12rem / 16);

    @media (min-width: 1200px) {
        margin-bottom: calc(20rem / 16);
    }

    @media (min-width: 1921px) {
        margin-bottom: calc(48rem / 16);
    }

}

.mai__footer__language-selector {
    padding: 0.75rem 1.25rem;
    border: 1px solid var(--color-anthracite);
    border-radius: 0.25rem;
    cursor: pointer;

    @media (min-width: 768px) {
        width: 12.875rem;
    }

    @media (min-width: 1200px) {
        font-size: 1rem;
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem / 16);
    }
}

.mai__footer__language .icon-link-arrow {
    font-size: 0.57rem;
}

.mai__footer__language .mai__footer__headline {
    @media (min-width: 1200px) {
        text-align: right;
    }
}

.mai__footer__social,
.mai__footer__legal {
    @media (min-width: 1200px) {
        display: flex;
        justify-content: flex-end;
    }
}

.mai__footer__social {
    display: flex;
    margin-bottom: calc(45rem/16);

    @media (min-width: 1200px) {
        margin-bottom: calc(15rem/16);
    }

    @media (min-width: 1921px) {
        margin-top: calc(10rem/16);
        margin-bottom: calc(40rem/16);
    }
}

.mai__footer__legal {
    margin-bottom: 1.875rem;

    @media (min-width: 1200px) {
        opacity: 0.5;
    }
}

.mai__footer__legal li + li {
    @media (min-width: 1200px) {
        margin-left: 2.5rem;
    }
}

.mai__footer__legal .mai__footer__nav-link {
    line-height: 1.751rem;
}

.mai__footer__social-icon {
    color: var(--color-anthracite);
    font-size: 1.5rem;

    @media (min-width: 1921px) {
        font-size: calc(42rem/16);
    }
}

.mai__footer__social-link {
    display: flex;
    align-items: center;
}

.mai__footer__social-link .icon-facebook {
    font-size: calc(26rem/16);

    @media (min-width: 1921px) {
        font-size: calc(47rem/16);
    }
}

.mai__footer__social-link .icon-youtube {
    font-size: calc(19rem/16);

    @media (min-width: 1921px) {
        font-size: calc(34rem/16);
    }
}

.mai__footer__social-link + .mai__footer__social-link {
    margin-left: 3.5375rem;
}

.mai__footer__copyright {

    @media (min-width: 1200px) {
        font-size: 1rem;
        opacity: 0.5;
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
        line-height: calc(28rem/16);

    }
}

.mai__footer__copyright .mai__footer__nav-link {
    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
    }
}
