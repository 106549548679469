.form-errors.invalid-feedback {
    font-size: calc(16rem/16);
    display: block;

    @media (min-width: 1921px) {
        font-size: calc(22rem/16);
    }
}
.form-errors:empty,
.is-valid .form-errors {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin-top: .25rem;
    font-size: .875em;
    color: var(--color-danger);
}

.form-group.has-error .form-control{
    border-color: var(--color-red);
}
