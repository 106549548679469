.mai__m30-icons-list {
    
}

.mai__m30-icons-list__heading {
    color: var(--color-black);
    font-size: calc(14rem/16);
    line-height: calc(28rem/16);
    margin-bottom: calc(15rem/16);

    @media (min-width: 992px) {
        font-size: calc(16rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
        line-height: calc(50rem/16);
    }
}

.mai__m30-icons-list__items {
    display: flex;
    flex-wrap: wrap;
}

.mai__m30-icons-list__item {
    margin-bottom: calc(30rem/16);
    padding-right: calc(15rem/16);
    flex: 1 0 50%;
    display: flex;
    align-items: center;
}

.mai__m30-icons-list__item__media {
    height: calc(40rem/16);
    width: calc(40rem/16);
    margin-right: calc(20rem/16);
    position: relative;
    -webkit-filter:grayscale(1);
    -moz-filter:grayscale(1);
    -ms-filter:grayscale(1);
    filter: grayscale(1);
    flex-shrink: 0;

    @media (min-width: 1921px) {
        height: calc(50rem/16);
        width: calc(50rem/16);
    }
}

.mai__m30-icons-list__item:hover .mai__m30-icons-list__item__media,
.mai__m30-icons-list__item:focus .mai__m30-icons-list__item__media {
    -webkit-filter:grayscale(0);
    -moz-filter:grayscale(0);
    -ms-filter:grayscale(0);
    filter: grayscale(0); 
}

.mai__m30-icons-list__item__image {
    object-fit: cover;
    width: 100%;
    height: auto;
    display: block;
}

.mai__m30-icons-list__item__text {
    color: var(--color-anthracite);
    font-size: calc(12rem/16);
    line-height: calc(22rem/16);

    @media (min-width: 992px) {
        font-size: calc(16rem/16);
        line-height: calc(28rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
        line-height: calc(50rem/16);
    }
}
