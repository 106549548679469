.mai__product-grid__accordion {
    border: 1px solid var(--color-warm-grey);
    border-radius: calc(8rem / 16);
    overflow: hidden;

    margin-bottom: calc(20rem / 16);

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 992px)  {
        background: var(--color-white);
    }
}

.mai__product-grid__accordion-toggle {
    font-size: inherit;
    line-height: inherit;
    font-weight: var(--font-weight-600);
    color: var(--color-anthracite);

    border: 0;
}

.mai__product-grid__accordion-toggle.collapsed .icon {
    transform: rotate(0);
    color: inherit;
}

.mai__product-grid__accordion-toggle .icon {
    font-size: calc(12rem / 16);
    color: var(--color-petrol);
    transform: rotate(90deg);

    transition: transform .15s ease-in-out, color .15s ease-in-out;

    @media (min-width: 1921px) {
        font-size: calc(20rem / 16);
    }
}

.mai__product-grid__accordion-content {
    color: var(--color-anthracite);
}

.mai__product-grid__accordion-content ul {
    list-style: none;
}

.mai__product-grid__accordion-content ul li {
    &::before {
        display: none;
    }

    padding: 0;
}


.mai__product-grid__accordion-content ul,
.mai__product-grid__accordion-content ul li,
.mai__product-grid__accordion-content ul li a,
.mai__product-grid__accordion-content ul li a:not(.btn) {
    color: inherit;
}

.mai__product-grid__accordion-content ul li a:not(.btn) {
    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.mai__product-grid__accordion-content li {
    @media (min-width: 992px) {
        opacity: .5;
        transition: opacity .15s ease-in-out;
    }

    &:hover,
    &:focus,
    &:focus-within {
        @media (min-width: 992px) {
            opacity: 1;
        }
    }
}

.mai__product-grid__accordion-content .is-active {
    font-weight: var(--font-weight-semibold);

    @media (min-width: 992px) {
        opacity: 1;
    }
}
