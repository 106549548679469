.as__ellipse {
    display: flex;

    background: var(--color-white);
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
    border-radius: 100%;
    justify-content: center;
    align-items: center;

    & .icon {
        margin: 0!important;
    }
}