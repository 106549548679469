@import "hero-stage--slider.css";
@import "hero-stage--styles.css";
@import "hero-stage--video.css";
@import "hero-stage--medium.css";
@import "hero-stage--single.css";
@import "hero-stage--no-media.css";

.mai__m6-hero-stage {
    position: relative;
}

.mai__m6-hero-stage:not(.mai__m6-hero-stage--has-background-color):not(.mai__m6-hero-stage--no-background)::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: url('/maiersports_ng/static/img/m6-hero-stage-background.svg') no-repeat bottom right;
    background-size: cover;

    z-index: 1;
    transform: translateZ(1px);
    pointer-events: none;
}

.mai__m6-hero-stage__inner {
    position: relative;
    z-index: 2;
    transform: translateZ(2px);
}

.mai__m6-hero-stage__slider-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mai__m6-hero-stage__item-inner {
    position: relative;
    border-radius: calc(6rem / 16);

    transition: transform .15s ease-in-out;

    @media (min-width: 992px) {
        border-radius: calc(8rem / 16);
    }

    @media (min-width: 1921px) {
        border-radius: calc(14rem / 16);
    }
}

.mai__m6-hero-stage__item-inner::before {
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: inherit;
    box-shadow: 0 calc(1.85rem / 16) calc(3.15rem / 16) 0 rgba(0,0,0,.02),
                0 calc(8.15rem / 16) calc(6.52rem / 16) 0 rgba(0,0,0,.03),
                0 calc(20rem / 16) calc(13rem / 16) 0 rgba(0,0,0,.04),
                0 calc(38.52rem / 16) calc(25.48rem / 16) 0 rgba(0,0,0,.04);

    z-index: 1;
    transform: translateZ(1px);
    pointer-events: none;

    opacity: 0;

    transition: opacity .15s ease-in-out;

    @media (min-width: 992px) {
        content: '';
    }
}

.mai__m6-hero-stage__item-inner:hover,
.mai__m6-hero-stage__item-inner:focus-within {
    @media (min-width: 992px) {
        transform: scale(1.0275);
    }

    &::before {
        opacity: 1;
    }
}

.mai__m6-hero-stage__item-row {
    position: relative;
    border-radius: inherit;
    overflow: hidden;
    z-index: 1;
    transform: translateZ(1px);
}

.mai__m6-hero-stage__image {
    object-fit: cover;
    font-family: "object-fit: cover";
}

.mai__m6-hero-stage__topline,
.mai__m6-hero-stage__headline,
.mai__m6-hero-stage__cta-buttons .mai__btn {
    color: var(--color-white);
}

.mai__m6-hero-stage__topline,
.mai__m6-hero-stage__headline {
    font-weight: var(--font-weight-600);
}

.mai__m6-hero-stage__headline.mai__m6-hero-stage__headline {
    @media (min-width: 992px) and (max-width: 1199px) {
        font-size: calc(32rem/ 16);
        line-height: calc(48rem / 16);
    }
}

.mai__m6-hero-stage__cta-buttons .mai__btn {
    border-color: currentColor;
}

.mai__m6-hero-stage__cta-buttons .mai__btn:hover,
.mai__m6-hero-stage__cta-buttons .mai__btn:focus {
    border-color: var(--color-red);
}

.mai__m6-hero-stage__media-container {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
}

.mai__m6-hero-stage .mai__slider-pagination__wrapper {
    padding-bottom: calc(60rem/16) !important;

    @media (min-width: 992px) {
        padding-bottom: 0 !important;
    }
}
