.mai__slider {
    position: relative;
}

.mai__slider-arrows,
.lg-actions {
    display: flex;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(40rem / 16);

    border-radius: 4px;

    z-index: 1;
    background: var(--color-red);
}

.mai__slider-arrows--invert {
    background-color: var(--color-white);
}

.mai__slider-arrows--vertical {
    right: auto;
    left: 0;
    flex-direction: column;
    width: calc(40rem/16);
    margin-left: calc(-20rem/16);

    @media (min-width: 1921px) {
        width: calc(70rem/16);
        margin-left: calc(-35rem/16);
    }
}

.mai__slider-arrows--d-none {
    display: none !important;
}

.mai__slider-arrow,
.lg-actions .lg-prev,
.lg-actions .lg-next  {
    display: flex;
    align-items: center;
    justify-content: center;

    height: calc(40rem / 16);
    padding: 0 calc(10rem / 16);

    color: var(--color-white);

    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border: 0;

    transition: 300ms transform cubic-bezier(0.91, 0.04, 0.2, 1);

    @media (min-width: 1921px) {
        height: calc(60rem / 16);
        padding-left: calc(20rem/16);
        padding-right: calc(20rem/16);
    }
}

.mai__slider-arrows--invert .mai__slider-arrow {
    color: var(--color-red);

    &:hover,
    &:focus {
        color: var(--color-red);
    }
}

.mai__slider-arrows--vertical .mai__slider-arrow {
    padding: calc(12rem / 16) 0;
    height: auto;

    @media (min-width: 1921px) {
        padding: calc(21rem/16) 0;
    }
}

.mai__slider-arrow:first-child,
.lg-actions .lg-prev {
    padding-left: calc(20rem / 16);

    @media (min-width: 1921px) {
        padding-left: calc(30rem / 16);
    }

    /* &:focus, */
    &:hover {
        &:not([disabled]) {
            @media (min-width: 991px) {
                transform: translateX(-5px);
            }
        }
    }
}

.mai__slider-arrow:last-child,
.lg-actions .lg-next {
    padding-right: calc(20rem / 16);

    @media (min-width: 1921px) {
        padding-right: calc(30rem / 16);
    }

    /* &:focus, */
    &:hover {
        &:not([disabled]) {
            @media (min-width: 991px) {
                transform: translateX(5px);
            }
        }
    }
}

.mai__slider-arrows--vertical .mai__slider-arrow:first-child {
    padding-left: 0;
    padding-top: calc(22rem / 16);

    @media (min-width: 1921px) {
        padding-left: 0;
        padding-top: calc(39rem/16);
    }

    /* &:focus, */
    &:hover {
        &:not([disabled]) {
            @media (min-width: 991px) {
                transform: translateY(-5px);
            }
        }
    }
}

.mai__slider-arrows--vertical .mai__slider-arrow:last-child {
    padding-right: 0;
    padding-bottom: calc(22rem / 16);

    @media (min-width: 1921px) {
        padding-right: 0;
        padding-bottom: calc(39rem/16);
    }

    /* &:focus, */
    &:hover {
        &:not([disabled]) {
            @media (min-width: 991px) {
                transform: translateY(5px);
            }
        }
    }
}

.mai__slider-arrow .icon,
.lg-actions .lg-prev,
.lg-actions .lg-next  {
    font-size: calc(10rem / 16);
    transition: opacity .15s ease-in-out,
                300ms transform cubic-bezier(0.91, 0.04, 0.2, 1);
}

.mai__slider-arrows--vertical .mai__slider-arrow .icon {
    font-size: calc(20rem / 16);

    @media (min-width: 1921px) {
        font-size: calc(35rem/16);
    }
}

.mai__slider-arrow[disabled] .icon {
    opacity: .3;
}

/* Overwrite Lightbox slider arrows */
.lg-inner {
    z-index: 5;
}

.lg-actions {
    position: absolute;
    bottom: calc(52rem/16);
    top: unset;
    right: unset;
    left: 50%;
    background-color: var(--color-white);
    transform: translateX(-50%);
    z-index: 10;
    transition: background-color 0.15s ease-in-out;

    @media (min-width: 1921px) {
        bottom: calc(70rem/16);
    }

}

.lg-actions .lg-prev,
.lg-actions .lg-next {
    color: var(--color-red);
    font-family: "iconfont" !important;
    position: relative;
    margin-top: 0;
    transform: none;

    &:hover,
    &:focus {
        color: var(--color-red);
    }
}

.lg-actions .lg-prev {
    left: unset;

    &::before {
        content: var(--icon-btn-arrow-left);
    }

    &::after {
        content: none;
    }
}

.lg-actions .lg-next {
    right: 0;

    &::before {
        content: var(--icon-btn-arrow-right);
    }
}

.lg-close.lg-icon {
    border-radius: 100%;
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    height: calc(48rem/16);
    width: calc(48rem/16);
    background-color: var(--color-white);
    top: -3px;
}

.lg-hide-items .lg-actions {
    background-color: transparent;
}

.lg-toolbar.lg-group {
    margin-top: calc(30rem/16);
    padding-right: calc(30rem/16);

    @media (min-width: 992px) {
        margin-top: calc(45rem/16);
        padding-right: calc(45rem/16);
    }
}

.lg-toolbar.lg-group .lg-icon:hover,
.lg-toolbar.lg-group .lg-icon:focus {
    color: var(--color-black);
}
