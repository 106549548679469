.rating + .rating {
    margin-top: calc(26rem / 16);
}
.rating__item {
    width: 100%;
    height: calc(8rem / 16);
    border-radius: calc(4rem / 16);
    background: var(--color-sand-20);
}
.rating__item + .rating__item {
    margin-left: calc(4rem / 16);
}
.rating__item.rating__item--active {
    background: var(--color-red);
}

/* rating small */
.rating--sm {
    font-size: calc(14rem / 16);
}
.rating--sm + .rating--sm {
    margin-top: calc(16rem / 16);
}

.mai__product-rating {
    font-size: calc(10rem / 16);

    @media (min-width: 768px) {
        font-size: calc(15rem / 16);
    }

    @media (min-width: 992px) {
        position: relative;
        top: -1px;
    }
}

.mai__product-rating__icon {
    display: flex;
    align-items: center;
} 

.mai__product-rating__icon--empty {
    color: rgba(3,140,126,.5);
}

.mai__product-rating .icon-rating-star--halffilled {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.28712 0V10.425L2.96269 13.5676L4.55826 8.40387L0.290039 5.18237L5.60059 5.13363L7.28712 0Z' fill='#038C7E'/%3E%3Cpath d='M7.29004 0L8.97657 5.13363L14.2871 5.18237L10.0189 8.40387L11.6145 13.5676L7.29004 10.425V0Z' fill='#038c7e80'/%3E%3C/svg%3E");
    display: inline-block;
    width: calc(13rem/16);
    height: calc(18rem/16);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;

    @media (min-width: 992px) {
        width: calc(21rem/16);
        height: calc(19rem/16);
    }
}

.mai__product-rating .icon-rating-star--halffilled:before {
    content: none;
}

