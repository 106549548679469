.modal {
    z-index: 1090;
    transform: translateZ(1090px);
}

.modal-backdrop {
    z-index: 1080;
    transform: translateZ(1080px);
    background-color: var(--color-anthracite);
    z-index: 1070;
    transform: translateZ(1070px);
}

.modal-backdrop.show {
    opacity: .9;
}

.modal-content {
    border: 0;
    border-radius: calc(6rem / 16);

    @media (min-width: 992px) {
        border-radius: calc(8rem / 16);
    }

    @media (min-width: 1921px) {
        border-radius: calc(14rem / 16);
    }
}

.modal-dialog {
    margin: calc(45rem / 16) calc(20rem / 16) calc(35rem / 16);

    @media (min-width: 576px) {
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 992px) {
        max-width: 550px;
    }

    @media (min-width: 1921px) {
        max-width: 800px;
    }
}

.modal-dialog.modal-xl {
    @media (min-width: 992px) {
        max-width: 800px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }
}

.modal-dialog-scrollable {
    height: calc(100% - 45rem / 16 - 35rem / 16);
}

.modal-dialog-centered {
    min-height: calc(100% - 45rem / 16 - 35rem / 16);
}

.modal-header,
.modal-body-content,
.modal-body,
.modal-footer {
    padding: calc(15rem / 16);

    @media (min-width: 992px) {
        padding: calc(20rem / 16);
    }
}

.modal-header {
    padding-top: calc(20rem / 16);
    padding-bottom: calc(10rem / 16);
    margin-bottom: calc(15rem / 16);

    @media (min-width: 992px) {
        margin-bottom: 0;
    }
}

.modal-footer {
    border: 0;
    padding-top: calc(2rem / 16);
    padding-bottom: 0;
}

.modal .close {
    font-size: calc(12rem / 16);
}
