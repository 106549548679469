.mai__m26-checkout-cta-size-select__sizes-scroll-container  {
    /* 78px height + 10px spacing */
    @media (min-width: 992px) {
        overflow: auto;
        max-height: calc(98rem / 16 * 3 + 20px);
    }

    /* 97px height + 10px spacing */
    @media (min-width: 1921px) {
         max-height: calc(117rem / 16 * 3 + 20px);
    }
}

.mai__m26-checkout-cta-size-select__size.js-selected-size--radio.disabled .custom-radio__box-btn {
    background-color: var(--color-warm-grey);
    color: var(--color-anthracite-40);
}
.mai__m26-checkout-cta-size-select__size.js-selected-size--radio.disabled .custom-radio__box-btn:hover {
    background-color: var(--color-warm-grey);
    color: var(--color-anthracite-40);
    border-color: transparent;
}

.mai__m26-checkout-cta-size-select__size.disabled,
.mai__m26-checkout-cta-size-select__size.disabled label,
.mai__m26-checkout-cta-size-select__size.disabled .custom-radio__box-btn {
    cursor: not-allowed;
}

.mai__m26-checkout-cta-size-select__size label,
.mai__m26-checkout-cta-size-select__size .custom-radio__box-btn {
    display: block;
    width: 100%;
}

.mai__m26-checkout-cta-size-select__size .custom-radio__box-btn {
    color: var(--color-anthracite);
    background: transparent;
    border-color: transparent;
    box-shadow: 0 0 calc(10rem / 16) rgb(0 0 0 / 8%);
    padding-top: calc(26rem / 16);
    padding-bottom: calc(26rem / 16);
    border-radius: calc(8rem / 16);

    opacity: .5;
    cursor: not-allowed;

    @media (min-width: 1921px) {
        font-size: calc(24rem / 16);
        line-height: calc(43rem / 16);
    }
}

.mai__m26-checkout-cta-size-select__size .custom-radio__input:checked ~ .custom-radio__box-btn,
.mai__m26-checkout-cta-size-select__size .custom-radio__box-btn:hover {
    color: var(--color-anthracite);
    background: transparent;
    border-color: transparent;
}

.mai__m26-checkout-cta-size-select__size.js-selected-size--radio .custom-radio__box-btn {
    opacity: 1;
}

.mai__m26-checkout-cta-size-select__size.js-selected-size--radio:not(.disabled) .custom-radio__box-btn {
    cursor: pointer;
}

.mai__m26-checkout-cta-size-select__size.js-selected-size--radio .custom-radio__input:checked ~ .custom-radio__box-btn,
.mai__m26-checkout-cta-size-select__size.js-selected-size--radio .custom-radio__box-btn:hover {
    background-color: transparent;
    color: var(--color-anthracite);
    border-color: currentColor;
}
