.mai__m18-text-media__content-headline {
    @media (min-width: 992px) {
        font-size: calc(48rem/16);
        line-height: 1.25em;
    }

    @media (min-width: 1921px) {
        font-size: calc(85rem/16);
        line-height: calc(107rem/16);
    }
}

.mai__m18-text-media__content-text {
    font-size: calc(14rem/16);
    line-height: calc(28rem / 16);

    @media (min-width: 992px) {
        font-size: inherit;
        line-height: inherit;
    }

    @media (min-width: 1921px) {
        font-size: calc(21rem/16);
        line-height: calc(32rem/16);
    }
}

.mai__m18-text-media__cta-button--petrol {
    color: var(--color-petrol);
    border-color: currentColor;
}

.mai__m18-text-media__cta-button--petrol:hover,
.mai__m18-text-media__cta-button--petrol:focus {
    border-color: var(--color-petrol);
    background-color: var(--color-petrol);
}

.mai__m18-text-media__cta-button--white {
    color: var(--color-white);
    border-color: currentColor;
}

.mai__m18-text-media__cta-button--white:hover,
.mai__m18-text-media__cta-button--white:focus {
    border-color: var(--color-white);
    background-color: var(--color-white);
}
