/*
.mai__m35b-quote {
    margin-bottom: calc(80rem/16);

    @media (min-width: 992px) {
        margin-bottom: calc(140rem/16);
    }
}
*/

.mai__m35b-quote__text,
.mai__m35b-quote__source {
    @media (min-width: 992px) {
        text-align: center;
    }
    
}

.mai__m35b-quote__text {
    color: var(--color-petrol);
}

.mai__m35b-quote__source {
    color: var(--color-mintpetrol);
    text-transform: uppercase;
}