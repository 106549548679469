.custom-file__name{
    position: absolute;
    top: 0;
    left: 0;
    padding-left: .75em;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    white-space: nowrap;
}

.custom-file-label:after{
    content: attr(data-translate);
    line-height: 2.3;
    position: absolute;
    display: block;
    height: 100%;
    top: 0;
    right: 0;
    background-color: var(--color-light-grey);
    border-left: 1px solid var(--color-dark-grey);
    z-index: 3;
    padding: calc(6rem/16) calc(12rem/16);
}
.custom-file-label{
    height: calc(48rem / 16);
    border: 1px solid var(--color-dark-grey);
    position: relative;
    background-color: transparent;
    z-index: 1;
}

.custom-file-input{
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 2;
    opacity: 0;
}

.custom-file .floating-label{
    background-color: transparent;
}

.custom-file{
    display: block;
    background-color: #fff;
    position: relative;
}

