.pagination {
    font-size: .875rem;
}
.page-link {
    color: #919191;
    text-decoration: none;
    background-color:transparent;
    border:0;
    padding: .75rem .5rem;
    font-family: var(--font-default-bold);
}
.page-link .icon {
    position: relative;
    top:0;
    color:inherit;
    font-size: .625rem;
}
.page-link:focus, .page-link:hover,
.page-item.active .page-link {
    color: var(--color-text-default);
    background-color:transparent;
    border-color:transparent;
    box-shadow: none;
}