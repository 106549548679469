.mai__slider-pagination__wrapper {
    padding-bottom: calc(40rem/16) !important;

    @media (min-width: 992px) {
        padding-bottom: 0 !important;
    }
}

.mai__slider-pagination {
    width: 100%;

    margin-top: calc(-20rem/16);
    margin-bottom: calc(20rem/16);
    padding-left: calc(30rem/16);
    padding-right: calc(30rem/16);

    pointer-events: auto;

    @media (min-width: 992px) {
        display: none;
    }
}

.mai__slider-pagination .splide__pagination__page {
    display: block;
    height: 2px;
    width: 100%;

    border: none;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;

    background-color: var(--color-warm-grey);
    transition: background-color .15s ease-in-out;

    border-radius: inherit;
    overflow: hidden;
}

.mai__slider-pagination li {
    flex: 1 0;
}

.mai__slider-pagination .splide__pagination__page.is-active {
    background-color: var(--color-red);
}
