.mai__m29-color-selector {
    margin-bottom: calc(30rem/16);
}

.mai__m29-color-selector__content.splide {
    margin-left: calc(-20rem/16);
    margin-right: calc(-20rem/16);

    @media (min-width: 992px) {
        margin-left: 0;
        margin-right: 0;
    }
}

.mai__m29-color-selector__content:not(.splide) .mai__m29-color-selector__arrows {
    display: none;
}

.mai__m29-color-selector__items:not(.splide__list) {
    display: flex;
    flex-wrap: wrap;
}

.mai__m29-color-selector__content--has-desktop-slider:not(.is-active) .mai__m29-color-selector__items {
    flex-wrap: nowrap;
}

.mai__m29-color-selector__item {
    width: calc(80rem/16);
}

.mai__m29-color-selector__item:not(.splide__slide) {
    margin-left: calc(15rem/16);

    &:first-child {
        margin-left: 0;
    }
}

.mai__m29-color-selector__media {
    width: calc(80rem/16);
    height: calc(80rem/16);
    background-color: var(--color-image-background);
    margin-bottom: calc(20rem/16);
    padding: calc(3rem/16);
    flex-shrink: 0;
    position: relative;
    border: 1px solid var(--color-warm-grey);
    border-radius: 4px;
    overflow: hidden;
}

.mai__m29-color-selector__link--active .mai__m29-color-selector__media {
    border-color: var(--color-anthracite);
}

.mai__m29-color-selector__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
}

.mai__m29-color-selector__text {
    color: var(--color-black);
    font-size: calc(14rem/16);
    line-height: calc(28rem/16);
    white-space: nowrap;
    text-transform: capitalize;

    @media (min-width: 992px) {
        font-size: calc(16rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(21rem/16);
        line-height: calc(32rem/16);
    }
}

.mai__m29-color-selector__arrows {
    display: none;
    position: absolute;
    left: calc(100% + 0.9375rem);

    @media (min-width: 1400px) {
        display: flex;
    }
}

.mai__m29-color-selector__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    background: none;
    height: calc(80rem/16);
    width: calc(80rem/16);
    border: 1px solid var(--color-warm-grey);
    border-radius: 4px;
}

.mai__m29-color-selector__arrow .icon {
    color: var(--color-anthracite);
    font-size: calc(9rem/16);
}

.mai__m29-color-selector__arrow.splide__arrow--prev {
    display: none;
}
