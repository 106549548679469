.mai__m40-pl-header__breadcrumb-wrapper {
    min-height: calc(30rem / 16);

    padding-left: calc(30rem / 16);
    padding-right: calc(30rem / 16);

    @media (min-width: 1921px) {
        min-height: calc(40rem / 16);

        padding-left: calc(40rem / 16);
        padding-right: calc(40rem / 16);
    }
}

.mai__m40-pl-header__breadcrumb .mai__backlink {
    display: flex;
    align-items: center;
    justify-content: center;

    width: calc(30rem / 16);
    height: calc(30rem / 16);

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    border-radius: 9999px;
    margin: auto;

    transition: background-color .15s ease-in-out, color .15s ease-in-out;

    @media (min-width: 1921px) {
        width: calc(40rem / 16);
        height: calc(40rem / 16);
    }

    &,
    &:focus,
    &:hover {
        color: var(--color-anthracite);
        background: var(--color-white);
        outline: 0;
    }
}

.mai__m40-pl-header__breadcrumb .mai__backlink__icon {
    font-size: calc(14rem / 16);

    @media (min-width: 1921px) {
        font-size: calc(18rem / 16);
    }
}

.mai__m40-pl-header__breadcrumb .mai__backlink__label {
    display: none;
}

.mai__m40-pl-header__breadcrumb .mai__breadcrumb-wrapper {
    display: flex;
    justify-content: center;
}

.mai__m40-pl-header__breadcrumb .mai__breadcrumb {
    font-size: var(--font-size-small-xs);
    line-height: var(--line-height-small-xs);
    color: var(--color-white);

    list-style: none;

    margin: 0;
    padding: 0;

    @media (min-width: 992px) {
        font-size: var(--font-size-small);
        line-height: var(--line-height-small);
    }

    @media (min-width: 1921px) {
        font-size: var(--font-size-small-xxxl);
        line-height: var(--line-height-small-xxxl);
    }
}

.mai__m40-pl-header__breadcrumb .mai__breadcrumb-item {
    margin-right: calc(10rem / 16);
}

.mai__m40-pl-header__breadcrumb .mai__breadcrumb-item::after {
    content: '»';
    padding-left: calc(10rem / 16);
    opacity: .5;
}

.mai__m40-pl-header__breadcrumb .mai__breadcrumb-item:last-child {
    margin-right: 0;

    &::after {
        display: none;
    }
}

.mai__m40-pl-header__breadcrumb .mai__breadcrumb-link {
    color: inherit;
    opacity: .5;

    transition: opacity .15s ease-in-out;
}

.mai__m40-pl-header__breadcrumb .mai__breadcrumb-link:hover,
.mai__m40-pl-header__breadcrumb .mai__breadcrumb-link:focus {
    opacity: 1;
    outline: 0;
}
