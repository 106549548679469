.mai__m3-product-row .mai__module-headline {
    font-size: calc(16rem/16);

    @media (min-width: 992px) {
        font-size: calc(24rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(43rem/16);
    }
}

.mai__m3-product-row .mai__module-headline .icon {
    @media (min-width: 1921px) {
        font-size: calc(24rem/16);
    }
}

.mai__m3-product-row__slider-track {
    @media (min-width: 992px) {
        padding-top: calc(10rem/16);
        padding-bottom: calc(10rem/16);
        margin-top: calc(-10rem/16);
        margin-bottom: calc(-10rem/16);
    }
}

.mai__m3-product-row__slider-list {
    list-style: none;
}

.mai__m3-product-row .mai__product-card__image-container {
     @media (min-width: 992px) {
         transition: transform 200ms ease-in-out;
     }
 }

.mai__m3-product-row .mai__product-card:hover .mai__product-card__image-container,
.mai__m3-product-row .mai__product-card:focus-within .mai__product-card__image-container,
.mai__m3-product-row .mai__product-card__head:hover .mai__product-card__image-container,
.mai__m3-product-row .mai__product-card__head:focus .mai__product-card__image-container {
    @media (min-width: 992px) {
        transform: scale(1.03);
    }
}

.mai__m3-product-row .mai__slider-arrows {
    right: calc(20rem/16);
}
