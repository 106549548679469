@import "filter.css";

.mai__product-grid__top-nav {
    position: relative;
    z-index: 1;
}

.mai__product-grid .product-grid__sort {
    width: 100%;
}

.mai__product-grid .product-grid__sort-select-wrapper {
    position: relative;
    color: var(--color-anthracite);
    width: 100%;
    border-radius: calc(4rem / 16);

    /* icon hover fix */
    &:hover:not(:focus-within)::before {
        z-index: -1;
    }
}

.mai__product-grid .product-grid__sort-select-wrapper.icon {
    font-size: calc(18rem/16);

    @media (min-width: 1921px) {
        font-size: calc(22rem/16);
    }

    &::before {
        position: absolute;
        top: calc(16rem/16);
        right: calc(20rem/16);

        @media (min-width: 992px) {
            top: calc(10rem/16);
        }

        @media (min-width: 1921px) {
            top: calc(19rem/16);
        }
    }
}

.mai__product-grid .product-grid__sort-select {
    font-size: calc(14rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-weight);
    line-height: calc(28/14);
    padding: calc(10rem / 16) calc(40rem / 16) calc(10rem / 16) calc(20rem / 16);
    border: 1px solid;
    display: inline-block;
    appearance: none;
    width: 100%;

    border-radius: calc(4rem / 16);
    text-transform: uppercase;
    text-decoration: none;

    transition: none;

    cursor: pointer;

    &,
    &:hover,
    &:focus {
        color: var(--color-anthracite);
        border-color: var(--color-anthracite);
        background-color: transparent;
        box-shadow: none;
    }

    @media (min-width: 992px) {
        font-size: calc(12rem / 16);
        line-height: calc(22/12);
        padding: calc(8rem / 16) calc(42rem / 16) calc(8rem / 16) calc(20rem / 16);

        transition: color .15s ease-in-out,
                    background-color .15s ease-in-out,
                    border-color .15s ease-in-out,
                    box-shadow .15s ease-in-out;
    }

    @media (min-width: 1921px) {
        font-size: calc(18rem / 16);
        line-height: calc(36/18);
        padding: calc(12rem / 16) calc(42rem / 16) calc(12rem / 16) calc(20rem / 16);
    }
}

.mai__product-grid .product-grid__sort-select-wrapper--product-list {
    color: var(--color-white);

    &:hover,
    &:focus-within {
        background-color: var(--color-white);
        color: var(--color-anthracite);
    }
}

.mai__product-grid .product-grid__sort-select-wrapper--product-list .product-grid__sort-select {
    color: var(--color-white);
    border-color: var(--color-white);
    background-color: transparent;

    &:hover,
    &:focus {
        color: var(--color-anthracite);
        border-color: var(--color-white);
    }
}
