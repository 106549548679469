.mai__m21-teaser-cards__card-wrapper--has-link {
    position: relative;
    border-radius: calc(6rem / 16);

    @media (min-width: 992px) {
        border-radius: calc(8rem / 16);
    }

    @media (min-width: 1921px) {
        border-radius: calc(14rem / 16);
    }
}

.mai__m21-teaser-cards__card-wrapper--has-link::before {
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: inherit;
    box-shadow: 0 calc(1.85rem / 16) calc(3.15rem / 16) 0 rgba(0,0,0,.02),
                0 calc(8.15rem / 16) calc(6.52rem / 16) 0 rgba(0,0,0,.03),
                0 calc(20rem / 16) calc(13rem / 16) 0 rgba(0,0,0,.04),
                0 calc(38.52rem / 16) calc(25.48rem / 16) 0 rgba(0,0,0,.04);

    z-index: 1;
    pointer-events: none;

    opacity: 0;

    transition: opacity .15s ease-in-out;

    @media (min-width: 992px) {
        content: '';
    }
}

.mai__m21-teaser-cards__card-wrapper--has-link:focus-within,
.mai__m21-teaser-cards__card-wrapper--has-link:hover {
    &::before {
        opacity: 1;
    }
}

.mai__m21-teaser-cards__card-wrapper--has-link .mai__m21-teaser-cards__image-wrapper {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden;
}

.mai__m21-teaser-cards__link,
.mai__m21-teaser-cards__link:hover,
.mai__m21-teaser-cards__link:focus {
    color: inherit;
    text-decoration: inherit;
    outline: 0;
}
