.mai__product-grid__loading-overlay {
    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;

    background-color: var(--color-anthracite-50);

    z-index: 9999;
    transform: translateZ(9999px);
}

.product-grid-is-loading .mai__product-grid__loading-overlay {
    pointer-events: auto;
    opacity: 1;
}

.mai__product-grid__loading-anim,
.mai__product-grid__loading-anim:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.mai__product-grid__loading-anim {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
