.btn {
    background-color: transparent;
    border: none;
    appearance: none;
    -webkit-appearance: none;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.mai__btn, .btn.btn-primary {
    color: var(--color-red);
    font-size: calc(14rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-weight);
    line-height: calc(28/14);
    padding: calc(10rem / 16) calc(22rem / 16) calc(10rem / 16) calc(20rem / 16);
    border: 1px solid var(--color-red);
    background-color: transparent;
    display: inline-block;

    border-radius: calc(4rem / 16);
    text-transform: uppercase;
    text-decoration: none;
    box-shadow: none;

    transition: none;

    &:hover,
    &:focus,
    &.mai__btn--active {
        color: var(--color-white);
        background-color: var(--color-red);
        box-shadow: none;
    }

    &.mai__btn--disabled {
        color: var(--color-red);
        background-color: transparent;
        opacity: 0.65;
        pointer-events: none;
    }

    @media (min-width: 992px) {
        font-size: calc(12rem / 16);
        line-height: calc(22/12);
        padding: calc(8rem / 16) calc(22rem / 16) calc(8rem / 16) calc(20rem / 16);

        transition: color .15s ease-in-out,
                    background-color .15s ease-in-out,
                    border-color .15s ease-in-out,
                    box-shadow .15s ease-in-out;
    }

    @media (min-width: 1921px) {
        font-size: calc(18rem / 16);
        line-height: calc(36/18);
        padding: calc(12rem / 16) calc(22rem / 16) calc(12rem / 16) calc(20rem / 16);
    }
}

.mai__btn .icon {
    font-size: calc(8rem / 16);
    margin-left: calc(20rem / 16);

    @media (min-width: 1921px) {
        font-size: calc(11rem / 16);
        margin-left: calc(32rem / 16);    }
}
.mai__btn.mai__btn--white {
    color: var(--color-white);
    border: 1px solid var(--color-white);
    background-color: transparent;


    &:hover,
    &:focus {
        color: var(--color-red);
        background-color: var(--color-white);
    }

    &.btn--disabled {
        color: var(--color-white);
    }
}

.mai__btn.mai__btn--red {
    color: var(--color-white);
    background-color: var(--color-red);
}

.mai__btn.mai__btn--big {
    padding-top: calc(11rem/16);
    padding-bottom: calc(11rem/16);
    line-height: calc(24rem/16);

    @media screen and (min-width: 992px) {
        padding-top: calc(13rem/16);
        padding-bottom: calc(13rem/16);
    }
}

.mai__slider-buttons {
    display: inline-flex;
    flex-direction: row;
    width: calc(108rem / 16);
    background-color: var(--color-red);
    border-radius: calc(4rem / 16);

    @media (min-width: 1921px) {
        width: calc(162rem / 16);
    }

    &.mai__slider-buttons--vertical {
        transform: rotateZ(90deg);
    }
}


.mai__slider-button {
    color: var(--color-white);
    flex: 1 1 auto;
    line-height: 1;

    &:first-child {
        padding: calc(22rem / 16) calc(12rem / 16) calc(22rem / 16) calc(22rem / 16);

        &:hover .icon,
        &:focus .icon {
            transform: translateX(-5px);
        }
    }

    &:last-child {
        padding: calc(22rem / 16) calc(22rem / 16) calc(22rem / 16) calc(12rem / 16);

        &:hover .icon,
        &:focus .icon {
            transform: translateX(5px);
        }
    }

    &:disabled,
    &.mai__slider-button--disabled {
        opacity: 0.65;
        pointer-events: none;
    }
}

.mai__slider-button .icon {
    font-size: calc(8rem / 16);
    position: relative;
    top: -2px;
    transition: all 0.3s ease;

    @media (min-width: 1921px) {
        font-size: calc(12rem / 16);
        top: 1px;
    }
}

.mai__slider-buttons--white {
    background-color: var(--color-white);

    & .mai__slider-button {
        color: var(--color-red)
    }
}

.mai__btn-icon {
    color: var(--color-anthracite);
    font-size: calc(10rem / 16);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    & .icon {
       font-size: calc(10rem / 16);
    }

    &.mai__btn-icon--rounded {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: calc(30rem / 16);
        height: calc(30rem / 16);
        border-radius: 100%;
        background-color: var(--color-white);
        box-shadow: 0 0 5px rgba(0,0,0,0.15);
    }
}

.mai__btn-text {
    color: var(--color-anthracite);
    font-size: calc(12rem / 16);
    text-transform: uppercase;

    &.mai__btn-text--red {
        color: var(--color-red);
    }

    &.mai__btn-text--white {
        color: var(--color-white);
    }

    &.mai__btn-text--disabled,
    &:disabled {
        opacity: 0.65;
        pointer-events: none;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

button.mai__slider-button,
button.mai__btn-icon,
button.mai__btn-text {
    appearance: none;
    border: none;
    background-color: transparent;
}

.btn-check:focus + .btn,
.btn:focus {
    box-shadow: none;
}

/* helper classes*/

.mai__btn--no-appearance {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    background-color: transparent;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    border-color: transparent;
}


@media screen and (max-width: 767px) {
    .btn-xs-block {
        display: block;
        width: 100%;
    }
    .xs-pos-sticky-bottom {
        position: sticky;
        left:0;
        right:0;
        bottom:0;
        width: auto;
        padding:.75rem 0;
        background: #fff;
        z-index: 6;
    }
    .xs-pos-sticky-bottom .btn {
        display: block;
        width: 100%;
    }
}
