.mai__m12-content-header--has-content {
    position: relative;
}

.mai__m12-content-header--has-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    pointer-events: none;
    background-color: var(--color-anthracite);
    opacity: .2;

    mix-blend-mode: multiply;

    z-index: 1;
}

.mai__m12-content-header__content-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    z-index: 2;
}

.mai__m12-content-header__topline,
.mai__m12-content-header__headline {
    font-weight: var(--font-weight-600);
    text-shadow: 0 calc(4rem/16) calc(20rem/16) rgba(0,0,0,.8);
}

.mai__m12-content-header__topline {
    font-size: calc(16rem/16);
    line-height: calc(24rem/16);

    @media (min-width: 1921px) {
        font-size: calc(28rem/16);
        line-height: calc(43rem/16);
    }
}

.mai__m12-content-header__headline {
    font-size: calc(32rem/16);
    line-height: calc(40rem/16);
    text-transform: none;

    @media (min-width: 992px) {
        font-size: calc(78rem/16);
        line-height: calc(82rem/16);
    }

    @media (min-width: 1921px) {
        font-size: calc(139rem/16);
        line-height: calc(146rem/16);
    }
}
