.mai__m5-teaser__item {
    position: relative;
    border-radius: 8px;
}

.mai__m5-teaser__item::before {
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: inherit;
    box-shadow: 0 calc(1.85rem / 16) calc(3.15rem / 16) 0 rgba(0,0,0,.02),
                0 calc(8.15rem / 16) calc(6.52rem / 16) 0 rgba(0,0,0,.03),
                0 calc(20rem / 16) calc(13rem / 16) 0 rgba(0,0,0,.04),
                0 calc(38.52rem / 16) calc(25.48rem / 16) 0 rgba(0,0,0,.04);

    z-index: 1;
    pointer-events: none;

    opacity: 0;

    transition: opacity .15s ease-in-out;

    @media (min-width: 992px) {
        content: '';
    }
}

.mai__m5-teaser__image-wrapper {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden;
}

.mai__m5-teaser__item:hover,
.mai__m5-teaser__item:focus-within {
    &::before {
        opacity: 1;
    }
}
