/* Magazine overview / list */
.mai__magazine-list__navigation {
    margin: calc(40rem/16) 0;

    @media (min-width: 1921px) {
        margin-bottom: calc(60em/16);
    }
}

.mai__magazine__list .mai__m1-magazine-teaser__items {
    flex-wrap: wrap !important;
}

.mai__magazine__list .mai__m1-magazine-teaser__item {
    margin-bottom: calc(60rem/16);
    flex: 1 0 100%;

    @media (min-width: 992px) {
        margin-bottom: calc(120em/16);
        flex: 1 0 33%;
    }

    @media (min-width: 1921px) {
        margin-bottom: calc(140em/16);
    }
}

.mai__m1-magazine-teaser__item.mai__magazine__list__item--highlight {
    @media (min-width: 992px) {
        flex: 1 0 50% !important;
    } 
}

/* Magazine detail page */
.mai__magazine__detail .wysiwyg h1,
.mai__magazine__detail .wysiwyg h2,
.mai__magazine__detail .wysiwyg h3,
.mai__magazine__detail .wysiwyg h4 {
    margin-bottom: calc(30rem/16);

    @media (min-width: 992px) {
        margin-bottom: calc(40rem/16);
    }
}

.mai__magazine__detail__author-image {
    border-radius: 100%;
    margin-right: calc(20rem/16);
}

.mai__magazine__detail .content-block + .content-block,
.mai__magazine__detail .content-block + .pimcore_area_content > .content-block,
.mai__magazine__detail .pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: 0;

    @media (min-width: 768px) {
        margin-top: 0;
    }
}
