.mai__m11-content-teaser__headline {
    @media (min-width: 992px) {
        margin-top: calc(30rem/16);
    }
}

.mai__m11-content-teaser__slider .splide__track {
    @media (min-width: 992px) {
        overflow: visible;
    }
}

.mai__m11-content-teaser__card {
    position: relative;
    width: calc(180rem / 16);

    padding: calc(20rem/16) calc(12rem/16);

    background-color: var(--color-warm-grey);
    border-radius: 6px;
    border: 0;

    text-decoration: none;
    color: inherit;

    transition: all .15s ease-in-out;
    z-index: 1;

    @media (min-width: 992px) {
        width: calc(210rem / 16);
    }

    @media (min-width: 1921px) {
        width: calc(320rem / 16);
    }
}

.mai__m11-content-teaser__card:hover,
.mai__m11-content-teaser__card:focus {
    color: inherit;

    @media (min-width: 992px) {
        transform: scale(1.08);
    }
}

.mai__m11-content-teaser__card::before {
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: inherit;
    box-shadow: 0 calc(1.85rem / 16) calc(3.15rem / 16) 0 rgba(0,0,0,.02),
                0 calc(8.15rem / 16) calc(6.52rem / 16) 0 rgba(0,0,0,.03),
                0 calc(20rem / 16) calc(13rem / 16) 0 rgba(0,0,0,.04),
                0 calc(38.52rem / 16) calc(25.48rem / 16) 0 rgba(0,0,0,.04);

    z-index: 1;
    pointer-events: none;

    opacity: 0;

    transition: opacity .15s ease-in-out;

    @media (min-width: 992px) {
        content: '';
    }
}

.mai__m11-content-teaser__card:hover,
.mai__m11-content-teaser__card:focus {
    &::before {
        opacity: 1;
    }
}

.mai__m11-content-teaser__icon {
    font-size: calc(22rem / 16);

    @media (min-width: 992px) {
        font-size: calc(18rem / 16);
    }

    @media (min-width: 1921px) {
        font-size: inherit;
    }
}


.mai__m11-content-teaser__card-title {
    font-size: calc(16rem / 16);
    line-height: calc(26rem / 16);

    @media (min-width: 992px) {
        font-size: calc(16rem / 16);
        line-height: calc(28rem / 16);
    }
}

.mai__m11-content-teaser__card-text {
    font-size: calc(14rem / 16);
    line-height: calc(28rem / 16);

    @media (min-width: 992px) {
        font-size: calc(12rem / 16);
        line-height: calc(22rem / 16);
    }

    @media (min-width: 1921px) {
        font-size: inherit;
        line-height: inherit;
    }
}

.mai__m11-content-teaser__card .card-footer {
    border: 0;
    background: none;
}

.mai__m11-content-teaser__link {
    font-size: calc(12rem/16);
    line-height: calc(24rem/16);

    @media (min-width: 1921px) {
        font-size: calc(21rem/16);
        line-height: calc(32rem/16);
    } 
}

.mai__m11-content-teaser__link,
.mai__m11-content-teaser__link:hover,
.mai__m11-content-teaser__link:focus {
    color: var(--color-red);
    text-decoration: none;
    text-transform: uppercase;
}
