.mai__m40-pl-header__accordion-toggle {
    position: fixed;
    top: 0;
    left: -9999px;
    z-index: -1;
}

.mai__m40-pl-header__accordion-content {
    max-height: 56px;
    overflow: hidden;
    transition: max-height .3s ease-in-out;

    @media (min-width: 576px) {
        max-height: none !important;
    }
}

.mai__m40-pl-header__accordion-trigger {
    @media (min-width: 576px) {
        display: none !important;
    }
}

.mai__m40-pl-header__accordion-trigger .mai__m40-pl-header__accordion-label--less,
.mai__m40-pl-header__accordion-toggle:checked ~ .mai__m40-pl-header__accordion-trigger .mai__m40-pl-header__accordion-label--more {
    display: none;
}

.mai__m40-pl-header__accordion-toggle:checked ~ .mai__m40-pl-header__accordion-trigger .mai__m40-pl-header__accordion-label--less {
    display: block;
}
