.mai__m25-product-slider__badge {
    display: flex;
    position: absolute;
    top: calc(15rem/16);
    left: calc(20rem/16);
    z-index: 5;

    @media (min-width: 992px) {
        top: calc(34rem/16);
        left: calc(114rem/16);
    }
}

.mai__m25-product-slider__badge + .mai__m25-product-slider__badge,
.mai__m25-product-slider__badge-item + .mai__m25-product-slider__badge-item {
    margin-left: calc(15rem/16);
}

.mai__m25-product-slider__badge-item {
    color: var(--color-anthracite);
    font-size: calc(12rem/16);
    line-height: calc(22rem/16);
    padding: calc(6rem/16) calc(10rem/16);
    background-color: var(--color-light-beige);
    border-radius: 4px;

    &.mai__m25-product-slider__badge-item--dimmed {
        opacity: 0.5;
    }
}

.mai__m25-product-slider {
    margin-left: 0;
    margin-right: 0;
    position: relative;
    overflow: hidden;
}

.mai__m25-product-slider__items:not(.splide__list) {
    display: flex;
    margin-left: calc(-5rem/16);
    margin-right: calc(-5rem/16);
}

.mai__m25-product-slider__media {
    position: relative;
    padding-bottom: 141.333%;

    @media (min-width: 992px) {
        padding-bottom: 100%;
    }
}

.mai__m25-product-slider__item.splide__slide {
    flex: 0 0 100%;

    @media (min-width: 992px) {
        flex: 0 0 50%;
    }
}

.mai__m25-product-slider__item:not(.splide__slide) {
    padding: 0 calc(5rem/16);
    flex: 1 1;
}

.mai__m25-product-slider__image {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.mai__m25-product-slider__media--contain .mai__m25-product-slider__image {
    object-fit: contain;
}

.mai__m25-product-slider__arrows {
    background-color: var(--color-white);
}

.mai__m25-product-slider__arrows .mai__slider-arrow {
    color: var(--color-red);
}

.mai__m29-color-selector__content.is-initialized .mai__m29-color-selector__arrows {
    @media (max-width: 991px) {
        display: none;
    }
}

.mai__m25-product-slider__pagination {
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    bottom: calc(33rem/16);
    padding-left: calc(48rem/16);
    padding-right: calc(48rem/16);
    z-index: 10;
}
