.mai__m13-headline__topline {
    margin-bottom: calc(10rem/16);

    @media (min-width: 992px) {
        margin-bottom: calc(20rem/16);
    }
}

.mai__m13-headline__heading {
    margin-bottom: calc(30rem/16);

    @media (min-width: 992px) {
        margin-bottom: calc(60rem/16);
    }
}

