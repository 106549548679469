.mai__followup-product-card__image-container,
.mai__followup-product-card__body {
    background: rgb(229 225 222 / 50%);
    border-top-left-radius: calc(8rem/16);
    border-bottom-left-radius: calc(8rem/16);
    overflow: hidden;
}

.mai__followup-product-card__body {
    border-radius: 0;
    border-top-right-radius: calc(8rem/16);
    border-bottom-right-radius: calc(8rem/16);
}


.mai__followup-product-card:hover .mai__btn,
.mai__followup-product-card:focus .mai__btn {
    background: var(--color-red);
    color: var(--color-white);
    box-shadow: none;
}
