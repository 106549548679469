.mai__m28-info-box {
    @media (min-width: 992px) {
        position: relative;

        border-top-right-radius: calc(8rem / 16);
        border-bottom-right-radius: calc(8rem / 16);

        padding: calc(30rem / 16) calc(40rem / 16);
        margin-top: calc(-70rem / 16);
        margin-left: calc(-40rem / 16);

        background: var(--color-white);
        z-index: 1;
    }
}

.mai__m28-info-box__article-nr {
    border-radius: calc(4rem / 16);
    border: 1px solid currentColor;
}

.mai__m28-info-box__product-name,
.mai__m28-info-box__product-price {
    @media (min-width: 992px) {
        font-size: calc(32rem / 16);
        line-height: calc(38rem / 16);
    }

    @media (min-width: 1921px) {
        font-size: calc(57rem / 16);
        line-height: calc(71rem / 16);
    }
}

.mai__m28-info-box__rating .mai__product-rating {
    font-size: calc(12rem / 16);
}

.mai__m28-info-box__rating-text {
    @media (min-width: 992px) {
        font-size: calc(16rem / 16);
        line-height: calc(28rem / 16);
    }
}

.mai__m28-info-box__actions {
    @media (min-width: 992px) {
        border-top: 1px solid rgba(0,0,0,.2);
    }
}

.mai__m28-info-box__action-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: calc(60rem / 16);
    height: calc(60rem / 16);

    border-radius: 9999px;
    box-shadow: 0 0 calc(22rem / 16) rgba(0,0,0,.15);

    @media (min-width: 992px) {
        width: calc(38rem / 16);
        height: calc(38rem / 16);
    }

    @media (min-width: 1921px) {
        width: calc(50rem / 16);
        height: calc(50rem / 16);
    }
}

.mai__m28-info-box__action-icon .icon {
    @media (min-width: 992px) {
        font-size: calc(26rem / 16)
    }

    @media (min-width: 1921px) {
        font-size: calc(34rem / 16)
    }
}

.mai__m28-info-box__action-label {
    @media (min-width: 992px) {
        font-size: calc(16rem / 16);
        line-height: calc(28rem / 16);
    }
}
