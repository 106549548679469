.mai__m6-hero-stage__item--medium.splide__slide {
    width: 100%;

    @media (min-width: 992px) {
        width: 50%;
    }
}

.mai__m6-hero-stage__item--medium .mai__m6-hero-stage__item-inner::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: inherit;

    opacity: .2;
    background-color: var(--color-anthracite);
    mix-blend-mode: multiply;

    pointer-events: none;

    z-index: 1;
    transform: translateZ(1px);
}

.mai__m6-hero-stage__item--medium .mai__m6-hero-stage__media-container {
    position: relative;
    border-radius: inherit;
    overflow: hidden;
    transform: translateZ(1px);
}

.mai__m6-hero-stage__item--medium .mai__m6-hero-stage__item-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    margin: auto;

    z-index: 2;
    transform: translateZ(2px);
}

.mai__m6-hero-stage__item--medium.mai__m6-hero-stage__item--no-media .mai__m6-hero-stage__media-container,
.mai__m6-hero-stage__item--medium.mai__m6-hero-stage__item--video .mai__m6-hero-stage__media-container {
    padding-bottom: 113.391877058177827%;

    @media (min-width: 992px) {
        padding-bottom: 89.463377192982456%
    }
}

.mai__m6-hero-stage__item--medium.mai__m6-hero-stage__item--no-cta .mai__m6-hero-stage__item-content {
    pointer-events: none;
}

.mai__m6-hero-stage__item--medium.mai__m6-hero-stage__item--no-cta.mai__m6-hero-stage__item--no-media .mai__m6-hero-stage__media-container {
    position: relative;
}

.mai__m6-hero-stage__item--medium.mai__m6-hero-stage__item--no-cta.mai__m6-hero-stage__item--no-media .mai__m6-hero-stage__image-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1;
    transform: translateZ(1px);
}
