@import "../../../libs/photoswipe/dist/photoswipe";

.pswp {
    --pswp-bg: #f2f2f5;

    /* Preload cursor image to workaround loading times */
    &::before {
        @media (min-width: 768px) {
            content: url("/maiersports_ng/static/img/icons/zoom-in.svg") url("/maiersports_ng/static/img/icons/zoom-out.svg");

            display: block;
            height: 0;
            width: 0;

            position: fixed;
            top: 0;
            left: -9999px;
            z-index: -1;
            opacity: 0;
            pointer-events: none;
        }
    }
}

.pswp__top-bar {
    top: calc(30rem/16);
    right: 0;

    padding-right: calc(30rem/16);

    @media (min-width: 992px) {
        top: calc(45rem/16);
        padding-right: calc(45rem/16);
    }
}

.pswp__button--close,
.pswp__button--close:hover,
.pswp__button--close:focus,
.pswp__button--close:active {
    display: flex;
    align-items: center;
    justify-content: center;

    width: calc(48rem/16);
    height: calc(48rem/16);

    margin: 0;
    border-radius: 9999px;

    color: var(--color-text-default);

    opacity: 1;
    box-shadow: 0 4px 4px rgba(0, 0, 0,.25);
    background: var(--color-white);


    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pswp__button--close::before {
    content: var(--icon-x);

    display: block;
    width: calc(14rem/16);
    height: calc(14rem/16);

    font-size: calc(14rem/16);
}

.pswp__button--close .pswp__icn {
    display: none;
}

.pswp__button--arrow {
    top: auto;
    bottom: calc(52rem/16);
    left: 50%;
    right: auto;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: auto;
    height: auto;

    margin: auto;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    overflow: visible;
    transform: translateX(-100%);

}

.pswp__button--arrow--next {
    justify-content: flex-end;

    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    transform: none;
}

.pswp__button--arrow,
.pswp__button--arrow:active,
.pswp__button--arrow:focus,
.pswp__button--arrow:hover {
    padding: calc(15rem/16) calc(10rem/16) calc(15rem/16) calc(20rem/16);
    background: var(--color-white);
}

.pswp__button--arrow--next,
.pswp__button--arrow--next:active,
.pswp__button--arrow--next:focus,
.pswp__button--arrow--next:hover {
    padding-left: calc(10rem/16);
    padding-right: calc(20rem/16);
}

.pswp__button--arrow .pswp__icn {
    position: static;

    width: calc(25rem/16);
    height: calc(10rem/16);

    margin: 0;

    fill: var(--color-red);

    cursor: pointer;
}

.pswp--click-to-zoom.pswp--zoom-allowed .pswp__img {
    @media (min-width: 768px) {
        cursor: url("/maiersports_ng/static/img/icons/zoom-in.svg"), zoom-in;
    }
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img,
.pswp--click-to-zoom.pswp--zoomed-in .pswp__img:active {
    @media (min-width: 768px) {
        cursor: url("/maiersports_ng/static/img/icons/zoom-out.svg"), zoom-out;
    }
}
