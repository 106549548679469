.scrolling-area {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    padding-bottom: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(45rem / 16); /* items shadow */
        margin-bottom: calc(-45rem / 16);
    }
}
.scrolling-area--vertical {
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (min-width: 768px) {
        padding: 0;
        margin: 0;
        padding-right: calc(10rem / 16);
    }
}
.scrolling-area::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 calc(8rem / 16) rgba(0, 0, 0, 0.2);
    background-color: transparent;
    transition: all 120ms ease;
}

.scrolling-area--xs::-webkit-scrollbar,
.scrolling-area::-webkit-scrollbar {
    -webkit-appearance: none;
    width: calc(2rem / 16);
    height: calc(2rem / 16);
    background: var(--color-sand-20);
    transition: all 120ms ease;
}

.scrolling-area--xs::-webkit-scrollbar-thumb,
.scrolling-area::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    transition: all 120ms ease;
}
.scrolling-area__item {
    scroll-snap-align: start;
}
@media screen and (max-width: 767px) {
    .scrolling-area--xs {
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
    }
}

.scrolling-area.scrolling-area--shadow {
    margin-top: calc(-20rem / 16);
    margin-bottom: calc(-20rem / 16);
    padding: calc(20rem / 16) 0;
}

.scrolling-area.scrolling-area--slider {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-bottom: calc(10rem / 16);
}
.scrolling-area--slider::-webkit-scrollbar {
    display: none;
}
.scrolling-area--slider {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 0;
    position: relative;
}
.scrolling-area--slider .scrolling-area__item {
    width: 100%;
    flex-shrink: 0;
}
.scrolling-area__arrow.scrolling-area__arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateX(100%);
    color: var(--color-sand);
    padding: calc(6rem / 16);
    font-size: calc(10rem / 16);
    z-index: 1;

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16);
        font-size: calc(18rem / 16);
    }
}
.scrolling-area__arrow.scrolling-area__arrow--prev {
    transform: translateY(-50%) translateX(-100%);
    right: auto;
    left: 0;
}

.scrolling-area--dots-only {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-bottom: 0;
}
.scrolling-area--dots-only::-webkit-scrollbar {
    display: none;
}
.container .scrolling-area--fill {
    margin-left: calc(-20rem / 16);
    margin-right: calc(-20rem / 16);
}

.scrolling-area__arrow-container {
    position: absolute;
    right: 0;
    bottom: calc(8rem / 16);
}

.scrolling-area__arrow[disabled] {
    opacity: .7;
    color: var(--color-text-default);
}
.scrolling-area__arrow:hover,
.scrolling-area__arrow:focus:not(:focus-visible) {
    outline: none;
    color: var(--color-primary);
}
.scrolling-area__nav {
    margin-top: calc(6rem / 16);
}
.scrolling-area__nav__dot + .scrolling-area__nav__dot {
    margin-left: calc(4rem / 16);
}
.scrolling-area__nav__dot {
    margin-top: calc(4rem / 16);
    width: calc(8rem / 16);
    height: calc(8rem / 16);
    background: var(--color-sand);
    border: 0;
    box-shadow: none;
    border-radius: 50%;
    flex-shrink: 0;
    padding: 0;
}
.scrolling-area__nav__dot:focus:not(:focus-visible) {
    outline: none;
}
.scrolling-area__nav__dot:focus,
.scrolling-area__nav__dot.is-active {
    background: var(--color-primary);
}